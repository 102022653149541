import { useContext } from "react"
import { Context } from "./ApplicationDetails"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { attachmentLabel, fileIcon, formatFormType, formatNumber, formatOrgnr, getStatus } from "../helpers/Functions"
import CollapseSection from "../components/CollapseSection"

export default function ApplicationOverviewDetails(){
    const { application, applicationConfig } = useContext(Context)
    const { payload } = application

    const Field = ({ type, name, alternatives, depends, fields, options }) => {
        let value = payload[name]
        if(payload[name]){
            value = payload[name]
        }else{
            if(payload.contact[name]){
                value = payload.contact[name]
            }else{
                return <div></div>
            }
            
        }


        
        switch(type){
            case 'numberformat':
                return (
                    <p><span className="bold">NOK</span> {formatNumber(value)}</p>
                )
            case 'radiogroupdepending':
                const val = alternatives.filter(a => a.value === value)[0]
                let dependingValue
                if(depends && depends.length){
                    dependingValue = depends.map((depend, key) => {
                        if(depend.radiovalue == value){
                            return depend.innerElements.map((inner, key) => {
                                return <Field {...inner} key={key} />
                            })
                        }
                    })
                }
                if(val) return (
                    <>
                        <p>{val.label}</p>
                        {dependingValue}
                    </>
                )
                break

            case 'repeater':
                const repeaterValue = JSON.parse(value)
                
                
                return (
                    <div className=" border-bottom mb-2">
                        <div className="row">
                    
                        {
                            repeaterValue.map((repeater, k) => {
                                return fields.map((group, groupkey) => {
                                    return group.map((item, key) => {
                                        return (
                                            repeater[item.name] ? 
                                            <div className="col-6" key={key}>
                                                {
                                                    item.type === 'numberformat' ? 
                                                    <p className="m-1"><span className="bold">NOK</span> {formatNumber(repeater[item.name].value)}</p> :
                                                    <p className="m-1">{repeater[item.name].value}</p>
                                                }
                                                
                                            </div>
                                            : null
                                        )
                                    })
                                })
                            })
                        }
                        </div>
                    </div>
                )
            case 'fileupload':
               
                    {
                        return value.map((file, key) => {
                            return (
                                <p>{file.name}</p>
                            )
                        })
                    }
            case 'ToggleSwitch':
                return <p>{value ? <FontAwesomeIcon icon={['fal', 'check-circle']} className="text-success" /> : <FontAwesomeIcon icon={['fal', 'times-circle']} className="text-danger" /> }</p>
            case 'dropdown':
                return <p>{options.filter(o => o.value === value)[0].label}</p>
            case 'brreg_required':
                return <p>{formatOrgnr(value)}</p>
            case 'dropdowncheck':
                const array = JSON.parse(value)
                
                return (
                    <div className="row">
                        {
                            array.map((item, key) => {
                                return (
                                    <div className="col-auto" key={key}>
                                        <p>{item.label}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
        }

        return <p>{value}</p>
    }

    if(!applicationConfig) return <div></div>

    return (
        <div>
            {
                applicationConfig.sections.map((section, key) => {
                    if(['pre', 'modal', 'summary'].includes(section.type)) return <div></div>
                    return (
                        <CollapseSection
                            key={key}
                            {...section}
                        >
                            
                            {
                                section.forms && section.forms.length && section.forms.map((form, key) => {
                                    return (
                                        <div className="row" key={key}>
                                            {
                                                form.elements.map((element, key) => {
                                                    return (
                                                        <div key={key} className={element.display && element.display !== 'text-center' ? element.display : ''}>
                                                            <div className="form-group">
                                                                <label>{element.label}</label>
                                                                <Field {...element} />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                    
                                })
                            }
                            </CollapseSection>
                            
                        
                    )
                })
            }
        </div>
    )
}