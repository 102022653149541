import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { createContext, createRef, useContext, useEffect, useState } from "react"
import { Link, Outlet, useMatch, useParams, useResolvedPath } from "react-router-dom"
import { AuthContext } from "../providers/AuthProvider"
import { attachmentLabel, fileIcon, formatFormType, formatNumber, formatOrgnr, getStatus } from "../helpers/Functions"
import FileUpload from "../components/FileUpload"
import FileUploadButton from "../components/FileUploadButton"
import Skeleton from "../components/Skeleton"
import { ConfigContext } from "../providers/ConfigProvider"
import CollapseSection from "../components/CollapseSection"
import ApplicationOverviewDetails from "./ApplicationOverviewDetails"
import ApplicationOverviewProjectgift from "./ApplicationOverviewProjectgift"
import moment from "moment"
import 'moment/locale/nb'

export const Context = createContext({
    application: null,
    applicationConfig: null,
    unreadMessages: null,
    finalReportEnabled: false,
    finalReportConfig: null
})

export const Provider = ({children}) => {
    const { data, lingo } = useContext(ConfigContext)
    const { apiCall } = useContext(AuthContext)

    const [application, setApplication] = useState(null)
    const [applicationConfig, setApplicationConfig] = useState(null)
    const [unreadMessages, setUnreadMessages] = useState(0)
    const [finalReportEnabled, setFinalReportEnabled] = useState(false)
    const [finalReportConfig, setFinalReportConfig] = useState(null)

    const params = useParams()

    const loadApplication = async () => {
        const { data } = await apiCall({
            action: 'getApplicationDetailsByHash',
            hash: params.hash
        })

        if(data.status === 1){
            setApplication(data.data)
        }
    }

    const setUnread = () => {
        let unread = 0
        application.messages.map((item) => {
            if(item.unread) unread++
        })

        setUnreadMessages(unread)
    }

    const checkFinalReport = () => {
        if(data){
            const currentApplication = data.applications.filter(a => a.type === application.type)[0]
            setApplicationConfig(currentApplication.application)
            if(currentApplication.finalReport){
                setFinalReportEnabled(true)
                setFinalReportConfig(currentApplication.finalReport)
            }
        }
    }

    useEffect(() => {
        //Setter antall uleste meldinger
        if(application){
            setUnread()
            checkFinalReport()
        }
    }, [application])

    useEffect(() => {
        if(params.hash){
            loadApplication()
        }
    }, [])
    return <Context.Provider value={{
        application,
        applicationConfig,
        unreadMessages,
        finalReportEnabled,
        finalReportConfig
    }}>{
        application ? children : <SkeletonContainer />
    }</Context.Provider>
}

export default function ApplicationDetails(){
    return (
        <Provider>
            <Main />
        </Provider>
    )
}

function Main(){
    const { application, unreadMessages } = useContext(Context)
    const { lingo } = useContext(ConfigContext)
    return (
        <div className="container py-4">
            <div className="d-flex flex-column">
                <div className="py-3">
                    <Link className="blue-link" to="/din-side">
                        <div className="row gx-2">
                            <div className="col-auto"><FontAwesomeIcon icon={['fal', 'chevron-left']} /></div>
                            <div className="col-auto">{lingo.dashboard_your_applications}</div>
                        </div>
                    </Link>
                    
                    <h2 className="m-0 pt-3">{application.applicant.name}</h2>
                    <h5 className="opacity-50">{formatFormType(application.type, lingo)}</h5>
                    
                </div>
                <div className="tabs">
                    <div>
                        <CustomLink to="">{lingo.dashboard_application_info}</CustomLink>
                        <CustomLink to="vedlegg">{lingo.dashboard_attachments}</CustomLink>
                        <CustomLink to="meldinger">
                            {unreadMessages > 0 ? <span className="badge">{unreadMessages}</span> : null}
                            {lingo.dashboard_messages}
                        </CustomLink>
                        {
                            application.status === 4 ? <CustomLink to="sluttrapport">{lingo.dashboard_final_report}</CustomLink> : null
                        }
                    </div>
                </div>
                <Outlet />
                
            </div>
        </div>
    )
}
function CustomLink({ children, to, ...props }) {
	let resolved = useResolvedPath(to);
	let match = useMatch({ path: resolved.pathname, end: true });
  
	return (
	  
		<Link
			className={match && 'active'}
			to={to}
			{...props}
		>
			{children}
		</Link>
	  
	);
}

export function ApplicationOverview(){
    const { application } = useContext(Context)
    
    switch(application.type){
        case 1:
            return <ApplicationOverviewProjectgift />
        default:
            return <ApplicationOverviewDetails />
    }
}



export function ApplicationFinalReportDetails(){
    const { application, finalReportConfig } = useContext(Context)
    const { final_report } = application

    const Field = ({ type, name, alternatives, depends, fields }) => {
        let value = final_report[name]
        if(!value){
            value = final_report.files[name]
        }
        
        switch(type){
            case 'numberformat':
                return (
                    <p><span className="bold">NOK</span> {formatNumber(value)}</p>
                )
            case 'radiogroupdepending':
                const val = alternatives.filter(a => a.value === value)[0]
                let dependingValue
                if(depends && depends.length){
                    dependingValue = depends.map((depend, key) => {
                        if(depend.radiovalue == value){
                            return depend.innerElements.map((inner, key) => {
                                return <Field {...inner} key={key} />
                            })
                        }
                    })
                }
                if(val) return (
                    <>
                        <p>{val.label}</p>
                        {dependingValue}
                    </>
                )

            case 'repeater':
                const repeaterValue = JSON.parse(value)
                
                
                return <div className="row">
                    
                    {
                        repeaterValue.map((repeater, k) => {
                            return fields.map((group, groupkey) => {
                                return group.map((item, key) => {
                                    return (
                                        repeater[item.name] ? 
                                        <div className="col-6" key={key}>
                                            {
                                                item.type === 'numberformat' ? 
                                                <p><span className="bold">NOK</span> {formatNumber(repeater[item.name].value)}</p> :
                                                <p>{repeater[item.name].value}</p>
                                            }
                                            
                                        </div>
                                        : null
                                        
                                    )
                                })
                            })
                        })
                    }
                </div>
            case 'fileupload':
               
                    {
                        return value.map((file, key) => {
                            return (
                                <p>{file.name}</p>
                            )
                        })
                    }
            case 'ToggleSwitch':
                return <p>{value ? <FontAwesomeIcon icon={['fal', 'check-circle']} className="text-success" /> : <FontAwesomeIcon icon={['fal', 'times-circle']} className="text-danger" /> }</p>
                
        }

        return <p>{value}</p>
    }

    if(!finalReportConfig) return <div></div>

    return (
        <div>
            <h2 className="mb-3">Sluttrapport</h2>
            {
                finalReportConfig.sections.map((section, key) => {
                    return (
                        <CollapseSection
                            key={key}
                            {...section}
                        >
                            
                            {
                                section.forms.map((form, key) => {
                                    return (
                                        <div className="row" key={key}>
                                            {
                                                form.elements.map((element, key) => {
                                                    return (
                                                        <div key={key} className={element.display && element.display !== 'text-center' ? element.display : ''}>
                                                            <div className="form-group">
                                                                <label>{element.label}</label>
                                                                <Field {...element} />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                    
                                })
                            }
                            </CollapseSection>
                            
                        
                    )
                })
            }
        </div>
    )
}

export function ApplicationMessages(){
    const { application } = useContext(Context)
    const { baseUrl, user, apiCall } = useContext(AuthContext)
    const [messages, setMessages] = useState(application.messages)
    const [message, setMessage] = useState('')
    const [files, setFiles] = useState([])

    const messageContainerRef = createRef()

    moment.locale('nb');

    useEffect(() => {
        // Chatloggen skal alltid være scrollet til bunn
        if(messageContainerRef.current){
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight - messageContainerRef.current.clientHeight;
        }
    }, [messageContainerRef])

    const reloadMessages = async () => {
        const response = await apiCall({action: 'getApplicationMessages', id: application.id})
        if(response.data.status === 1) {
            
            setMessages(response.data.data)
        }
    }

 

    const sendMessage = async () => {
        if(message.length === 0 && files.length === 0) return
        const response = await apiCall({
            action: 'addApplicationMessage', 
            application: application.id, 
            message: message,
            filer_vedlegg: files
            
        })

        if(response.data.status === 1){
            setFiles([])
            setMessage('')
            reloadMessages()
        }
    }

    

    const removeFile = (file) => {
		const newFiles = files.filter((t) => t !== file);
  		setFiles(newFiles);
	}

	const handleFileDrop = (fileList) => {
		for (var i = 0; i < fileList.length; i++) {
			if(!files.some(file => file.name === fileList[i].name)){
				setFiles([...files, fileList[i]])
			}
			
		}
	}

    const Attachment = ({ name, url }) => {
        return (
            <div className="col-auto">
                <div className="border border-light px-2 py-1" >
                    <div className="row gx-2">
                        <div className="col-auto">
                            <FontAwesomeIcon icon={['fal', 'paperclip']} />
                        </div>
                        <div className="col">
                            <a href={baseUrl + url} className="text-white" target="_blank">{name}</a>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }

    const Message = ({ id, attachments, reciever, sender, sent, unread, message }) => {
        let me;
        if(user && sender){
          me = user.id === sender.id
        }

        useEffect(() => {
            if(unread){
                markMessageRead()
            }
        }, [])

        const markMessageRead = async () => {
            const response = await apiCall({
                action: 'markApplicationMessageRead', 
                message: id
            })
        }
        
        return me ? (
            <div className="row mb-3 align-items-center gx-2 justify-content-end">
                <div className="col-auto">
                    <div className="rounded px-4 py-3 bg-success text-white">
                        <p className="m-0">{message}</p>
                        {
                            attachments.length > 0 ? 
                            (
                                <div className="row">
                                    { attachments.map((item, key) => <Attachment key={key} {...item} />)}
                                </div>
                            )
                            :
                            null
                        }
                    </div>
                </div>
                <div className="col-auto">
                    { sender && sender.photo ? <img src={baseUrl + 'assets/files/' + sender.photo} style={{width: 40, height: 40, borderRadius: '50%'}} /> : <div className="profile-photo-replacement">{sender && sender.fullname.substr(0, 1)}</div> }
                </div>
            </div>
        ) : (
            <div className="row mb-3 align-items-center gx-2">
                <div className="col-auto">
                    { sender && sender.photo ? <img src={baseUrl + 'assets/files/' + sender.photo} style={{width: 40, height: 40, borderRadius: '50%'}} /> : <div className="profile-photo-replacement">{sender && sender.fullname.substr(0, 1)}</div> }
                    
                </div>
                <div className="col-auto">
                    <div className="rounded px-4 py-3 bg-primary text-white">
                        <p className="m-0">{message}</p>
                        <span className="small">{moment(sent).format("MM.DD.YYYY")}</span>
                        {
                            attachments.length > 0 ? 
                            (
                                <div className="row">
                                    { attachments.map((item, key) => <Attachment key={key} {...item} />)}
                                </div>
                            )
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        )

    }
    return (
        <div className="flex-grow-1 d-flex flex-column py-3">
            <h2>Meldinger</h2>
            <div className="flex-grow-1 message-container" ref={messageContainerRef}>
                {
                    messages.map((item, key) => {
                        return <Message key={key} {...item} />
                    })
                }
            </div>
            <div>
                <hr className="thick" />
                <div className="row gx-1">
                    <div className="col">
                        
                        <textarea 
                            placeholder={'Skriv din melding her'} 
                            className={"message-textarea"}
                            value={message}
                            rows={1}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                    <div className="col-auto">
                        <FileUploadButton
                            files={files}
                            onHandleDrop={handleFileDrop}
                            onRemoveFile={removeFile}
                        />
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-primary" onClick={() => sendMessage()}>
                            <div className="row gx-1 align-items-center">
                                <div className="col-auto">
                                    Send melding
                                </div>
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={['fal', 'paper-plane-alt']} />
                                </div>
                            </div>
                           
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export function ApplicationAttachments(){
    const { application } = useContext(Context)
    const { baseURL, lingo } = useContext(ConfigContext)
    const { attachments, messages } = application

    const Attachment = ({ name, url, label }) => {
        const fileName = name.split('.').pop()
        
        return (
            <div className="col-md-6">
                <div className="border border-thick rounded p-4 mb-3">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <FontAwesomeIcon icon={['fad', fileIcon(fileName)]} size="3x" />
                        </div>
                        <div className="col">
                            <h5 className="m-0">{attachmentLabel(label, lingo)}</h5>
                            <p className="bold m-0">{name}</p>
                            <a href={baseURL + url} target="_blank">{lingo.download}</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            {attachments.length ? <h2>Vedlegg</h2> : null}

            <div className="row justify-content-center">
                
            {   
                attachments.map((item, key) => {
                    return <Attachment key={key} {...item} />
                })
            }
            </div>
            <h2>{lingo.from_messages}</h2>
            <div className="row align-items-center">
                {!messages.length && <p><i>{lingo.from_messages_no_attachments}</i></p>}
                {
                    messages.map((item, key) => {
                        if(item.attachments.length){
                            return item.attachments.map((item, key) => {
                                return <Attachment key={key} {...item} />
                            })
                        }
                    })
                }
            </div>
        </div>

    )
}

function SkeletonContainer(){
    const { lingo } = useContext(ConfigContext)
    return (
        <div className="container">
            <div className="d-flex flex-column">
                <div className="py-3">
                    <Link to="/din-side">
                        <div className="row gx-2">
                            <div className="col-auto"><FontAwesomeIcon icon={['fal', 'arrow-left']} /></div>
                            <div className="col-auto">{lingo.backlink}Tilbake</div>
                        </div>
                    </Link>
                    
                    <div><h2 className="m-0 mt-3 skeleton">{lingo.helloworld}</h2></div>
                    <h5 className="opacity-50 skeleton">{lingo.gift_dummy}</h5>
                    
                </div>
                
                <div className="tabs">
                    <div>
                        <span className="skeleton mx-2">{lingo.dashboard_application_info}</span>
                        <span className="skeleton mx-2">{lingo.dashboard_attachments}</span>
                        <span className="skeleton mx-2">{lingo.dashboard_messages}</span>
                    </div>
                </div>
                
            </div>

            <div>
            <h2 className="m-0 pt-3"><Skeleton width={300} height={40} /></h2>
            <div className="row">
                <div className="col-lg-8 col-12">
                    <div className="pt-3 mb-2">
                        <h4 className="mb-4 skeleton">{lingo.about_applicant}</h4>
                        <div className="row">
                            <div className="col-md-6">
                                <p className="caps bold m-0"><Skeleton width={150} height={25} /></p>
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-md-6">
                                <p className="caps bold m-0"><Skeleton width={150} height={25} /></p>
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-md-6">
                                <p className="caps bold m-0"><Skeleton width={125} height={25} /></p>
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-md-6">
                                <p className="caps bold m-0"><Skeleton width={150} height={25} /></p>
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-md-12">
                                <p className="caps bold m-0"><Skeleton width={150} height={25} /></p>
                                <Skeleton width={100} height={20} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="pt-3 mb-2">
                        <h4 className="mb-4 skeleton">{lingo.about_project}</h4>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="caps bold m-0 skeleton">{lingo.desc_project}</p>
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-md-12">
                                <p className="caps bold m-0 skeleton">{lingo.application_amount}</p>
                                <Skeleton width={100} height={20} />
                            </div>
                            
                            <div className="col-md-6">
                                <p className="caps bold m-0 skeleton">{lingo.sustainable_development_goals}</p>
                                <Skeleton width={100} height={20} />
                            </div>
                            
                        </div>
                    </div>
                    <hr />
                    
                    <div className="pt-3 mb-2">
                        <h4 className="mb-4 skeleton">{lingo.applicant_contact_information}</h4>
                        <div className="row">
                            <div className="col-md-6">
                                <p className="caps bold m-0 skeleton">{lingo.applicant_name}</p>
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-md-6">
                                <p className="caps bold m-0 skeleton">{lingo.dashboard_edit_profile_phone_label}</p>
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-md-6">
                                <p className="caps bold m-0 skeleton">{lingo.userinfo_email_label}</p>
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-md-6">
                                <p className="caps bold m-0 skeleton">{lingo.dashboard_edit_profile_address_label}</p>
                                <Skeleton width={100} height={20} />
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-md-6">
                                <p className="caps bold m-0 skeleton">{lingo.account_number}</p>
                                <Skeleton width={100} height={20} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="pt-3 mb-2">
                        <h4 className="mb-4 skeleton">{lingo.other_applications}</h4>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="caps bold m-0 skeleton">{lingo.applied_somewhere_else}</p>
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-md-12">
                                <p className="caps bold m-0 skeleton">{lingo.other_sources}</p>
                                <Skeleton width={100} height={20} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="border border-thick rounded p-3 mb-4">
                        <p className="bold caps skeleton">{lingo.application_status}</p>
                        <div className={"row gx-2 align-items-center"}>
                            <div className="col-auto">
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col">
                                <h5 className="m-0"><Skeleton width={70} height={20} /></h5>
                            </div>
                        </div>
                    </div>
                    <div className="border border-thick rounded p-3 mb-2">
                        <div className="mb-3">
                            <p className="font-weight-bold caps m-0 skeleton">{lingo.application_amount}</p>
                            <Skeleton width={100} height={20} />
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
    )
}