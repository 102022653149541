import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../providers/ConfigProvider";

const Header = () => {
    const [showHeader, setShowHeader] = useState(false);
    const fulldata = useContext(ConfigContext);
    const [baseUrl, setBaseUrl] = useState(null);
    const [logo, setLogo] = useState(null);
    const [title, setTitle] = useState(null);
    const [customCss, setCustomCss] = useState(null);

    //const {logo} = data;
    // Getting the logo
    useEffect(() => {
        if(fulldata && fulldata.baseURL && fulldata.data){
            setBaseUrl(fulldata.baseURL);
            setLogo(fulldata.data.logo);
            setTitle(fulldata.data.title);
            if(fulldata.data.custom_css){
                setCustomCss(fulldata.data.custom_css);
            }
        }
    }, [fulldata]);

    // useEffect(() => {
    //     if(baseUrl && customCss){
    //         // Add custom stylesheet to the app from the baseURL
    //         const link = document.createElement('link');
    //         link.rel = 'stylesheet';
    //         link.type = 'text/css';
    //         link.href = `${baseUrl}${customCss}`;
    //         document.head.appendChild(link);
    //     }
    // }, [customCss]);

    useEffect(() => {
        if(logo && baseUrl && title){
            setShowHeader(true);
        }
    }, [logo]);

    return (
        <>
            {
                showHeader ? (
                    <div className="w-100">
                        <div className="row">
                            <div className="col-auto text-center">
                                <img src={`${baseUrl}${logo}`} alt={title} className="logo"/>
                            </div>
                        </div>
                    </div>
                )
                : null
            }
        </>
    );
}

export default Header;