import { useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group";

export default function BubbleModal({ children, active, setActive, image, background}){
    const nodeRef = useRef(null);
    const [contentActive, setContentActive] = useState(false);

    const { innerHeight, innerWidth} = window;

    const calclulateBubbleSize = () => {
        
        let pixelValue = innerHeight + 100;
        if(innerHeight > innerWidth){
            pixelValue = innerHeight * 0.95;
        }
        return pixelValue;
    }

    useEffect(() => {
        setContentActive(active)
    }, [active])

    return (
        <CSSTransition nodeRef={nodeRef} in={active} timeout={200} classNames="bubble-modal" unmountOnExit>
            <div ref={nodeRef} className={"bubble-modal " + background} 
            style={{
                backgroundImage: background ? 'url(' + process.env.PUBLIC_URL + '/gfx/blurbg.png)' : 'none'
            }}>
                <CSSTransition nodeRef={nodeRef} in={contentActive} timeout={400} classNames="bubble-modal-content" >
                    <div ref={nodeRef} className="bubble-modal-content bg-white" style={{backgroundImage: image ? `url(${image})` : 'none',minWidth: calclulateBubbleSize(), minHeight: calclulateBubbleSize()}}>
                        {children}
                    </div>
                </CSSTransition>
            </div>
        </CSSTransition>
    )
}