import { useEffect, useContext } from 'react'
import { 
	BrowserRouter,
	Routes,
	Route,
	Outlet,
	useNavigate,
	useLocation
} from "react-router-dom";
import Components from "./screens/Components";
import { ApplicationFormContainer } from './screens/ApplicationForm';
import Application from './screens/Application';
import Section from './screens/Section';
import Form from './screens/Form';
import { AuthContext } from "./providers/AuthProvider";
import ApplicationDetails, { ApplicationAttachments, ApplicationFinalReportDetails, ApplicationMessages, ApplicationOverview } from './screens/ApplicationDetails';
import ApplicationSubmitted from './screens/ApplicationSubmitted';
import Dashboard, { Archive, Overview, Profile } from './screens/Dashboard';
import { ConfigProvider } from './providers/ConfigProvider';
import FinalReport from './screens/FinalReport';
import FinalReportSection from './screens/FinalReportSection';
import FinalReportForm from './screens/FinalReportForm';
import AuthDashboard from './screens/AuthDashboard'


export default function AppContainer(){

    const { baseUrl } = useContext(AuthContext)

    return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />}>
					<Route exact path="/din-side" element={<Dashboard />}>
						<Route index element={<Overview />} /> 
						<Route path="arkiv" element={<Archive />} />
						<Route path="rediger-profil" element={<Profile />} />
					</Route>
					<Route path="sluttrapport/:hash" element={<FinalReport />}>
						<Route path="/sluttrapport/:hash/:section" element={<FinalReportSection />}>
							<Route path="/sluttrapport/:hash/:section/:form" element={<FinalReportForm /> } />
						</Route>
					</Route>
					<Route exact path="/components" element={<Components />} />
					<Route exact path="soknadskjema/" element={<ApplicationFormContainer />}>
						<Route path="/soknadskjema/:formType" element={<Application />}>
							<Route path="/soknadskjema/:formType/:section" element={<Section />}>
								<Route path="/soknadskjema/:formType/:section/:form" element={<Form />} />
							</Route>
						</Route>
						<Route path="/soknadskjema/soknad-innsendt" element={<ApplicationSubmitted />} />
					</Route>
					<Route path="soknad/:hash" element={<ApplicationDetails />}>
						<Route index element={<ApplicationOverview />} />
						<Route path="meldinger" element={<ApplicationMessages />} />
						<Route path="vedlegg" element={<ApplicationAttachments />} />
						<Route path="sluttrapport" element={<ApplicationFinalReportDetails />} />
 					</Route>
					 <Route path="/webpage" element={() => {
						 window.location.href = baseUrl;
						 return null;
					}}/>
					
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

function App(){

	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		if(location.pathname === '/'){
			navigate('/din-side')
		}
	}, [])

	return (
		<Outlet />
	)
}

