import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import ToggleSwitch from "../components/ToggleSwitch";
import { formatNumber, formatOrgnr, formatPhoneNumber } from "../helpers/Functions";
import useTypeBook from "../helpers/useTypeBook";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { AuthContext } from "../providers/AuthProvider";

const Summary = () => {
    const navigate = useNavigate();
    const {formData, application, form} = useContext(ApplicationContext);
    
    const res = application.sections.filter(section => {
        return section.type === 'form' || section.type === 'user_info';
    });
    
    useEffect(() => {
    }, [formData]);


    return (
        <div>
            {
                res && res.map((item, key) => {
                    return (
                        <React.Fragment key={key}>
                            <SummarySection section={item} formData={formData} />
                        </React.Fragment>
                    );
                })
                
            }
            <SummaryConsent
                section={application.sections.filter(section => {
                    return section.type === 'summary'
                })}
            />
        </div>
    );
}
 
export default Summary;

const SummaryConsent = ({section}) => {
    const consent = section[0].consent;

    const { consentApproved, setConsentApproved } = useContext(ApplicationContext)
    return (
        <React.Fragment>
            <div className="section summary-section">
                <div className="row">
                    <div className="col-lg-auto col-12 summary-sidebar">
                        <div className="sidebar">

                            <div className="sidebar-navigation">
                                <div className={"sidebar-navigation-item d-flex align-items-center "}>

                                    <div className="sidebar-navigation-item-icon me-2">
                                        <FontAwesomeIcon icon={['fal', 'check']} />
                                    </div>

                                    <div className="sidebar-navigation-item-content mx-2">
                                        <h5 className="mb-0">Samtykke</h5>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg col-12 summary-content">
                        <div className="p-4 border mb-2 summary-container">
                            <div className="row gx-0">
                                <div className="col">
                                    <div className="col-12">
                                        <b className="font-weight-bold">Samtykke</b>
                                        {consent.text ? <div dangerouslySetInnerHTML={{__html: consent.text}}></div> : ''}
                                        <div>
                                            <ToggleSwitch
                                                label={consent.btnlabel}
                                                value={consentApproved}
                                                onClick={setConsentApproved}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const SummarySection = ({section, formData}) => {
    /**
     * Denne er for hver hovedkategori som da har tittel og ikon for hovedkategorien
     * Eks: Om søkeren, Kontaktperson, Om prosjektet og Vedlegg
     */
    const {title, icon, url, short_description} = section;

    
    const params = useParams();
    


    return (
        <React.Fragment>
            
            <div className="section summary-section">
                <div className="row">
                    <div className="col-lg-auto col-12 summary-sidebar">
                        <div className="sidebar">

                            <div className="sidebar-navigation">
                                <div className={"sidebar-navigation-item d-flex align-items-center " + (params.section===url ? 'active' : '')}>

                                    <div className="sidebar-navigation-item-icon me-2">
                                        <FontAwesomeIcon icon={['fal', icon]} />
                                    </div>

                                    <div className="sidebar-navigation-item-content mx-2">
                                        <h5 className="mb-0">{title}</h5>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg col-12 summary-content">
                        {
                            (section && section.type && (section.type === 'form' || section.type === 'user_info'))
                            ? <SummaryForms forms={section.forms} formData={formData} url={url} />
                            : null
                        }
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    );
}

const SummaryForms = ({forms, formData, url}) => {
    /**
     * Denne er en gruppe med alle skjemaene. Jeg tror ikke denne skal ha noe spesiell markup,
     * men man kan aldri vite
     */

    return(
        <React.Fragment>
            {/* <p>SummaryForms</p> */}
            {
                forms.map((item, key) => {

                    return (
                        <SummaryFormElements
                            key={key}
                            item={item.elements}
                            formData={formData}
                            parentUrl={url}
                            url={item.url}
                        />
                    )
                    
                })
            }
            
        </React.Fragment>
    );
}
const SummaryFormElements = ({item,formData, parentUrl,url}) => {
    /**
     * Denne skal ha ramme og link til å kunne redigere på akkurat det skjemaet
     */
    const { application } = useContext(ApplicationContext);

    return (
        <div className="p-4 border mb-2 summary-container">
            <div className="row gx-0">
                <div className="col">
                {
                    item.map((element, key) => {
                            return (
                                    <SummaryFormElement
                                        key={key}
                                        item={element}
                                        formData={formData}
                                    />
                            )
                    })
                }
                </div>
                <div className="col-auto summary-edit-link">
                    <Link to={`/soknadskjema/${application.url}/${parentUrl}/${url}`}>
                        <div className="row gx-2 align-items-center">
                            <div className="col-auto"><span className="smaller">Rediger</span></div>
                            <div className="col-auto"><span className="smaller"><FontAwesomeIcon icon={['fal', 'pen-circle']} fixedWidth /></span></div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

const SummaryFormElement = ({item, formData}) => {
    /**
     * Denne inneholder 1 felt i skjemaet som da skal vise label og value
     */
     const compare = (a, b) => {
        if ( a.order < b.order ){
            return -1;
        }
        if ( a.order > b.order ){
            return 1;
        }
        return 0;
    }
    
    const {label, name} = item;
    let hasChildren = false; // Sjekke etterhvert om det er barn og vi må kjøre funksjoner rekursivt
    let innerElements = []; // Gjør klar en array for elementer som befinner seg som barn

    let fieldvalue = 'ikke satt';
    let nestedItems = [];
    let showField = true;

    if(item.type === 'calculate'){
        showField = false;
    }

    if(formData && formData[name] && typeof formData[name].value === 'string'){
        
        fieldvalue = formData[name].value;
        
        if(item.type === 'radiogroupdepending'){
            fieldvalue = (formData[name].value === '0') ? 'Nei' : 'Ja';

            let dependArr = item.depends;
            nestedItems = (dependArr || []).map((nestedItem, key) => {
                if(nestedItem.radiovalue === formData[name].value){
                    hasChildren = true;
                    innerElements = nestedItem.innerElements;
                    // Fylle en array med innerElements som da skal kjøres rekursivt i bunnen i return statement.
                }
            });
        }

        if(item.name === 'application_other_name'){
        }
    }



    if(formData && formData[name]){
        if(item.type === 'repeater'){
            let repeaterFields = item.fields[0];
            repeaterFields.sort( compare );
            
            const repeaterValue = formData[name].value;
            fieldvalue = repeaterValue.map((rv, key) => {
                return (
                    <OutputRepeaterFields
                        key={key}
                        repeaterValue={rv}
                        repeaterFields={repeaterFields}
                    />
                );
            });

            


        }
        
    
        if(item.type === 'fileupload' || item.type === 'fileuploadsingle'){        

            // Her kan det være mange filer i en array.
            let existingFilesArray = formData[name].existing_files;
            let filesArray = formData[name].value;
            let tempArr = [];
            let tempvalue = [];

            tempvalue = existingFilesArray && <SummaryFileValue item={existingFilesArray} key={true} />
                

            if(tempvalue){
                tempArr.push(tempvalue);
            }

            tempvalue = filesArray && filesArray.map((file, key) => {
                let temp = {}
                temp.name = file.name;
                return (
                    <SummaryFileValue
                        item={temp}
                        key={key}
                    />
                )
            })
            
            if(tempvalue){
                tempArr.push(tempvalue);
            }

            fieldvalue = tempArr ? tempArr : [];

        }
    }
    if(formData && formData[name] && typeof formData[name].value === 'object'){

        if(item.type === 'dropdown'){
            fieldvalue = formData[name].value.label;
        }

        if(item.type === 'dropdowncheck'){
            /**
             * Her kan man velge flere enn 1 så her må vi gå igjennom og se på mange svar.
             */
            let values = formData[name].value;
            fieldvalue = values.map((value, key) => {
                return (
                    <DropdownCheckValue
                        item={value}
                        key={key}
                    />
                );
            });

        }
        
    }

    if(formData && formData[name] && item.type === 'numberformat'){
        fieldvalue = formatNumber(formData[name].value);
    }

    if(formData && formData[name] && (item.type === 'brreg_required' || item.type === 'brreg')){
        fieldvalue = formatOrgnr(formData[name].value);
    }

    if(formData && formData[name] && (name === 'user_phone')){
        fieldvalue = formatPhoneNumber(formData[name].value);
    }

    if(formData && formData[name] && item.type === 'ToggleSwitch'){
        fieldvalue = (formData[name].value === 'no') ? 'Nei' : 'Ja';
    }
    if(formData && formData[name] && item.type === 'RadioGroup'){
        /**
         * Her er det en gruppe med radioknapper, så vi må sammenligne value med et av alternativene for å få label
         */
        let stuff = item.alternatives.filter((word)=> {
            return word.value === formData[name].value;
        });
        fieldvalue = stuff[0].label;
    }
    
    return (
        <>
            {showField && <div className="col-12 summary-formfield"><b className={`font-weight-bold ${fieldvalue === '' || fieldvalue === 'ikke satt' ? 'text-danger' : ''}`}>{label}</b> <br />{fieldvalue} <b>{item.suffix !== '' ? <b>{item.suffix}</b> : ''}</b></div>}
            {hasChildren && innerElements && innerElements.map((item, key) => {
                return(
                    <SummaryFormElement item={item} formData={formData} key={key} />
                )
            })
                
            }
        </>
    );
}

const DropdownCheckValue = ({item}) => {
    return (
        <span className="bg-green p-1 px-2 me-2 rounded text-white">{item.label}</span>
    );
}

const SummaryFileValue = ({item}) => {
    return (
        <span className="bg-green p-1 px-2 me-2 rounded d-inline-block mb-2"><a className="text-white" rel="noreferrer" href={item.location} target="_blank">{item.name}</a></span>
    );
}
const OutputRepeaterFields = ({repeaterFields, repeaterValue}) => {

    return (
        <>
        <div className="row repeater-row gx-0 py-1">
            {repeaterFields.map((rf, key) => {
                return (
                    <OutputRepeaterField
                        key={key}
                        name={rf.name}
                        value={repeaterValue[rf.name].value}
                        suffix={rf.suffix ? rf.suffix : ''}
                    />
                )
            })}
            </div>
        </>
    );
}
const OutputRepeaterField = (props) => {
    let {value, suffix, name} = props;
    const { type:fieldtype } = useTypeBook(name);
    
    if(fieldtype === 'numberformat'){
        value = formatNumber(value);
    }

    return (   
        <>
            <div className="col">{value} {suffix ? <b>{suffix}</b> : ''}</div>
        </>
    );
}