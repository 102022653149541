import { Outlet } from "react-router-dom";
import { FinalReportProvider } from "../providers/FinalReportProvider";

export default function FinalReport(){
    return (
        <FinalReportProvider>
            <Main />
        </FinalReportProvider>
    )
}

function Main(){
    return (
        <Outlet />
    )
}