import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../providers/ApplicationProvider"



const CalculateFields = (props) => {
    const {formData} = useContext(ApplicationContext);
    const {
        fields, 
        display, 
        sublabel,
        rules
    } = props;

    const [notEnough, setNotEnough] = useState(false);
    const [errortext, setErrortext] = useState('');

    // console.trace(rules);

    const getArraySum = (a) => {
        let total = 0;
        for(let i in a){
            let val = 0;
            if(formData[a[i].name] === undefined){
                continue;
            }
            if( !('repeaterfield' in a[i])){
                val = formData[a[i].name].value;
            }
            if('repeaterfield' in a[i]){
                let repeaterField = a[i].repeaterfield;
                let reducer = formData[a[i].name].value.map((item, key) => {
                    if (item[repeaterField] === undefined){
                        return 0;
                    }
                    else{
                        return item[repeaterField].value
                    }       
                });
                val = reducer.reduce((sum, x) => Number(sum) + Number(x));
            }
            if(a[i].method === 'add'){
                total += Number(val);
            }
            if(a[i].method === 'subtract'){
                total -= Number(val);
            }
        }
        return total;
    }
    
    
    let amount = 0;

    if ( Object.keys(formData).length && fields.length) {
        amount = getArraySum(fields);
    }

    
    const validateCalculateRule = (rules, amount) => {

        if(rules){
            const {name, type, percent, errormessage} = rules.dependFieldPercent;
            const checkValue = (formData[name] && formData[name].value) ? formData[name].value : 0;
            
            switch(type){
                case 'lesser':
                    let currentPercent = (amount / checkValue) * 100;
                    if(currentPercent >= percent){
                        setNotEnough(false);
                        setErrortext('')
                    }else{
                        setNotEnough(true);
                        setErrortext(errormessage)
                    }
                    break;
                default:
                    setNotEnough(true);
                    setErrortext(`Mangler regel i CalculateFields.jsx på type = ${type}`);
            }       
        }
    }

    useEffect(() => {
        validateCalculateRule(rules, amount);
    }, [amount])
    

    const formatConfig = {
        style: "currency",
        currency: "NOK", // CNY for Chinese Yen, EUR for Euro
        minimumFractionDigits: 0,
        currencyDisplay: "symbol",
      };


    return (
        <>
            <div className={display ? display : ''}>
                <div className="row d-flex justify-content-center">
                    <div className="col-6">
                        <div className="bg-light-grey rounded-more py-1">
                            <p className="p-0 m-0 calc-label">
                                <strong>
                                    {Intl.NumberFormat('nb-NO', formatConfig).format(amount)}
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {sublabel ? <small><i>{sublabel}</i></small> : ''}
            {
                notEnough ? <div className="input-warning-message-box">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="p-2">
                                <FontAwesomeIcon icon={['far', 'circle-info']} className="text-error opacity" size="4x" />
                            </div>
                        </div>
                        <div className="col"> {errortext} </div>
                    </div>
                </div> : null
            }
        </>
    );
}
 
export default CalculateFields;