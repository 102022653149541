import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import validateRules from "./Validations";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { ConfigContext } from "../providers/ConfigProvider";
export default function Textarea(props){
    const { 
        value,
        onChange,
        placeholder,
        type,
        maxLength,
        classes,
        error,
        errorMessage,
        validation,
        disabled,
        rules,
        readonly
    } = props
    const {formData} = useContext(ApplicationContext);
    const { lingo } = useContext(ConfigContext);
    const [focus, setFocus] = useState(false)
    const [isValidating, setIsValidating] = useState(false)
    const [valid, setValid] = useState({error: false, valid: true})
    const [hasFiredOnChange, setHasFiredOnChange] = useState(false)

     let typingTimer;

    useEffect(() => {
        clearTimeout(typingTimer)
        if(validation){
            setIsValidating(true)
            const isValid = validation(value);
            setValid(isValid)
            setIsValidating(false)
        }

        if(rules && typeof rules !== 'undefined'){
            return setValid(validateRules(value, rules, formData, lingo));
        }else{
            return setValid({error: false, valid: true});
        }
        
    }, [value]);

    useEffect(() => {
        onChange && onChange(value, valid.valid);
    },[valid]);

    return (
        <div>
        <div className={(readonly ? 'readonly font-italic smaller ' : '') + "textarea-group"}>
            <textarea 
                maxLength={maxLength? maxLength : 1000}
                type={type ? type : 'textarea'}
                placeholder={placeholder} 
                className={"textarea " + (disabled ? 'disabled ' : '') + (valid.valid ? 'valid ' : '') + (valid.error && hasFiredOnChange ? 'error ' : '')}
                value={value}
                rows={readonly ? 1 : 4}
                onFocus={(e) => setFocus(true)}
                onBlur={() => setFocus(false)}
                onChange={(e) => {
                    setHasFiredOnChange(true)
                    onChange(e.target.value, valid.valid)        
                }}
                disabled={disabled ? disabled : null}
            />
                
                
            
            {maxLength ? <span className="textarea-max-length">{value.length} / {maxLength}</span> : null}
            
            {valid.valid || error ? <div className={"input-validation-icon " + (valid ? 'valid' : 'error')}>
                <FontAwesomeIcon icon={['fas', valid ? 'check' : 'times']} size="sm" color="white" />
            </div> : null}


        </div>
        {
            hasFiredOnChange && valid.error && valid.message ? <span className="input-error-message">{valid.message}</span> : null
        }
    </div>
    )

}