import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"

export default function DropDown(props){
    const { 
        value, 
        onChange, 
        placeholder, 
        classes, 
        disabled, 
        prefix,
        error,
        errorMessage,
        valid,
        options
    } = props

    const [open, setOpen] = useState(false)
    const [focus, setFocus] = useState(false)
    const nodeRef = useRef(null);

    useEffect(() => {
        
    }, [open])

    const Option = ({ label, val }) => {
        return (
            <li 
                className={value && value.value === val ? 'active' : ''} 
                onClick={() => {
                    setOpen(false)
                    onChange({label: label, value: val}, true)
                }}
            >{label}</li>
        )
    }

    return (
        <div className="position-relative">
            <div className="form-group position-relative mb-1" onClick={() => setOpen(!open)}>
                <div className={"dropdown input-group " + (open ? 'focus ' : '') + (disabled ? 'disabled ' : '') + (valid ? 'valid ' : '') + (error ? 'error ' : '')}>  
                    <p>{value && value !== '' ? value.label : placeholder}</p>
                    <FontAwesomeIcon icon={['fal', 'chevron-down']} />
                </div>
            </div>
             
            <CSSTransition nodeRef={nodeRef} in={open} unmountOnExit timeout={200} classNames="dropdown">
                <div ref={nodeRef} className="dropdown-options">
                    <ul>
                        {
                            options.map((item, key) => {
                                return (
                                    <Option 
                                        key={key}
                                        label={item.label}
                                        val={item.value}
                                    />
                                )
                            })
                        }
                    </ul>
                </div>
            </CSSTransition>
             
        </div>
    )
}