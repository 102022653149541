import React, { useContext, useEffect, useState } from "react";
import axios from 'axios'
import config from '../config.json'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import { faChevronDoubleLeft } from "@fortawesome/pro-thin-svg-icons";
import { ConfigContext } from "./ConfigProvider";
export const ApplicationContext = React.createContext({
    applicationId: null,
    draftId: null,
    applicationType: null,
    data: null,
    application: null,
    section: null,
    form: null,
    formData: null,
    animate: false,
    configJsonArray: [],
    hasDraft: false,
    isProgressDone: false,
    consentApproved: false,
    deleteDraftFromDataBase: () => {},
    setIsProgressDone: () => {},
    getDraftDetails: () => {},
    getDraftFromDataBase: () => {},
    setApplicationData: () => {},
    setApplicationId: () => {},
    setApplicationType: () => {},
    next: () => {},
    prev: () => {},
    updateForm: () => {},
    removeProperty: () => {},
    bulkUpdateForm: () => {},
    saveDraft: () => {}
})

export const ApplicationProvider = ({children}) => {

    const { data, lingo } = useContext(ConfigContext)
    const [applicationId, setApplicationId] = useState(null);
    const [applicationType, setApplicationType] = useState(null);
    const [draftId, setDraftId] = useState(null);

    const [application, setApplication] = useState(null)
    const [formData, setFormData] = useState({})

    const [isProgressDone, setIsProgressDone] = useState(false);
    const [consentApproved, setConsentApproved] = useState(false)

    const [section, setSection] = useState(null)
    const [form, setForm] = useState(null)
    
    const [animate, setAnimate] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    const {apiCall} = useContext(AuthContext);

    const [doneProcessingDBData, setDoneProcessingDBData] = useState(false);

    const [configJsonArray, setConfigJsonArray] = useState([]);

    const [counter, setCounter] = useState(0);

    useEffect(() => {
        
    }, [formData])

    useEffect(() => {
        // fylle elementArr her
        if(application && application.length !== 0){
            const sectionArr = application.sections.filter(section => 
                section.type === 'form' || section.type === 'user_info'
            );
            
            let elementArr = [];
            sectionArr.map((section) => {
                let innerElement = [];
                section.forms.map((el) => {
                    el.elements.map((field) => {
                        if(field.type !== 'calculate'){
                            innerElement.push(field);
                        }
                        
                    });
                })
                elementArr.push({
                    title: section.title,
                    url: section.url,
                    valid: true,
                    items: innerElement
                });
            });
            setConfigJsonArray(elementArr);
        }
        

    }, [application, formData])

    useEffect(() => {
        
    }, [applicationId])

    useEffect(() => {
        
    }, [applicationType])

    useEffect(() => {
        
        if(application){

            if(params.formType){
                const currentApplication = data.applications.filter(application => application.url === params.formType)
                if(currentApplication){
                    setApplication(currentApplication[0].application)
                    setApplicationType(currentApplication[0].type)
                }
            }

            if(params.section){
                if(application.sections.filter(section => section.url === params.section).length){
                    setSection(application.sections.filter(section => section.url === params.section)[0])
                }
            }else{
                setSection(null)
            }

            if(params.form){
                
                if(section){
                    if(section.forms && section.forms.length){
                        
                        if(section.forms.filter(form => form.url === params.form).length){
                            setForm(section.forms.filter(form => form.url === params.form)[0])
                        }
                    }
                }
            }else{setForm(null)}
        }
    }, [location])

    useEffect(() => {
        // setTimeout(() => {
            validateSections();
        // }, 2000)
        
    }, [location, formData]);
    
    const isFieldValid = (fieldname) => {

        if(formData[fieldname] === undefined){
            return false;
        }

        return formData[fieldname].valid;
        
    }
    
    const validateSections = () => {

        if(configJsonArray.length !== 0){
            configJsonArray.map((section, sectionkey) => {
                configJsonArray[sectionkey].valid = true;
                section.items.map((item, itemkey) => {
                    if(!isFieldValid(item.name)){
                        configJsonArray[sectionkey].valid = false;
                    }
                })
            })
            setConfigJsonArray(configJsonArray);
        }
    }

    useEffect(() => {
        if(application){
            if(params.section){
                if(application.sections.filter(section => section.url === params.section).length){
                    setSection(application.sections.filter(section => section.url === params.section)[0])
                }
            }else{
                setSection(null)
            }
            // Kommentert den ut her fordi den skal kjøres på trykk
            //populateFormDataFromDatabase();
        }
    }, [application])

    useEffect(() => {

        if(doneProcessingDBData){

        }
    }, [doneProcessingDBData]);

    useEffect(() => {

        if(params.form){
            if(section){
                if(section.forms && section.forms.length){
                    if(section.forms.filter(form => form.url === params.form).length){
                        setForm(section.forms.filter(form => form.url === params.form)[0])
                    }
                }
            }
        }else{setForm(null)}
    }, [section])

    const checkDrafts = async () => {
        //Sjekk om brukeren har noen drafts og sett hasDraft til true
        const object = {
            applicationType: applicationType,
        };
        const response = await apiCall({
            action: 'checkForDraft',
            ...object
        })
        const data = await response.data;
        
        if ( data.status === 1){
            setDraftId(data.data.draft.applicationId);
            
        }else{
            setDraftId(null);
        }
    }

    useEffect(() => {
        if(applicationType){
            checkDrafts();
        }
        
    }, [applicationType]);

    return <ApplicationContext.Provider value={{
        applicationId,
        applicationType,
        draftId,
        data,
        lingo,
        application,
        section,
        configJsonArray,
        form,
        formData,
        animate,
        isProgressDone,
        setIsProgressDone: (value) => {
            setIsProgressDone(value);
        },
        consentApproved, setConsentApproved,
        next: () => {
            setAnimate(false);
            setTimeout(() => {            
                if(!section){
                    if(application.sections[0].forms && application.sections[0].forms.length){
                        navigate(`/soknadskjema/${application.url}/${application.sections[0].url}/${application.sections[0].forms[0].url}`)
                    }
                }

                if(form){
                    const formIndex = section.forms.findIndex(f => f.url === form.url)
                    if(section.forms[formIndex + 1]){
                        navigate(`/soknadskjema/${application.url}/${section.url}/${section.forms[formIndex + 1].url}`)
                    }else{
                        const sectionIndex = application.sections.findIndex(s => s.url === section.url)
                        if(application.sections[sectionIndex + 1]){
                            navigate(`/soknadskjema/${application.url}/${application.sections[sectionIndex + 1].url}`)
                        }
                    }
                }else{
                    if(section){
                        const sectionIndex = application.sections.findIndex(s => s.url === section.url)
                        if(application.sections[sectionIndex + 1]){
                            if(application.sections[sectionIndex + 1].forms && application.sections[sectionIndex + 1].forms.length){
                                navigate(`/soknadskjema/${application.url}/${application.sections[sectionIndex + 1].url}/${application.sections[sectionIndex + 1].forms[0].url}`)
                            }else{
                                navigate(`/soknadskjema/${application.url}/${application.sections[sectionIndex + 1].url}`)
                            }

                        }
                    }
                }
                setAnimate(true)
            }, 500)
        },
        prev: () => {
            setAnimate(false)
            setTimeout(() => {

                if(form){
                    const formIndex = section.forms.findIndex(f => f.url === form.url)
                    if(section.forms[formIndex - 1]){
                        navigate(`/soknadskjema/${application.url}/${section.url}/${section.forms[formIndex - 1].url}`)
                    }else{
                        const sectionIndex = application.sections.findIndex(s => s.url === section.url)
                        if(application.sections[sectionIndex - 1]){
                            navigate(`/soknadskjema/${application.url}/${application.sections[sectionIndex - 1].url}`)
                        }
                    }
                }else{
                    if(section.type == "modal"){
                        //gå til forrige section og siste url
                        const sectionIndex = application.sections.findIndex(s => s.url === section.url);
                        
                        if(application.sections[sectionIndex - 1]){
                            const prevSection = application.sections[sectionIndex - 1];

                            if(application.sections[sectionIndex - 1].forms && application.sections[sectionIndex - 1].forms.length){
                                navigate(`/soknadskjema/${application.url}/${prevSection.url}/${prevSection.forms[prevSection.forms.length - 1].url}`)
                            }else{
                                navigate(`/soknadskjema/${application.url}/${prevSection.url}`)
                            }
                            
                        }
                    }else if(section){
                        
                        const sectionIndex = application.sections.findIndex(s => s.url === section.url);
                        if(application.sections[sectionIndex - 1]){
                            if(application.sections[sectionIndex - 1].forms && application.sections[sectionIndex - 1].forms.length){
                                navigate(`/soknadskjema/${application.url}/${application.sections[sectionIndex - 1].url}/${application.sections[sectionIndex + 1].forms[0].url}`)
                            }else{
                                navigate(`/soknadskjema/${application.url}/${application.sections[sectionIndex - 1].url}`)
                            }
                            
                        }
                    }
                }
                setAnimate(true)
            }, 500)
        },
        setApplicationData: (type) => {
            if(data){
                const currentApplication = data.applications.filter(application => application.url === type)
                setApplication(currentApplication[0].application)
                setApplicationType(currentApplication[0].type)
                setTimeout(() => {
                    setAnimate(true)
                }, 1000)
                
            }
        },
        updateForm: (name, value, valid, isFile) => {
                const newData = Object.assign({}, formData)
                newData[name] = {
                    value: value,
                    valid: valid
                }
                if(isFile){
                    newData[name].file = true;
                }
                setFormData(newData)
        },
        removeProperty: (name, i) => {
            const newData = Object.assign({}, formData)

            if(newData[name] && newData[name].value[i]){
                newData[name].value.splice(i, 1);
                setFormData(newData);
            }else{
                alert("Kunne ikke slette rad. Kontakt systemadministrator")
            }
            
        },
        bulkUpdateForm: (newFormData) => {
                let newObject = Object.assign(formData, newFormData);
                setFormData(newObject);
        },
        setApplicationId: (applicationId) => {
            setApplicationId(applicationId);
        },
        setApplicationType: (applicationType) => {
            setApplicationType(applicationType);
        },
        getDraftDetails: async (putId) => {
            let dbData = apiCall({
                action: 'getApplicationDraftFromDB',
                applicationId: putId
            })

            const configJson = await application;
            
            if(dbData && dbData.data.status === 1 && configJson){
                return dbData.data;
            }else{
                return false;
            }
        },
        getDraftFromDataBase: async () => {
            let happydata = [];

            let dbData = await apiCall({
                action: 'getApplicationDraftFromDB',
                applicationId: draftId
            })
    
            const configJson = await application;
    
            if(dbData && dbData.data.status === 1 && configJson){
                
                dbData = dbData.data.data.json;
    
                const sectionArr = configJson.sections.filter(section => 
                    section.type === 'pre' || section.type === 'form' || section.type === 'user_info'
                );
                let elementArr = [];
    
                sectionArr.map((section) => {
                    section.forms.map((el) => {
                        el.elements.map((field) => {
                            elementArr.push(field);
                            if(field.depends != null && typeof field.depends === 'object'){
                                field.depends.map(field_element => {
                                    field_element.innerElements.map(fei => {
                                        elementArr.push(fei);
                                    })
                                })
                            }
                        });
                    })
                });
    
                
    
                elementArr.map(el => {
                    let obj = {}
                    let inner = {}
    
                    switch(el.type){
                        case 'dropdown':
                            // value: {label: "Jevnaker", value: "jevnaker"}
                            if(dbData[el.name]){
                                let innerval = {}
                                innerval.label = dbData[el.name].replaceAll('_', ' ');
                                innerval.value = dbData[el.name].toLowerCase();
                                inner.value = innerval;
                            }
                            
                            break;
                        case 'fileuploadsingle':
                        case 'fileupload':
                            if(dbData[el.name] && el.name.startsWith('filer_')){
                                inner.existing_files = dbData[el.name];
                            }
                            break;
                        case 'bigradio':
                        case 'ToggleSwitch':
                        case 'RadioGroup':
                            if(dbData[el.name]){
                                inner.value = dbData[el.name];
                            }
                            
                            break;
                            case 'repeater':
                        case 'dropdowncheck':
                            /*
                            fn-baerekraftsmaal: {valid: true, value: [
                                0 => {label: '1. Utrydde fattigdom', value: 'fng_01},
                                1 => {label: '2. Utrydde sult', value: 'fng_02'}
                            ]}
                            */
                            if(dbData[el.name]) inner.value = JSON.parse(dbData[el.name])
                            break;
                        case 'radiogroupdepending':
                            if(dbData[el.name]){
                                inner.value = dbData[el.name];
                            }
                            break;
                        
                        case 'textarea':
                        case 'brreg':
                        case 'brreg_required':
                        case 'inputmask':
                        case 'numberformat':
                        case 'text':
                            if(dbData[el.name]){
                                inner.value = dbData[el.name];
                            }
                            break;
                    }
                    
                    if(Object.keys(inner).length !== 0){
                        inner.valid = true;
                        obj[el.name] = inner;
                        happydata = {...happydata, ...obj};
                    }
                    
                    
                    
                    
                });
                if(happydata.length !== 0){
                    setFormData(happydata);
                    setApplicationId(draftId);
                }
            }
    
            setDoneProcessingDBData(true);

            /**
             * For å finne progresjon i soknaden må vi gå gjennom objektet som inneholder hele prosessen (configJson)
             * og sammenligne med objektet som holder på de feltene som er midlertidig lagret (happydata).
             * Først fjerner vi alle seksjoner som ikke har noe med formelementer å gjøre
             */
            const formSections = configJson.sections.filter(({forms}) => forms);

            /**
             * Her fjerner vi det vi enda ikke har i happydata fra configJson-objektet. 
             * På den måten luker vi ut seksjoner
             * Til slutt må vi finne ut hvilke forms/sider som enda ikke er fylt ut. Man bør fortsette på den siste validerte siden, og 
             * vi luker derfor ut forms som ikke har elementer matchende fra happydata
             */
            let strippedConfigSections = formSections.filter(({forms}) => {
                let formCol = [];
                forms.forEach(({elements}) => {
                    let elCol = [];
                    elements.forEach((element) => {
                        if(element.name && happydata.hasOwnProperty(element.name)){
                            elCol.push(element.name);
                        }
                    })
                    if(elCol.length){ formCol.push(elCol) }
                })
                return formCol.length
            });
            strippedConfigSections.forEach((section, i) => {
                strippedConfigSections[i].forms.filter((form) => {
                    let formCol = [];
                    form.elements.forEach((element) => {
                        if(element.name && happydata.hasOwnProperty(element.name)){
                            formCol.push(element.name);
                        }
                    })
                    return formCol.length;
                })
            })
            
            return strippedConfigSections;
        },
        saveDraft: async () => {
            let object = {}            
            for ( var key in formData ) {

                if(Array.isArray(formData[key].value)){
                    if(formData[key].file){
                        object[key] = formData[key].value;
                    }else{
                        object[key] = JSON.stringify(formData[key].value)
                    }
                } 
                else if( formData[key].existing_files ){
                    object['existingfile_' + key] = JSON.stringify(formData[key].existing_files);
                }
                else{
                    object[key] = formData[key].value;
                }

            }
            
            const status = await apiCall({
                action: 'saveApplicationDraft',
                applicationId: applicationId,
                applicationType: applicationType,
                ...object
            })
            
            if(!applicationId) setApplicationId(status.data.application_id);
            if(status && status.data && status.data.status && status.data.status == 1138){

                alert(lingo.application_already_submitted);
            }
            
            
            return status;
        },
        deleteDraftFromDataBase: async (applicationType, draftId) => {
            const status = await apiCall({
                action: 'deleteApplicationDraftFromDB',
                draftId: draftId,
                applicationType: applicationType
            });
            setDraftId(null);
            return status;
        }
    }}>{data?children: <h1>Laster config</h1>}</ApplicationContext.Provider>
}