import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../providers/ConfigProvider";

export default function Logo({className}) {
    const fulldata = useContext(ConfigContext);
    const [baseUrl, setBaseUrl] = useState(null);
    const [logo, setLogo] = useState(null);
    const [title, setTitle] = useState(null);

    //const {logo} = data;
    // Getting the logo
    useEffect(() => {
        if(fulldata && fulldata.baseURL && fulldata.data){
            setBaseUrl(fulldata.baseURL);
            setLogo(fulldata.data.logo);
            setTitle(fulldata.data.title);
        }
    }, [fulldata]);

    return <img src={`${baseUrl}${logo}`} alt={title} className={className ? className : null} />
}
