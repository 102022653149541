
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react'
import Button from '../components/Button';
import DropDown from '../components/DropDown';
import Input from '../components/Input';
import Textarea from '../components/Textarea';
import RadioGroup from '../components/RadioGroup';
import BubbleModal from '../components/BubbleModal';
import { ApplicationContext, ApplicationProvider } from '../providers/ApplicationProvider';
import UserInfo from './UserInfo';
import { Outlet } from 'react-router-dom';
import { ConfigContext } from '../providers/ConfigProvider';

export default function ApplicationForm(){
    const { currentFormData, navigator, lingo } = useContext(ApplicationContext)
    useEffect(() => {
    }, [navigator])

    if(currentFormData === null){
        return (
            <h3>{lingo.error}</h3>
        )
    }

    if(navigator.type === 'main'){
        return (
            <Main />
        )
    }

    if(navigator.type === 'initial'){
        return (
            <InitialQuestions />
        )
    }

    if(navigator.type === 'intro'){
        return (
            <Intro />
        )
    }

    return (
        <Intro/>
    )
}



export function ApplicationFormContainer(){
    return (
        <ApplicationProvider>
            <Outlet />
        </ApplicationProvider>
    )
}

function Intro(){
    const { currentFormData, navigate, lingo } = useContext(ApplicationContext)
    if(currentFormData === null){
        return (
            <h3>{lingo.error}2</h3>
        )
    }

    const nextStep = () => {
        navigate({
            type: 'initial',
            step: 0
        })
    }
    return (
        <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
            <div className='text-center container narrow'>
                <h1 className='mb-5'>{currentFormData.application.title}</h1>
                <img src={process.env.PUBLIC_URL + '/svg/intro.svg'} className='mb-4' alt="" />
                <p className='bold'>{currentFormData.application.introtext}</p>
                <Button 
                    title={lingo.start_application_process}
                    type="primary"
                    rightIcon="arrow-right"
                    onClick={() => nextStep()}
                />
            </div>
        </div>
    )
}

function InitialQuestions(){
    const { currentFormData, navigator, navigate, formData, updateForm } = useContext(ApplicationContext)
    const { title, alternatives, type, name } = currentFormData.application.pre_questions[navigator.step]
    const { lingo } = useContext(ConfigContext);

    const next = () => {
        setValid(false)
        setSelectedValue(null)
        if(currentFormData.application.pre_questions[navigator.step + 1]){
            navigate({
                step: 1,
                type: 'initial'
            })
        }else{
            navigate({
                step: 0,
                form: 0,
                type: 'main'
            })
        }
    }

    const [valid, setValid] = useState(false)
    const [selectedValue, setSelectedValue] = useState(null)

    useEffect(() => {
        if(formData[name]){
            setValid(true)
            const result = alternatives.filter(obj => obj.value === formData[name].value)[0]
            setSelectedValue(result)
        }
    }, [formData])
    return (
        <div style={{minHeight: '100vh'}} className="d-flex align-items-center justify-content-center h-100">
            <div className="text-center container narrow">
                <h6 className="text-green">{lingo.before_you_start}</h6>
                <h2>{title}</h2>
                <RadioGroup 
                    items={alternatives}
                    onCheck={(value) => updateForm(name, value, true)}
                    checked={formData[name] ? formData[name].value : null}
                    classes="mb-4"
                />
                {
                    valid && selectedValue.description ? (<div className="bg-light-green px-4 pt-4 pb-2 text-start mb-4" dangerouslySetInnerHTML={{__html: selectedValue.description}}></div>) : null
                }
                {
                    valid && <Button onClick={() => next()} title="Neste" rightIcon="arrow-right" type="primary" />
                }
            </div>
        </div>
    )

}

function Main(){
    const { currentFormData, navigator, navigate, formData, updateForm, lingo } = useContext(ApplicationContext)
    const data = currentFormData.application.sections[navigator.step]

    if(!data){
        return (
            <div>Laster</div>
        )
    }

    return (
        <div className="form-container">
            <div className="sidebar">
                <div className="close-sidebar">
                    <Button 
                        title={lingo.go_to_your_page}
                        leftIcon="chevron-left"
                        classes="py-4 w-100"
                    />
                </div>
                <div className="sidebar-navigation">
                    {
                        currentFormData.application.sections.map((item, key) => {
                            if(item.type === 'form' || item.type === 'user_info'){
                                
                                return (
                                    <SidebarSection
                                        key={key} 
                                        item={item}
                                        active={key === navigator.step}
                                    />
                                )
                            }
                        })
                    }
                    
                </div>
                
            </div>
            <div className="main-content">
                <div>
                    <ProgressBar />
                    <MainForm />
                    <Tips />
                    <Footer />
                </div>
            </div>
        </div>
    )
}

function ProgressBar(){
    const { currentFormData, formData} = useContext(ApplicationContext)
    const [totalProgress, setTotalProgress] = useState(0)
    const [currentProgress, setCurrentProgress] = useState(0)  

    useEffect(() => {
        updateProgress()
        
    }, [formData])

    const updateProgress = () => {
        let total = 0;
        let current = 0;

        currentFormData.application.sections.forEach((section) => {
            if(section.forms && section.forms.length){
                section.forms.forEach((form) => {
                    if(form.elements){
                        form.elements.forEach((element) => {
                            total += 1
                            
                            if(formData[element.name] && formData[element.name].valid){
                                current += 1
                                
                            }
                        })
                    }
                })
            }
            
        })

        setCurrentProgress(current)
        setTotalProgress(total)
    }
    
    return (
        <div className="progress-container">
            <div className="row justify-content-between align-items-end">
                <div className="col-auto">
                    <h4>{currentFormData.application.title}</h4>
                </div>
                
                <div className="col-auto">
                    <small>{Math.round((currentProgress / totalProgress) * 100)}% gjennomført</small>
                </div>
            </div>
            <div className="progress">
                <div className="progress-indicator" style={{width: (currentProgress / totalProgress) * 100 +'%'}}></div>
            </div>
        </div>
    )
}

function Footer(){
    const { currentFormData, navigator, navigate, formData, updateForm } = useContext(ApplicationContext)
    const data = currentFormData.application.sections[navigator.step]
    const [canGoBack, setCanGoBack] = useState(false);

    useEffect(() => {

        if(data.forms){
            if(data.forms[navigator.form - 1]){
                setCanGoBack(true)
                return
            }
                

            if(currentFormData.application.sections[navigator.step - 1]){
                setCanGoBack(true)
                return
            }

            setCanGoBack(false)
        }
        if(!data.forms){
            return (<div></div>);
        }

    }, [navigator]);

    const next = () => {
        if(data.forms[navigator.form + 1]){
            navigate({
                step: navigator.step,
                form: navigator.form + 1,
                type: 'main'
            })
            return
        }

        if(currentFormData.application.sections[navigator.step + 1]){
            navigate({
                step: navigator.step + 1,
                form: 0,
                type: 'main'
            })
            return
        }
    }

    const prev = () => {
        if(data.forms[navigator.form - 1]){
            navigate({
                step: navigator.step,
                form: navigator.form - 1,
                type: 'main'
            })
            return
        }

        if(currentFormData.application.sections[navigator.step - 1]){
            
            navigate({
                step: navigator.step - 1,
                form: currentFormData.application.sections[navigator.step - 1].forms.length - 1,
                type: 'main'
            })
            return
        }
    }

    return (
        <div className="row justify-content-between bottombuttonwrapper">
            <div className="col-auto">
                { canGoBack ? 
                <Button 
                    title="Tilbake"
                    type="secondary"
                    leftIcon="arrow-left"
                    onClick={() => prev()}
                />
                : null}
            </div>
            <div className="col-auto">
                <Button 
                    title="Neste"
                    type="primary"
                    rightIcon="arrow-right"
                    onClick={() => next()}
                    
                />
            </div>
        </div>
    )
}

function Tips(){
    const { currentFormData, navigator, navigate, formData, updateForm } = useContext(ApplicationContext)
    const data = currentFormData.application.sections[navigator.step]

    /*
    icon: "tips.svg"
    title: "Tips"
    value: "<p>Her er de...</p>"
    */

    if(data && data.forms && data.forms[navigator.form].tips){
        let {icon, title, value} = data.forms[navigator.form].tips;

        return (
            <div className="form-content">
                <div className="row">
                    {icon ? 
                        <div className="col-auto">
                            <img src={process.env.PUBLIC_URL + '/svg/' + icon} className='mb-4' />    
                        </div>
                    : null}
                    <div className="col">
                        {title ? <h6 className="text-blue">{title}</h6> : null}
                        {value ? <div dangerouslySetInnerHTML={{__html: value}}></div> : null}
                    </div>
                </div>
            </div>
        );
    }
    return(<div></div>);
}

function MainForm(){
    const { currentFormData, navigator, navigate, formData, updateForm } = useContext(ApplicationContext)
    const data = currentFormData.application.sections[navigator.step]
    const [modalActive, setModalActive] = useState(false)

    if(!data){
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                <FontAwesomeIcon spin icon={['fal', 'spinner-third']} size='4x' />
            </div>
        )
    }
    if(data.type === 'user_info'){
        return (
            <UserInfo />
        );
    }
    if(data.type === 'modal'){
        
        let { title, icon, subtitle, content } = data;
        return (
            <BubbleModal active={data.type === 'modal'} setActive={setModalActive}>
                <div className="text-center narrow-60">
                    {title ? <h3 className="mb-4">{title}</h3> : null }
                    <div style={{height: 100, width: 100, backgroundColor: 'var(--green)', margin: '0 auto', borderRadius: '50%', marginBottom: 30}}></div>
                    {subtitle ? <p className="text-green">{subtitle}</p> : null }
                    {content ? <div className="" dangerouslySetInnerHTML={{__html: content}}></div> : null }
                
                    <Button 
                        title="Gå videre"
                        type="primary"
                        rightIcon="arrow-right"
                        onClick={() => navigate({
                            step: navigator.step + 1,
                            form: 0,
                            type: 'main'
                        })}
                        
                    />
                </div>
            </BubbleModal>
        )
    }

    return (
        <div className="form-content">
            <div>
                <div className="form-title mb-4">
                    <p className="m-0 hint-text">{data.title} - {navigator.form + 1} / {data.forms.length}</p>
                    <h1 className="m-0">{data.forms[navigator.form].title}</h1>
                    {
                        data.forms[navigator.form].elements.map((item, key) => {
                            return (
                                <FormElement 
                                    key={key}
                                    item={item}
                                />
                            )
                        })
                    }
                    
                </div>
            </div>
        </div>
    )
}

function FormElement({ item }){
    const { label, mask, name, placeholder, required, type } = item
    const { formData, updateForm } = useContext(ApplicationContext) 
    const [value, setValue] = useState(formData[name] ? formData[name] : '')

    
    useEffect(() => {

    }, [value])

    switch(type){
        case 'brreg':
            
            return (
                <div className="form-group mb-3">
                    <label>{label}</label>
                    <Input 
                        value={formData[name] ? formData[name].value : ''}
                        onChange={(value, valid) => updateForm(name, value, valid)}
                        validation={(value) => {
                            if(value.length !== 9){
                                return {
                                    valid: false,
                                    error: true,
                                    message: 'Organisasjonsnummer må være 9 siffer'
                                }
                            }

                            return {
                                valid: true,
                                error: false
                            }
                            
                            /*
                            await fetch('https://data.brreg.no/enhetsregisteret/api/enheter/' + value).then((response) => {
                                if(response.ok){
                                    return {
                                        valid: true,
                                        error: false
                                    }
                                }else{
                                    return {
                                        valid: false,
                                        error: false,
                                        message: 'Fant ikke organisasjon i brønnøysundsregisteret'
                                    }
                                }
                            })
                            */
            
                            
                        }}
                        {...item}
                    />
                </div>
            )
        case 'text':
            return (
                <div className="form-group mb-3">
                    <label>{label}</label>
                    <Input 
                        value={formData[name] ? formData[name].value : ''}
                        onChange={(value, valid) => updateForm(name, value, valid)}
                        {...item}
                    />
                </div>
            )
        case 'dropdown':
            return (
                <div className="form-group mb-3">
                    <label>{label}</label>
                    <DropDown
                        value={formData[name] ? formData[name].value : ''}
                        onChange={(value) => updateForm(name, value, true)}
                        {...item}
                    />
                </div>
            )
        case 'textarea':
            return (
                <div className="form-group mb-3">
                    <label>{label}</label>
                    <Textarea
                        value={formData[name] ? formData[name].value : ''}
                        onChange={(value, valid) => updateForm(name, value, valid)}
                        validation={(value) => {
                            if(value.length <= 10){
                                return {
                                    valid: false,
                                    error: true,
                                    message: 'Organisasjonsnummer må være 9 siffer'
                                }
                            }

                            return {
                                valid: true,
                                error: false
                            }
                        }}
                        
                        {...item}
                    />
                </div>
            );
        default:
            return (<div></div>)
    }


}

function SidebarSection({ item, active }){
    const { title, icon, short_description } = item

    return (
        <div className={"sidebar-navigation-item " + (active ? 'active' : '')}>
            <div className="sidebar-navigation-item-content">
                <h6>{title}</h6>
                <p>{short_description}</p>
            </div>
            <div className="sidebar-navigation-item-icon">
                <FontAwesomeIcon icon={['fal', icon]} />
            </div>
        </div>
    )
}