import { useContext, useState, useEffect } from "react";
import Input from "../components/Input";
import InputMask from "../components/InputMask";
import RadioGroup from "../components/RadioGroup";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { AuthContext } from "../providers/AuthProvider";


const UserInfo = () => {
    const {lingo, formData, updateForm, bulkUpdateForm, application, section, form} = useContext(ApplicationContext);    
    const [useInfo, setUseInfo] = useState('use-manual-info');
    const [hackRender, setHackRender] = useState(new Date());
    const [zip, setZip] = useState(formData['user_zip'] ? formData['user_zip'].value : '');
    const [city, setCity] = useState(formData['user_city'] ? formData['user_city'].value : '');
    const {user} = useContext(AuthContext);

    let profileUserInfo = {}

    const getProfileUserInfo = () => {
        profileUserInfo = {
            user_name: { value: user.fullname, valid: user.fullname !== '' },
            user_address: { value: user.address, valid: user.address !== '' },
            user_zip: { value: user.zip, valid: user.zip !== '' },
            user_city: { value: user.city, valid: user.city !== '' },
            user_email: { value: user.email, valid: user.email !== '' },
            user_phone: { value: user.phone, valid: user.phone !== '' }
        };
        setZip(user.zip)
    }

    const getCityFromPostCode = async (zipcode) => {
        const headers = {
            'X-MyBring-API-Uid': 'post@catchmedia.no',
            'X-MyBring-API-Key': 'aec45816-0c00-4ac8-88dc-a6d3e29cd771'
            //'X-Bring-Client-URL': window.location.href
        }

        const url = `https://api.bring.com/pickuppoint/api/postalCode/NO/getCityAndType/${zipcode}.json`;
        //const url = `https://webapi.no/api/v1/zipcode/${zipcode}`;
        
        const response = await fetch(url, { headers });
        //const response = await fetch(url);
        const json = await response.json();
        if(response.ok){
            return json.postalCode.city;
            //return json.data.city;
        }else{
            return '';
        }
    }
    
    const checkPostCode = (value) => {
        
        setZip(value);
        //updateForm('user_zip', value, false);
        let isnum = /^\d+$/.test(value);
        
        if(isnum){
            if(value.length === 4){
                getCityFromPostCode(value)
                .then( (response) => {
                    if(response !== ''){
                        bulkUpdateForm({
                            user_city: {value: response, valid: true},
                            user_zip: {value: value, valid: true},
                        });
                        setHackRender(new Date());
                    }else{
                        
                    }
                });
            }
            if(value.length !== 4){
                updateForm('user_city', '', false);
                setHackRender(new Date());
            }
        }else{
            updateForm('user_city', '', false);
            setHackRender(new Date());
        }
    }

    useEffect(() => {
        if(useInfo === 'use-profile-info'){
            getProfileUserInfo();
            bulkUpdateForm(profileUserInfo);
            //setZip(formData['user_zip'] ? formData['user_zip'].value : '');
            setHackRender(new Date());
        }else{
            setHackRender(new Date());
        }
    },[useInfo]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        checkPostCode(zip);
    }, [zip])

    const formIndex = section.forms.findIndex(s => s.url === form.url)

    const { title } = form

    if(form.url === 'kontakt-og-organisasjon'){
        return (
            <div className="form-content">
                <div>
                    <div className="form-title mb-4">
                        <p className="m-0 hint-text">{title} - {formIndex + 1} / {section.forms.length}</p>
                        <h2 className="m-0 mb-4">{lingo.contact_organization_title}</h2>

                        <div className="form-group mb-3">
                            <label>{lingo.your_role_title}</label>
                            <Input
                                value={formData['your_role_organization'] ? formData['your_role_organization'].value : ''}
                                onChange={(value, valid) => updateForm('your_role_organization', value, valid)}
                                placeholder={lingo.your_role_placeholder}
                                required={true}
                                maxLength={100}
                                name="your_role_organization"
                                type="text"
                                rules={{
                                    minLength:{
                                        "errorvalue":2,
                                        "errormessage":lingo.field_2_char_or_more
                                    }
                                }}
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label className="my-2">{lingo.board_behind_application}</label>
                            <RadioGroup
                                items={[
                                    {label: lingo.behalf_of_board, value: 'behalf-of-board'},
                                    {label: lingo.own_initiative, value: 'own-initiative'}
                                ]}
                                onCheck={(value) => updateForm('main_control_behind', value, true)}
                                checked={formData['main_control_behind'] ? formData['main_control_behind'].value : ''}
                                classes="justify-content-start"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if(form.url === 'kontaktinformasjon'){
        return (
            <div className="form-content">
                <div>
                    <div className="form-title mb-4">
                        <p className="m-0 hint-text">{title} - {formIndex + 1} / {section.forms.length}</p>
                        <h2 className="m-0 mb-4">{lingo.contact_info}</h2>

                        <div className="form-group mb-3">
                            <label>{lingo.userinfo_email_label}</label>
                            <Input 
                                value={formData['user_email'] ? formData['user_email'].value : ''}
                                onChange={(value, valid) => updateForm('user_email', value, valid)}
                                placeholder={lingo.userinfo_email_placeholder}
                                required={true}
                                maxLength={100}
                                name="user_email"
                                type="text"
                                rules={{
                                    email:{},
                                    required:{}
                                    
                                }}
                            />
                        </div>

                        <div className="form-group mb-3">
                            <label>{lingo.userinfo_phone_label}</label>
                                <InputMask

                                    value={formData['user_phone'] ? formData['user_phone'].value : ''}
                                    onChange={(value, valid) => {
                                        updateForm('user_phone', value, valid)
                                    }}
                                    placeholder={lingo.userinfo_phone_placeholder}
                                    required={true}
                                    maxLength={8}
                                    replace=" "
                                    replaceWith=""
                                    mask="00 00 00 00"
                                    name="user_phone"
                                    type="inputmask"
                                    rules={{
                                        minLength: {
                                            errorvalue:8,
                                            errormessage:lingo.userinfo_phone_error
                                        }
                                    }}
                                />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="form-content">
            <div>
                <div className="form-title mb-4">
                    <p className="m-0 hint-text smaller">{lingo.contact_person} - {formIndex + 1} / {section.forms.length}</p>
                    <h2 className="m-0 mb-4">{lingo.contact_organization_title}</h2>

                    <div className="form-group mb-3">
                        <RadioGroup 
                            items={[
                                { label: lingo.userinfo_button_use_profile_info, value: 'use-profile-info'},
                                { label: lingo.userinfo_button_use_manual_info, value: 'use-manual-info'}
                            ]}
                            onCheck={(e) => {
                                setUseInfo(e)
                            }}
                            checked={useInfo}
                            classes="justify-content-start"
                        />
                    </div>

                    <div className="form-group mb-3">
                        <label>{lingo.userinfo_name_label}</label>
                        <Input 
                            value={formData['user_name'] ? formData['user_name'].value : ''}
                            onChange={(value, valid) => {
                                updateForm('user_name', value, valid)
                            }}
                            placeholder={lingo.userinfo_name_placeholder}
                            required={true}
                            maxLength={100}
                            name="user_name"
                            type="text"
                            rules={{
                                "required":{
                                }
                            }}
                        />
                    </div>
                    
                    <div className="form-group mb-3">
                        <label>{lingo.userinfo_address_label}</label>
                        <Input 
                            value={formData['user_address'] ? formData['user_address'].value : ''}
                            onChange={(value, valid) => {
                                updateForm('user_address', value, valid)
                            }}
                            placeholder={lingo.userinfo_address_placeholder}
                            required={true}
                            maxLength={100}
                            name="user_address"
                            type="text"
                            rules={{
                                "required":{
                                }
                            }}
                        />
                    </div>

                    <div className="form-group mb-3">
                        <label>{lingo.userinfo_user_zip_label}</label>
                        <div className="row">
                            <div className="col-lg-4">
                                <Input 
                                    value={zip}
                                    onChange={(value, valid) => {
                                        updateForm('user_zip', value, valid);
                                            setZip(value);
                                        //checkPostCode(value);
                                    }}
                                    placeholder={lingo.userinfo_user_zip_placeholder}
                                    required={true}
                                    name="user_zip"
                                    type="text"
                                    maxLength={4}
                                    rules={{
                                        minLength: {
                                            errorvalue:4,
                                            errormessage:""
                                        },
                                        numbersOnly:{}
                                    }}
                                />
                            </div>
                            <div className="col">
                                <Input
                                    value={formData['user_city'] ? formData['user_city'].value : ''}
                                    // onChange={(value, valid) => {
                                    //     //updateForm('user_city', value, valid)
                                    //     //updateForm('user_city', value, true)
                                    // }}
                                    placeholder={lingo.userinfo_user_city}
                                    required={true}
                                    name="user_city"
                                    type="text"
                                    maxLength={100}
                                    rules={{
                                        required: {}
                                    }}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
     );
}
 
export default UserInfo;