import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createRef, useEffect, useState } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { useContext } from "react"
import { ConfigContext } from "../providers/ConfigProvider";
export default function FileUploadMaxTwo(props){


    /*
        kode til config.json
        { 
            "label": "Last opp inntil 2 filer",
            "name": "filer_andre", 
            "type": "fileuploadmaxtwo",
            "maxNumberOfFilesLimit": 2,
            "extensions": "Word/Excel/Powerpoint/PDF",
            "extensionsFormat": [".docx",".doc",".xlsx",".xlsm",".csv",".xls",".ppt",".pptx",".pdf"]
        },
    */

    const { maxNumberOfFilesLimit, onHandleDrop, files, onRemoveFile, existing_files, readonly, final_report, extensions, extensionsFormat, validateNewFiles } = props
    const { lingo } = useContext(ConfigContext);
    const container = createRef()
    const [dragging, setDragging] = useState(false)
    const [mousePosition, setMousePosition] = useState({})
    const [validFileType, setValidFileType] = useState(true);
    const fileSelector = document.createElement('input');

    const renderAcceptList = (extensionsFormat) => {
        let output = "";

        if(extensionsFormat && extensionsFormat.length){
            output = extensionsFormat.join(",");
        }
        
        return output;
    }

    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', renderAcceptList(extensionsFormat));
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.addEventListener('change', (e) => {
        e.preventDefault();
        let path = e.path || (e.composedPath && e.composedPath());
        if(validateNewFiles(path[0].files)){
            setValidFileType(true);
            onHandleDrop(path[0].files, maxNumberOfFilesLimit)
        }else{
            setValidFileType(false);
        }
        // onHandleDrop(e.path[0].files)
    })
    
    useEffect(() => {
        if(container.current){
            ['dragenter', 'dragover'].forEach(eventName => {
                container.current.addEventListener(eventName, (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    
                    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                        setDragging(true)
                        /*
                        setMousePosition({
                            position: 'fixed',
                            top: e.pageY,
                            left: e.pageX
                        })
                        */
                    }
                }, false)
                
            });

            ['dragleave'].forEach(eventName => {
                container.current.addEventListener(eventName, (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                        setDragging(false)
                    }
                }, false)
                
            })

        
            container.current.addEventListener('drop', (e) => {
                e.preventDefault()
                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                    setDragging(false)
                    if(validateNewFiles(e.dataTransfer.files)){
                        onHandleDrop(e.dataTransfer.files, maxNumberOfFilesLimit)
                    }
                    setValidFileType(validateNewFiles(e.dataTransfer.files));
                    e.dataTransfer.clearData()  
                }
                
            })
        }

        return () => {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                if(container.current){
                    container.current.removeEventlistener(eventName)
                }
            })
        }
    }, [container])

    
    const formatExtensions = (extensions) => {
        if(extensions){
            let output = '';
            let typeArr = extensions.split('/');
            let lastItem = typeArr.pop();

            if(typeArr.length){
                output = typeArr.join(", ");
            }
            output += ` ${lingo.ext_and} ${lastItem} ${lingo.ext_is_legal}`;
            return output;
        }else{
            return lingo.ext_all_is_legal
        }
    }

    const FileItem = ({ file }) => {

        let icon = 'file-text';
        switch(file.type){
            case 'application/pdf':
                icon = 'file-pdf';
                break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                icon = 'file-word'
                break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                icon = 'file-excel';
                break;
            default:
                icon = 'file-text'
            
        }
        return (
            <div className="file-item">
                <div className="px-2 text-center" title={file.name}>
                    <FontAwesomeIcon icon={['fas', icon]} size="3x" />
                    <h6>{file.name}</h6>
                    <h6 className="remove-file text-danger" onClick={() => {
                        setValidFileType(true);
                        onRemoveFile(file);
                    }}>
                    <FontAwesomeIcon className="px-1" icon={['far', 'times']} size="lg"></FontAwesomeIcon> Fjern fil</h6>
                </div>
            </div>
        )
    }
    
    const ExistingFilesItem = ({file}) => {
        if(!file) return <div></div>
        const filetype = file?.name?.split('.').pop();
        let icon = 'file-text';
        switch(filetype){
            case 'pdf':
                icon = 'file-pdf';
                break;
            case 'docx':
            case 'doc':
                icon = 'file-word'
                break;
            case 'xmlx':
            case 'xml':
                icon = 'file-excel';
                break;
            default:
                icon = 'file-text'
        }

        

        return(
            <div className="file-item">
                <div className="px-2 text-center" title={file.name}>
                    <FontAwesomeIcon icon={['fal', icon]} size="3x" className="mb-1 opacity-light" />
                    <h6>{file.name}</h6>
                    <h6 className="remove-file text-danger" onClick={() => onRemoveFile(file)}><FontAwesomeIcon className="px-1" icon={['far', 'times']} size="lg"></FontAwesomeIcon> Fjern fil</h6>
                </div>
            </div>
        );
    }
    const ReadonlyFilesItem = ({file}) => {
        const { baseUrl } = useContext(AuthContext)

        const output = file.map(fileObj => {
            return (<a key={fileObj.key} target="_blank" href={baseUrl + fileObj.url}>{lingo.ext_see_file_from_application}</a>)
        })

        return output;

    }
    if(readonly && typeof readonly !== "undefined"){
        return (
            <div>
                {final_report && final_report.length !== 0 ? <ReadonlyFilesItem file={final_report} /> : <p><small><i>{lingo.ext_no_file_on_this_point}</i></small></p>}
            </div>
        )
    }else{
        return (
            <div>
            <div ref={container} className={"file-upload-container " + (dragging ? 'highlight ' : '') + (!validFileType && 'invalid ')}>
                {
                (files && (files.length === 0))?
                    <div className="file-wrapper">
                        <div className="file-upload-icon">
                            <div className="file-upload-bubble" style={dragging ? mousePosition : null}></div>
                            <FontAwesomeIcon icon={['fal', 'file-text']} size="4x" />
                        </div>
                    </div>
                : 
                    (<>
                    <div className="file-wrapper">
                        {
                            existing_files && !Array.isArray(existing_files) && existing_files.length !== 0 ? <ExistingFilesItem file={existing_files} /> : <ExistingFilesItem file={existing_files[0]} />
                        }
                        {
                            files && files.map((el, index) => {
                                return (
                                    <FileItem 
                                        key={index}
                                        file={el}
                                    />)
                            })  
                        }
                    </div>
                    </>)
                }
                <div className="file-upload-text text-center">
                    <strong>{lingo.ext_drag_drop_maximum} {maxNumberOfFilesLimit} {lingo.ext_files_here_or} <a href="javascript();" onClick={(e) => {
                        e.preventDefault();
                        fileSelector.click();
                    }}>{lingo.ext_click_to_upload}</a></strong>
                    <p>{formatExtensions(extensions)}</p>
                </div>
            </div>
            
            {!validFileType && <p className="text-error text-center mt-2">{lingo.ext_upload_error}</p>}
            </div>
        )
    }
}
