import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Button from "../components/Button";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { AuthContext } from "../providers/AuthProvider";

export default function Application(){
    const { lingo, getDraftFromDataBase, setApplicationId, setApplicationData, application, formData, next, animate, applicationType, applicationId, draftId, deleteDraftFromDataBase } = useContext(ApplicationContext)
    const { apiCall } = useContext(AuthContext);
    const navigate = useNavigate()
    let params = useParams();
    const nodeRef = useRef(null);
    

    useEffect(() => {

        if(application === null){
            setApplicationData(params.formType)
        }
    })

    const deleteDraftDB = async () => {

        const res = await deleteDraftFromDataBase(applicationType, draftId);
        if(res && res.data && res.data.status === 1){
            
        }
        if(res && res.data && res.data.status !== 1){
            
        }
        
        
    }

    const handleContinueApplication = () => {
        
        getDraftFromDataBase().then((data) => {
           if(params.section){
                navigate();
           }
           else if(data){
                let section = data.at(-1);
                if(section.forms){
                    let form = section.forms.at(-1);
                    navigate(section.url + '/' + form.url);
                }
            }else{
                next()
            }
        });
    }

    const handleNewApplication = () => {
        deleteDraftDB();
        if(!params.section) next();
    }

    if(application === null){
        return (
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
            </div>
        )
    }

    // 1. sjekk om brukeren har noen kladder
    // 2. hvis ja, så returneres fortsett soknad
    // 2.1 Hvis fortsett så fortsetter vi
    // 2.2 Hvis ny så slettes gammel kladd i database og applicationID
    // 3. hvis nei, fortsett til return under


    if(draftId && applicationId === null){
        return (
            <div>
            
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <CSSTransition nodeRef={nodeRef} in={animate} timeout={500} classNames="fade-up" unmountOnExit>
                    <div ref={nodeRef} className='text-center container narrow'>
                        <h1 className='mb-5'>{lingo.welcome_back}</h1>
                        <img src={process.env.PUBLIC_URL + '/svg/intro.svg'} className='mb-4' alt="" />
                            <p className='bold'>{lingo.welcome_back_text}</p>
                        <Button
                            title={lingo.close_and_start_over}
                            type="secondary"
                            onClick={() => handleNewApplication()}
                        />
                        <span className="spacer">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button
                            title={lingo.continue_application}
                            type="primary"
                            rightIcon="arrow-right"
                            onClick={() => handleContinueApplication()}
                        />
                    </div>
                </CSSTransition>
            </div>
        </div>
        )
    }

    if(params.section){
        return(
            <Outlet />
        );
    }

    return (
        <div>
            
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <CSSTransition nodeRef={nodeRef} in={animate} timeout={500} classNames="fade-up" unmountOnExit>
                    <div ref={nodeRef} className='text-center container narrow'>
                        <h1 className='mb-5'>{application.title}</h1>
                        <img src={process.env.PUBLIC_URL + '/svg/intro.svg'} className='mb-4' alt="" />
                        <p className='bold'>{application.introtext}</p>
                        <Button
                            title={lingo.start_application_process}
                            type="primary"
                            rightIcon="arrow-right"
                            onClick={() => next()}
                        />
                    </div>
                </CSSTransition>
            </div>
        </div>
    )
}