import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { useContext } from "react"
import {useDropzone} from 'react-dropzone';
import { FinalReportContext } from "../providers/FinalReportProvider";
import { ConfigContext } from "../providers/ConfigProvider";

export default function FileUploadSingle(props){
    const { lingo } = useContext(ConfigContext);
    const { application } = useContext(FinalReportContext)

    const { onHandleDrop, files, onRemoveFile, existing_files, readonly, final_report, extensions, extensionsFormat, validateNewFiles, name } = props
    
    
    const [validFileType, setValidFileType] = useState(true);

    const generateMimeTypes = (fileExtensions) => {
        
        const mimeTypesMap = {
            'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'doc': 'application/msword',
            'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'xlsm': 'application/vnd.ms-excel',
            'csv': 'text/csv',
            'xls': 'application/vnd.ms-excel',
            'ppt': 'application/vnd.ms-powerpoint',
            'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'pdf': 'application/pdf',
            'jpg': 'image/jpeg',
            'jpeg': 'image/jpeg',
            'png': 'image/png',
        };

        const acceptedMimeTypes = fileExtensions
            .filter(extension => mimeTypesMap.hasOwnProperty(extension)) // Fjerner ugyldige filendelser
            .map(extension => mimeTypesMap[extension])
            .join(',');

        return acceptedMimeTypes;
    };

    const acceptedMimeTypes = generateMimeTypes(extensionsFormat);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        //accept: renderAcceptList(extensionsFormat),
        accept: acceptedMimeTypes,
        onDrop: acceptedFiles => {
            onHandleDrop(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          })));
        }
    });

    const formatExtensions = (extensions) => {
        if(extensions){
            let output = '';
            let typeArr = extensions.split('/');
            let lastItem = typeArr.pop();

            if(typeArr.length){
                output = typeArr.join(", ");
            }
            output += ` ${lingo.ext_and} ${lastItem} ${lingo.ext_is_legal}`;
            return output;
        }else{
            return lingo.ext_all_is_legal;
        }
    }
    const FileItem = ({ file }) => {

        let icon = 'file-text';
        switch(file.type){
            case 'application/pdf':
                icon = 'file-pdf';
                break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                icon = 'file-word'
                break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                icon = 'file-excel';
                break;
            default:
                icon = 'file-text'
            
        }
        return (
            <div className="file-item">
                <div className="px-2 text-center" title={file.name}>
                    <FontAwesomeIcon icon={['fas', icon]} size="3x" />
                    <h6>{file.name}</h6>
                    <h6 className="remove-file text-danger" onClick={() => {
                        setValidFileType(true);
                        onRemoveFile(file);
                    }}>
                    <FontAwesomeIcon className="px-1" icon={['far', 'times']} size="lg"></FontAwesomeIcon> {lingo.ext_remove_file}</h6>
                </div>
            </div>
        )
    }
    const ExistingFilesItem = ({file}) => {
        if(!file) return <div></div>
        const filetype = file?.name?.split('.').pop();
        let icon = 'file-text';
        switch(filetype){
            case 'pdf':
                icon = 'file-pdf';
                break;
            case 'docx':
            case 'doc':
                icon = 'file-word'
                break;
            case 'xmlx':
            case 'xml':
                icon = 'file-excel';
                break;
            default:
                icon = 'file-text'
        }

        

        return(
            <div className="file-item">
                <div className="px-2 text-center" title={file.name}>
                    <FontAwesomeIcon icon={['fal', icon]} size="3x" className="mb-1 opacity-light" />
                    <h6>{file.name}</h6>
                    <h6 className="remove-file text-danger" onClick={() => onRemoveFile(file)}><FontAwesomeIcon className="px-1" icon={['far', 'times']} size="lg"></FontAwesomeIcon> {lingo.ext_remove_file}</h6>
                </div>
            </div>
        );
    }
    const ReadonlyFilesItem = ({file}) => {
        const { baseURL } = useContext(ConfigContext)
        


        const output = file.map(fileObj => {
            return (<a key={fileObj.key} target="_blank" rel="noreferrer" href={baseURL + fileObj.url}>{lingo.ext_see_file_from_application}</a>)
        })

        return output;

    }
    if(readonly && typeof readonly !== "undefined"){

        if(application && application?.attachments){
            const item = application?.attachments.filter(a => a.label === name);
            if(item) return <ReadonlyFilesItem file={item} />
        }
        return (
            <div>
                <p><small><i>{lingo.ext_no_file_on_this_point}</i></small></p>
            </div>
        )
    }else{
        return (
            <div>
            <div {...getRootProps()} className={`dropzone file-upload-container ${isDragActive ? 'highlight' : ''}`}>
                <input {...getInputProps()} />
                {
                (files && (files.length === 0))?
                    <div className="file-wrapper">
                        <div className="file-upload-icon">
                            <div className="file-upload-bubble"></div>
                            <FontAwesomeIcon icon={['fal', 'file-text']} size="4x" />
                        </div>
                    </div>
                : 
                    (<>
                    <div className="file-wrapper">
                        {
                            existing_files && !Array.isArray(existing_files) && existing_files.length !== 0 ? <ExistingFilesItem file={existing_files} /> : <ExistingFilesItem file={existing_files[0]} />
                        }
                        {
                            files && files.map((el, index) => {
                                return (
                                    <FileItem 
                                        key={index}
                                        file={el}
                                    />)
                            })  
                        }
                    </div>
                    </>)
                }
                <div className="file-upload-text text-center">
                    <strong>{lingo.ext_drop_or_upload_here}</strong>
                    <p>{formatExtensions(extensions)}</p>
                </div>
            </div>
            
            {!validFileType && <p className="text-error text-center mt-2">{lingo.ext_upload_error}</p>}
            </div>
        )
    }
}
