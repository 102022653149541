import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Button from "../components/Button";
import CalculateFields from "../components/CalculateFields";
import DropDown from "../components/DropDown";
import DropDownCheck from "../components/DropDownCheck";
import FileUpload from "../components/FileUpload";
import FileUploadSingle from "../components/FileUploadSingle";
import Input from "../components/Input";
import InputMask from "../components/InputMask";
import Number from "../components/Number";
import RadioGroup from "../components/RadioGroup";
import RadioGroupDepending from "../components/RadioGroupDepending";
import Repeater from "../components/Repeater";
import ShowGifts from "../components/ShowGifts";
import Textarea from "../components/Textarea";
import ToggleSwitch from "../components/ToggleSwitch";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { Tips } from "../screens/Section";
import FileUploadMaxTwo from "../components/FileUploadMaxTwo";


export default function Form(){
    const { application, section, form, setApplicationData, applicationType, configJsonArray, formData, getDraftFromDataBase, applicationId } = useContext(ApplicationContext)
    let params = useParams();
    


    useEffect(() => {
        if(application === null){
            setApplicationData(params.formType)
        }
    })

    if(application === null){
        return (
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
            </div>
        )
    }

    if(section === null){
        return (
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
            </div>
        )
    }

    if(form === null){
        return (
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
            </div>
        )
    }

    if(section.type === 'pre'){
        return (<PreQuestion />)
    }

    if(section.type === 'form'){
        return (
            <>
            <FormGroup />
            </>
        )
    }

    
    return(
        <div>
            <h3>Form</h3>
        </div>
    )
}

function PreQuestion(){

    const { formData, updateForm, next, form } = useContext(ApplicationContext)
    
    const [valid, setValid] = useState(false)
    const [selectedValue, setSelectedValue] = useState(null)

    const { title, elements } = form 
    const { alternatives, name, showgifts } = elements[0]

    useEffect(() => {
        setValid(false)
        setSelectedValue(null)
    }, [form])

    useEffect(() => {
        if(formData[name]){
            setValid(true)
            const result = alternatives.filter(obj => obj.value === formData[name].value)[0]
            setSelectedValue(result)
        }else{
            setValid(false)
            setSelectedValue(null)
        }
    }, [formData])

    return (
        <>
            <div>
            <h2 className="mb-5">{title}</h2>
                <RadioGroup
                    items={alternatives}
                    onCheck={(value) => updateForm(name, value, true)}
                    checked={formData[name] ? formData[name].value : null}
                    classes="mb-4"
                />
                {
                    valid && selectedValue.description ? (
                    <div className={`bg-${selectedValue.description.type}-light px-4 pt-4 pb-2 text-start mb-4 rounded`} dangerouslySetInnerHTML={{__html: selectedValue.description.content}}></div>
                    ) : null
                }
                {
                    valid && showgifts && 
                        <>
                            <ShowGifts
                                selectedValue={selectedValue.value}
                            />
                        </>
                }
                {
                    valid && <Button onClick={() => next()} title="Neste" rightIcon="arrow-right" type="primary" />
                }
                
            </div>
        </>
    )
}

function FormGroup(){
    const { form, section, animate } = useContext(ApplicationContext) 

    const formIndex = section.forms.findIndex(s => s.url === form.url)
    const { display } = form;
    const nodeRef = useRef(null)

    return (
        <CSSTransition nodeRef={nodeRef} in={animate} timeout={500} classNames="fade">
            <div ref={nodeRef} className="form-content">
                <div>
                    <div className="form-title my-4">
                        <p className="m-0 hint-text smaller">{section.title} - {formIndex + 1} / {section.forms.length}</p>
                        <h2>{form.title}</h2>
                        {form.introtext ? <div dangerouslySetInnerHTML={{__html: form.introtext}}></div> : ''}
                        <div className="row mt-5">
                        {
                            form.elements.map((item, key) => {
                                
                                return (
                                    <FormElement 
                                        key={key}
                                        item={item}
                                    />
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
            
        </CSSTransition>
    )
}

const getBrregInfo = async (value) => {
    
    const response = await fetch('https://data.brreg.no/enhetsregisteret/api/enheter/' + value);

    if(response.status !== 200){
        throw {
            valid: false,
            error: true,
            message: 'Fant ikke organisasjon i brønnøysundregisteret'
        };
    }

    const data = await response.json();
    return data;
};

const BrregButtons = ({info}) => {
    const [value, setValue] = useState('own');
    const { formData, updateForm, next, form, bulkUpdateForm, lingo } = useContext(ApplicationContext);
    
    useEffect(() => {
        if(value === 'get_brreg'){
            bulkUpdateForm(info)
            updateForm('last-update', new Date(), true);
        }       
    }, [value])

    return (
        <div className="form-group mb-3">
            <RadioGroup
                items={[
                    {
                        "label": lingo.brreg_fill_yourself,
                        "value": "own"
                    },
                    {
                        "label": lingo.brreg_get_it_api_style,
                        "value": "get_brreg"
                    }
                ]}
                checked={value}
                onCheck={(e) => {
                        setValue(e)
                    }
                }
				classes="justify-content-start align-items-center"
                separator={lingo.brreg_separator}
             />
        </div>
    );
}

export function FormElement({ item, altOnChange }){

    const { maxNumberOfFiles, label, mask, name, placeholder, required, type, depends, display, extensions, extensionsFormat } = item
    const {configJsonArray, formData, updateForm, bulkUpdateForm, lingo } = useContext(ApplicationContext) 
    const [value, setValue] = useState(formData[name] ? formData[name] : '')
    const [showButton, setShowButton] = useState(false);
    const [helperValue, setHelperValue] = useState({});

    //const [files, setFiles] = useState([]);

    const removeFile = (file) => {
        
        let existingFilesArr = formData[name].existing_files;
        let newFiles = formData[name].value;


        if(file instanceof File){
            newFiles = formData[name].value.filter((f) => {
                return f !== file;
            });
        }else{
            existingFilesArr = formData[name].existing_files.filter((f) => {
                return f !== file;
            })
        }

        

        if(existingFilesArr && existingFilesArr.length !== 0){
            let innertemp = {};
            let obj = {};

            innertemp = {
                existing_files: existingFilesArr,
                value: newFiles,
                file: true,
                valid: true
            };
            obj[name] = innertemp;

            bulkUpdateForm(obj);
            updateForm('last-update', new Date(), true);

        }else{
            updateForm(name, newFiles, false);
        }

        
    }
    const recursiveSearchInConfig = (obj, property, term) => {
        var result = null;
        if(obj instanceof Array) {
            for(var i = 0; i < obj.length; i++) {
                result = recursiveSearchInConfig(obj[i], property, term);
                if (result) {
                    break;
                }   
            }
        }
        else
        {
            for(var prop in obj) {
                if(prop == property) {
                    if(obj[prop] == term) {
                        return obj;
                    }
                }
                if(obj[prop] instanceof Object || obj[prop] instanceof Array) {
                    result = recursiveSearchInConfig(obj[prop], property, term);
                    if (result) {
                        break;
                    }
                } 
            }
        }
        return result;
    }

    const removeFileSingle = (file) => {
        
        let existingFilesArr = formData[name].existing_files;
        let newFiles = formData[name].value;


        if(file instanceof File){
            newFiles = formData[name].value.filter((f) => {
                return f !== file;
            });
        }else{
            newFiles = []            
        }

        

        if(existingFilesArr && existingFilesArr.length !== 0){
            let innertemp = {};
            let obj = {};

            innertemp = {
                existing_files: existingFilesArr,
                value: newFiles,
                file: true,
                valid: true
            };
            obj[name] = innertemp;

            bulkUpdateForm(obj);
            updateForm('last-update', new Date(), true);

        }else{

            const fileConfig = recursiveSearchInConfig(configJsonArray, "name", name);
            let isValid;

            if(fileConfig && fileConfig.hasOwnProperty("required")){
                isValid = fileConfig.required
                isValid = false;

            }else{
                isValid = true;
            }
            updateForm(name, newFiles, isValid);
        }

        
    }
    const handleFileDrop = (fileList) => {
        
        let newFilesArr = [];
        let existingFilesArr = [];
        if( formData[name] && formData[name].existing_files !== undefined){
            existingFilesArr = formData[name].existing_files;
        }

        if ( formData[name] && formData[name].value !== undefined ){
            newFilesArr = formData[name].value;
        }

        for ( let i = 0; i < fileList.length; i++ ) {
            if(!newFilesArr.some(file => file.name === fileList[i].name)){
                newFilesArr.push(fileList[i]);
            }
        }
        
        if(existingFilesArr.length !== 0){
            let innertemp = {};
            let obj = {};

            innertemp = {
               existing_files: existingFilesArr,
               value: newFilesArr,
               file: true,
               valid: true
            };
            obj[name] = innertemp;

            bulkUpdateForm(obj);
            updateForm('last-update', new Date(), true);
           
        }else{
            updateForm(name, newFilesArr, true, true);
        }

        
        
    }

    /**
     * Skal håndtere validering av filopplastning, både fra drop og klikk. Eventuelle regler og spesialhåndtering gjøres her.
     */
    const validateNewFiles = (fileToValidate) => {
        
        if(extensionsFormat && fileToValidate.length){
            //Finner filtypen som skal lastes opp
            let fileFormat = fileToValidate[0].name.split(".");
            fileFormat = fileFormat.pop();
            return extensionsFormat.includes("." + fileFormat);
        }else{
            return true;
        }
    }

    /**
     * Håndterer drop-event for opplastning av filer for typen single. 
     */
    const handleFileDropSingle = (fileList) => {
        
        let newFilesArr = [];
        let existingFilesArr = [];
        if( formData[name] && formData[name].existing_files !== undefined){
            existingFilesArr = formData[name].existing_files;
        }

        if ( formData[name] && formData[name].value !== undefined ){
            newFilesArr = formData[name].value;
        }

        // for ( let i = 0; i < fileList.length; i++ ) {
        //     if(!newFilesArr.some(file => file.name === fileList[i].name)){
        //         newFilesArr.push(fileList[i]);
        //     }
        // }

        // for ( let i = 0; i < 1; i++ ) {
        //     if(!newFilesArr.some(file => file.name === fileList[i].name)){
        //         newFilesArr[0] = fileList[0];
        //     }
        // }

        newFilesArr[0] = fileList[0];
        existingFilesArr = [];

        if(existingFilesArr.length !== 0){
            let innertemp = {};
            let obj = {};
            /*
                file_project_budget: file: true, valid: true, value: [File, File], existing_files: []
            */
           // Her må jeg bruke bulkUpdateForm for å få til det jeg ønsker. (tror jeg)
           innertemp = {
               existing_files: existingFilesArr,
               value: newFilesArr,
               file: true,
               valid: true
            };
            obj[name] = innertemp;

            bulkUpdateForm(obj);
            updateForm('last-update', new Date(), true);
           
        }else{
            updateForm(name, newFilesArr, true, true);
        }

        
        
    }

    const handleFileDropMaxTwo = (fileList, maxNumberOfFilesLimit) => {
        const maxNumberOfFiles = maxNumberOfFilesLimit;
        let spaceForMoreFiles = maxNumberOfFiles;
        let numberOfFiles = 0;
        
        let newFilesArr = [];
        let existingFilesArr = [];
        
        if ( formData[name] && formData[name].existing_files !== undefined){
            existingFilesArr = formData[name].existing_files;
        }

        numberOfFiles += existingFilesArr.length;

        if ( formData[name] && formData[name].value !== undefined ){
            newFilesArr = formData[name].value;
        }

        numberOfFiles += newFilesArr.length;
        spaceForMoreFiles -= numberOfFiles;

        if(spaceForMoreFiles >= fileList.length){

            for ( let i = 0; i < fileList.length; i++ ) {
                if(!newFilesArr.some(file => file.name === fileList[i].name)){
                    newFilesArr.push(fileList[i]);
                }
            }

            if(existingFilesArr.length !== 0){
                let innertemp = {};
                let obj = {};
    
                innertemp = {
                   existing_files: existingFilesArr,
                   value: newFilesArr,
                   file: true,
                   valid: true
                };
                obj[name] = innertemp;
    
                bulkUpdateForm(obj);
                updateForm('last-update', new Date(), true);
               
            }else{
                updateForm(name, newFilesArr, true, true);
            }

        }else{
            
        }
    }

    useEffect(() => {

    }, [value])

    switch(type){
        case 'brreg_required':
            return (
                <>
                <div className="form-group mb-3">
                    <label>{label}</label>
                    <InputMask
                        value={formData[name] ? formData[name].value : ''}
                        onChange={(value, valid) => updateForm(name, value, valid)}
                        validation={async (value) => {
                            let isnum = /^\d+$/.test(value);
                            if(value.length !== 9 || !isnum){
                                let info = {
                                    name: {value: '', valid: false},
                                    municipality: {value: '', valid: false}
                                }
                                bulkUpdateForm(info);
                                updateForm('last-update', new Date(), true);
                                setShowButton(false);
                                return {
                                    valid: false,
                                    error: true,
                                    message: lingo.brreg_message
                                }
                            }else{
                                const brregInfo = await getBrregInfo(value)
                                    .then(data => {
                                        //setShowButton(true);
                                        let info = {
                                            orgnr: {value: data.organisasjonsnummer, valid: true},
                                            name: {value: data.navn, valid: true},
                                            //municipality: {valid: true, value: (data.forretningsadresse.kommune ? data.forretningsadresse.kommune : lingo.brreg_not_registered)}
                                            //municipality: {valid: true, value: (data.postadresse.kommune ? data.postadresse.kommune : lingo.brreg_not_registered)}
                                            municipality: { valid: true, value: (data.postadresse ? data.postadresse?.kommune : (data.forretningsadresse ? data.forretningsadresse.kommune : lingo.brreg_not_registered))}
                                        }
                                        setHelperValue(info);

                                        // Sjekke om data.forretningsadresse.poststed er lovlig verdi i dropdown
                                        //updateForm('orgnr', data.organisasjonsnummer, true);
                                        bulkUpdateForm(info)
                                        return {
                                            valid: true,
                                            error: false,
                                        }
                                    })
                                    .catch(err => {
                                        return err;
                                    });
                                return brregInfo;
                            }
                        }}
                        {...item}
                    />
                </div>
                { showButton ? <BrregButtons info={helperValue} /> : null }

                
                </>
            )
        case 'brreg':
            return (
                <>
                <div className="form-group mb-3">
                    <label>{label}</label>
                    <Input
                        value={formData[name] ? formData[name].value : ''}
                        onChange={(value, valid) => updateForm(name, value, valid)}
                        validation={async (value) => {
                            let isnum = /^\d+$/.test(value);
                            if(value.length !== 9 || !isnum){
                                let info = {
                                    name: {value: '', valid: false},
                                    municipality: {value: '', valid: false}
                                }
                                bulkUpdateForm(info);
                                updateForm('last-update', new Date(), true);
                                setShowButton(false);
                                return {
                                    valid: false,
                                    error: true,
                                    message: lingo.brreg_message
                                }
                            }else{
                                const brregInfo = await getBrregInfo(value)
                                    .then(data => {
                                        setShowButton(true);
                                        let info = {
                                            orgnr: {value: data.organisasjonsnummer, valid: true},
                                            name: {value: data.navn, valid: true},
                                            municipality: {valid: true, value: {
                                                label: data.forretningsadresse.poststed,
                                                value: data.forretningsadresse.poststed
                                                }
                                            }
                                        }
                                        setHelperValue(info);

                                        // Sjekke om data.forretningsadresse.poststed er lovlig verdi i dropdown
                                        updateForm('orgnr', data.organisasjonsnummer, true);
                                        return {
                                            valid: true,
                                            error: false,
                                        }
                                    })
                                    .catch(err => {
                                        return err;
                                    });
                                return brregInfo;
                            }
                        }}
                        {...item}
                    />
                </div>
                { showButton ? <BrregButtons info={helperValue} /> : null }

                
                </>
            )
        case 'text':
            return (
                <div className={display ? display : ''}>
                    <div className="form-group mb-3">
                        <label>{label}</label>
                        <Input 
                            value={formData[name] ? formData[name].value : ''}
                            onChange={(value, valid) => {
                                updateForm(name, value, valid)
                            }}
                            {...item}
                            onAltChange={altOnChange}
                        />
                    </div>
                </div>
            )
        case 'inputmask':
            return (
                <div className={display ? display : ''}>
                    <div className="form-group mb-3">
                        <label>{label}</label>
                        <InputMask 
                            value={formData[name] ? formData[name].value : ''}
                            onChange={(value, valid) => {
                                updateForm(name, value, valid)
                            }}
                            {...item}
                        />
                    </div>
                </div>
            )
        case 'numberformat':
            return (
                <div className={display ? display : ''}>
                    <div className="form-group mb-3">
                        <label>{label}</label>
                        <Number
                            value={formData[name] ? formData[name].value : ''}
                            onChange={(value, valid) => {
                                updateForm(name, value, valid)
                            }}
                            {...item}
                        />
                    </div>
                </div>
            )
        case 'calculate':
            return (
                <div className={display ? display: ''}>
                    <div className="form-group mb-3">
                        <label>{label}</label>
                        <CalculateFields
                            {...item}
                        />
                    </div>
                </div>
            )
        case 'repeater':
            return (
                <div className={display ? display + ' col-12': 'col-12'}>
                    <div className="form-group mb-3">
                        <div className="row">
                            
                            <label>{label}</label>
                            <Repeater
                                {...item}
                            />
                        </div>
                    </div>
                </div>
            )
        case 'dropdown':
            return (
                <div className={display ? display : ''}>
                    <div className="form-group mb-3">
                        <label>{label}</label>
                        <DropDown
                            value={formData[name] ? formData[name].value : ''}
                            onChange={(value, valid) => updateForm(name, value, valid)}
                            {...item}
                        />
                    </div>
                </div>
            )
        case 'dropdowncheck':
            return (
                <div className={display ? display : ''}>
                <div className="form-group mb-3">
                    <label>{label}</label>
                    <DropDownCheck
                        value={formData[name] ? formData[name].value : []}
                        onChange={(value, valid) => updateForm(name, value, valid)}
                        {...item}
                    />
                </div>
                </div>
            )
        case 'ToggleSwitch':
            return (
                <div className={display ? display : ''}>
                <ToggleSwitch
							label={label}
							value={formData[name] ? formData[name].value : []}
							onClick={(checked) => updateForm(name, checked, true)}
                            {...item}
						/>
                </div>
            );
        case 'radiogroup':
            return (
                <div className="form-group mb-3">
                    <label>{label}</label>
                    <RadioGroup 
							items={item.alternatives}
                            value={formData[name] ? formData[name].value: []}
							onCheck={(value) => updateForm(name, value, true)}
							checked={formData[name] ? formData[name].value : null}
                            {...item}
						/>
                </div>
            );
        case 'radiogroupdepending':
            return (
                <React.Fragment>
                    <div className="form-group mb-3">
                        <label className="mb-2">{label}</label>
                        <RadioGroupDepending 
                            items={item.alternatives}
                            value={formData[name] ? formData[name].value: []}
                            onCheck={(value) => updateForm(name, value, true)}
                            checked={formData[name] ? formData[name].value : []}
                            depends={depends}
                            {...item}
                        />
                    </div>
                </React.Fragment>
            );
        case 'textarea':
            return (
                <div className={display ? display : ''}>
                <div className="form-group mb-3">
                    <label>{label}</label>
                    <Textarea
                        value={formData[name] ? formData[name].value : ''}
                        onChange={(value, valid) => updateForm(name, value, valid)}
                        validation={(value) => {
                            if(value.length <= 10){
                                return {
                                    valid: false,
                                    error: true,
                                    message: 'Organisasjonsnummer må være 9 siffer'
                                }
                            }

                            return {
                                valid: true,
                                error: false
                            }
                        }}
                        
                        {...item}
                    />
                </div>
                </div>
            );
        case 'fileupload':
            return (
                <div className={display ? display: ''}>
                    <div className="form-group mb-3">
                        <FileUpload 
                            onHandleDrop={handleFileDrop}
                            onRemoveFile={removeFile}
                            validateNewFiles={validateNewFiles}
                            files={formData[name] ? formData[name].value : []}
                            extensions={extensions && extensions}
                            extensionsFormat={extensionsFormat && extensionsFormat}
                            existing_files={(formData[name] && formData[name].existing_files) ? formData[name].existing_files : []}
                            {...item}
                        />
                    </div>
                </div>
            );
            case 'fileuploadmaxtwo':
                return(
                    <div className={display ? display: ''}>
                        <div className="form-group mb-3">
                            <FileUploadMaxTwo
                                onHandleDrop={handleFileDropMaxTwo}
                                onRemoveFile={removeFile}
                                validateNewFiles={validateNewFiles}
                                files={formData[name] ? formData[name].value : []}
                                extensions={extensions && extensions}
                                extensionsFormat={extensionsFormat && extensionsFormat}
                                existing_files={(formData[name] && formData[name].existing_files) ? formData[name].existing_files : []}
                                {...item}
                            />
                        </div>
                    </div>
                );
        case 'fileuploadsingle':
            return (
                <div className={display ? display: ''}>
                    <div className="form-group mb-3">
                        <FileUploadSingle 
                            onHandleDrop={handleFileDropSingle}
                            onRemoveFile={removeFileSingle}
                            validateNewFiles={validateNewFiles}
                            files={formData[name] ? formData[name].value : []}
                            extensions={extensions && extensions}
                            extensionsFormat={extensionsFormat && extensionsFormat}
                            existing_files={(formData[name] && formData[name].existing_files) ? formData[name].existing_files : []}
                            {...item}
                        />
                    </div>
                </div>
            );
        default:
            return (<div></div>)
    }


}