import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../components/Button";
import Input from "../components/Input";
import { AuthContext } from "../providers/AuthProvider";
import { ConfigContext } from "../providers/ConfigProvider";
import Logo from "../components/Logo";

export default function Register(){

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [error, setError] = useState({})
    const [success, setSuccess] = useState(null);
    const { lingo } = useContext(ConfigContext)

    const { registerUser } = useContext(AuthContext)

    const doRegister = async () => {
        registerUser(email, password, repeatPassword).then((status) => {
            if(status.data.status !== 1){
                setError({status: status.data.status, msg: status.data.message})
            }
            if(status.data.status === 1){
                setSuccess({status: status.data.status, msg: status.data.message});
            }
        })
    }

    useEffect(()=>{
        const listener = (e) => {
            if(e.code === "Enter" || e.code === "NumpadEnter"){
                e.preventDefault();
                doRegister();
            }else{
                setError({})
            }
        }
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener)
        }
    })

    return (
        <div className="minheight-full position-relative">
            
            <div className="d-lg-flex d-none no-gutters h-100 w-100 position-absolute cover">
                <div className="col-6 bg-light-green opacity-heavy">
                </div>
            </div>
                
            <div className="px-lg-5 px-3 w-100 h-100 position-relative login-container">
                <div className="d-flex flex-wrap h-100 align-items-center">
                    <div className="col-lg-6 text-lg-center">
                        <div className="login-left-wrap pt-4 pt-lg-0">
                            <h3>{lingo.register_left_title}</h3>
                            <p>{lingo.register_left_tips}</p>
                            <p>{lingo.register_left_call_to_action}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 pb-5 ps-lg-5 col-12">
                        {success &&
                        <div className="login-wrap">
                            <h2 className="py-3">{lingo.register_right_success_title}</h2>
                            <p>{lingo.register_right_success_text}</p>
                            <p><strong><Link to="/"><FontAwesomeIcon icon={['far', 'chevron-left']} className="pe-2" />{lingo.register_right_success_link_text}</Link></strong></p>
                        </div>
                        }
                        {!success && 
                        <div className="login-wrap">
                            <p><strong><Link to="/"><FontAwesomeIcon icon={['far', 'chevron-left']} className="pe-2" />{lingo.register_right_backlink_text}</Link></strong></p>
                            <h2 className="py-3">{lingo.register_right_title}</h2>
                            <div className="form-group mb-3">
                                    <label>{lingo.register_right_email_label}</label>
                                <Input 
                                    type="email"
                                    placeholder={lingo.register_right_email_placeholder}
                                    value={email}
                                    rules={{
                                        email : {}
                                    }}
                                    onChange={(value) => setEmail(value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                    <label>{lingo.register_right_password_label}</label>

                                <Input 
                                    type="password"
                                        placeholder={lingo.register_right_password_placeholder}
                                    value={password}
                                    rules={[]}
                                    onChange={(value) => setPassword(value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                    <label>{lingo.register_right_password_repeat_label}</label>

                                <Input 
                                    type="password"
                                    placeholder={lingo.register_right_password_repeat_placeholder}
                                    value={repeatPassword}
                                    rules={[]}
                                    onChange={(value) => setRepeatPassword(value)}
                                />
                            </div>
                            
                            {error ? <p className="text-error">{error.msg}</p> : null}
                            
                            <Button 
                            title={lingo.register_right_password_button_text}
                            type="primary"
                            onClick={doRegister}
                            classes="mb-5"
                            />
                            <Logo className={"login-logo"} />
                        </div>
                        }

                    </div>
                </div>
             </div>
        </div>
    )
}