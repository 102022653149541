import React, { useContext, useEffect, useState } from "react";
import axios from 'axios'
import config from '../config.json'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import { faChevronDoubleLeft } from "@fortawesome/pro-thin-svg-icons";
import { ConfigContext } from "./ConfigProvider";
import useTypeBook from "../helpers/useTypeBook";
import { getFieldType } from "../helpers/Functions";

export const FinalReportContext = React.createContext({
    application: null,
    formData: null,
    reportApplication: null,
    section: null,
    form: null,
    configJsonArray: null,
    percent: null,
    completed: null,
    updateForm: () => {},
    removeProperty: () => {},
    bulkUpdateForm: () => {},
    next: () => {},
    prev: () => {},
    updatePercent: () => {},
    setAsCompleted: () => {}
})

export const FinalReportProvider = ({children}) => {

    const { data, lingo } = useContext(ConfigContext)
    const { apiCall } = useContext(AuthContext)

    const [application, setApplication] = useState(null)
    const [reportApplication, setReportApplication] = useState(null)
    const [formData, setFormData] = useState({})
    const [percent, setPercent] = useState(0)
    const [completed, setCompleted] = useState(false)

    const [section, setSection] = useState(null)
    const [form, setForm] = useState(null)

    const [configJsonArray, setConfigJsonArray] = useState([]);

    
    // HOOKS
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    const loadApplication = async () => {
        const { data } = await apiCall({
            action: 'getApplicationDetailsByHash',
            hash: params.hash
        })

        if(data.status === 1){
            setApplication(data.data)
        }
    }

    const setFormDataOnLoad = () => {
        const fields = getAllFieldNames()
        const { payload, final_report, amount_awarded, attachments } = application
        
        let temp = {}
        fields.map((item, key) => {

            //Vi har filer_fremdriftsplan, filer_prosjektbudsjett og filer_vedlegg
            validateField(item)
            const isFile = item && item.includes('filer_')
            
            const assignValue = () => { 
                if(final_report && final_report[item]){
                    return final_report[item];
                }else if(item === "amount_awarded" && amount_awarded){
                    return amount_awarded;
                }else if(isFile){
                    //Her kan vi rydde litt slik at det blir puttet i existing_files som det skal
                    if(final_report?.files && final_report.files[item]){
                        return final_report.files[item]
                    }
                    if(final_report?.files && Array.isArray(final_report.files)) return final_report.files.filter(e => e.label === item);
                    
                }else{ 
                    return payload[item];
                }
            }

            let value = assignValue();
            if(!value) return;
            
            if(item.match(/filer_\w+/g)){
                
                temp[item] = {
                    existing_files: value,
                    valid: true
                }
            }else{
                //Problemet er at alle felt settes til valid true om de finnes, og dette gjelder da "accept" som by default er "false"
                //Her må man gå igjennom alle felter og sjekke om de er valid basert på hva som står i config
                try{
                    JSON.parse(value)
                    temp[item] = {value: JSON.parse(value), valid:  true} 
                } catch (e) {
                    temp[item] = {value: value, valid: true}
                }
            }
        })

        setFormData(temp)
        
    }

    const validateField = (fieldname) => {
        const { payload, final_report, amount_awarded, attachments } = application

    }

    const populateConfigArray = () => {
        const sectionArr = reportApplication.sections.filter(section => 
            section.type === 'form' || section.type === 'user_info'
        );
        
        let elementArr = [];
        sectionArr.map((section) => {
            let innerElement = [];
            section.forms.map((el) => {
                el.elements.map((field) => {
                    if(field.type !== 'calculate'){
                        innerElement.push(field);
                    }
                    
                });
            })
            elementArr.push({
                title: section.title,
                url: section.url,
                valid: true,
                items: innerElement
            });
        });
        setConfigJsonArray(elementArr);
    }

    useEffect(() => {
        loadApplication()
    }, [])

    useEffect(() => {
        // fylle elementArr her
        if(reportApplication && reportApplication.length !== 0){
            populateConfigArray()
            setFormDataOnLoad()
        }
        

    }, [reportApplication])

    useEffect(() => {
        if(reportApplication){
                
                if(params.section){
                    
                    if(reportApplication.sections.filter(section => section.url === params.section).length){
                        setSection(reportApplication.sections.filter(section => section.url === params.section)[0])
                    }
                }else{
                    navigate(reportApplication.sections[0].url)
                }

                if(section){
                    if(section.url !== params.section){
                        const sec = reportApplication.sections.filter(section => section.url === params.section)[0]
                        navigate(`${sec.url}/${sec.forms[0].url}`)
                        return
                    }
                    if(params.form){
                        if(section.forms.filter(form => form.url === params.form).length){
                            setForm(section.forms.filter(form => form.url === params.form)[0])
                        }
                    }else{
                        navigate(`${section.url}/${section.forms[0].url}`)
                    }
                }
            
            
        }
    }, [location, reportApplication, section])

    useEffect(() => {
        
    }, [section, location])

    useEffect(() => {
        if(application && !reportApplication){
            const temp = data && data.applications.filter(a => a.type === application.type)[0]
            if(temp){
                setReportApplication(temp.finalReport)
            }
        }
    }, [application])

    useEffect(() => {
    }, [reportApplication])

    const render = () => {
        if(!data) return (
            <div className="">
                <h3>{lingo.final_loading_config}</h3>
            </div>
        )

        if(!application || !reportApplication){
            <div className="">
                <h3>{lingo.final_loading_application}</h3>
            </div>
        }

        return children
    }

    useEffect(() => {
        // setTimeout(() => {
            validateSections();
        // }, 2000)
        
    }, [location, formData]);

    const isFieldValid = (fieldname) => {

        if(formData[fieldname] === undefined){
            return false;
        }

        if(formData[fieldname] === false) return false

        return formData[fieldname].valid;
        
    }

    

    const validateSections = () => {

        if(configJsonArray.length !== 0){
            configJsonArray.map((section, sectionkey) => {
                configJsonArray[sectionkey].valid = true;
                section.items.map((item, itemkey) => {
                    if(!isFieldValid(item.name) && item.hasOwnProperty("required") && item.required){
                        configJsonArray[sectionkey].valid = false;
                    }
                })
            })
            
            setConfigJsonArray(configJsonArray);
        }
    }

    const getAllFieldNames = () => {
        let array = [];
        reportApplication.sections.map((section) => {
            section.forms.map((form) => {
                form.elements.map((element) => {
                    if(element.fields && element.fields.length){
                        element.fields.map((field) => {
                            array.push(field.name)
                        })

                    }
                    if(element.depends && element.depends.length){
                        element.depends.map((depend) => {
                            if(depend.innerElements && depend.innerElements.length){
                                depend.innerElements.map((el) => {
                                    array.push(el.name)
                                })
                            }
                        })
                    }
                    if(element.name) array.push(element.name)
                })
            })
        })

        array.push('files')
        return array
    }

    const saveDraft = async () => {
        let object = {}
        const fieldNames = getAllFieldNames()
        for ( var key in formData ) {
            if(!fieldNames.includes(key)) continue
            if(Array.isArray(formData[key].value)){
                if(formData[key].file){
                    object[key] = formData[key].value;
                }else{
                    object[key] = JSON.stringify(formData[key].value)
                }
            } 
            if( formData[key].existing_files ){
                object['existingfile_' + key] = JSON.stringify(formData[key].existing_files);
            }
            else{
                object[key] = formData[key].value;
            }

        }

        
        const { data } = await apiCall({
            action: 'saveFinalReportDraft',
            application: application.id,
            ...object
        })
        
    }

    const complete = async () => {
        let object = {}
        const fieldNames = getAllFieldNames()
        for ( var key in formData ) {
            if(!fieldNames.includes(key)) continue
            if(Array.isArray(formData[key].value)){
                if(formData[key].file){
                    object[key] = formData[key].value;
                }else{
                    object[key] = JSON.stringify(formData[key].value)
                }
            } 
            else if( formData[key].existing_files ){
                object['existingfile_' + key] = JSON.stringify(formData[key].existing_files);
            }
            else{
                object[key] = formData[key].value;
            }

        }
        const { data } = await apiCall({
            action: 'completeFinalReport',
            application: application.id,
            ...object
        })

        if(data.status === 1){
            setCompleted(true)
        }
        
    }

    return <FinalReportContext.Provider value={{
        application,
        formData,
        reportApplication,
        section,
        form,
        lingo,
        configJsonArray,
        percent,
        completed,
        updateForm: (name, value, valid, isFile) => {
            
            // if(doneProcessingDBData){
                const newData = Object.assign({}, formData)
                newData[name] = {
                    value: value,
                    valid: valid
                }
                if(isFile){
                    newData[name].file = true;
                }
                setFormData(newData)
                
            // }
        },
        removeProperty: (name, i) => {
            const newData = Object.assign({}, formData)

            if(newData[name] && newData[name].value[i]){
                newData[name].value.splice(i, 1);
                setFormData(newData);
            }else{
                alert("Kunne ikke slette rad. Kontakt systemadministrator")
            }
            
        },
        bulkUpdateForm: (newFormData) => {
            // if(doneProcessingDBData){
                let newObject = Object.assign(formData, newFormData);
                setFormData(newObject);
            // }
        },
        next: () => {
            //setAnimate(false);
            saveDraft()
            setTimeout(() => {

                if(!section){
                    if(reportApplication.sections[0].forms && reportApplication.sections[0].forms.length){
                        navigate(`/sluttrapport/${application.hash}/${reportApplication.sections[0].url}/${reportApplication.sections[0].forms[0].url}`);
                    }
                }

                if(form){
                    const formIndex = section.forms.findIndex(f => f.url === form.url)
                    
                    if(section.forms[formIndex + 1]){
                        navigate(`/sluttrapport/${application.hash}/${section.url}/${section.forms[formIndex + 1].url}`);
                    }else{
                        const sectionIndex = reportApplication.sections.findIndex(s => s.url === section.url)
                        
                        if(reportApplication.sections[sectionIndex + 1]){
                            navigate(`/sluttrapport/${application.hash}/${reportApplication.sections[sectionIndex + 1].url}`)
                        }
                    }
                }else{
                    if(section){
                        const sectionIndex = reportApplication.sections.findIndex(s => s.url === section.url)
                        if(reportApplication.sections[sectionIndex + 1]){
                            if(reportApplication.sections[sectionIndex + 1].forms && reportApplication.sections[sectionIndex + 1].forms.length){
                                navigate(`/sluttrapport/${application.hash}/${reportApplication.sections[sectionIndex + 1].url}/${reportApplication.sections[sectionIndex + 1].forms[0].url}`)
                            }else{
                                navigate(`/sluttrapport/${application.hash}/${reportApplication.sections[sectionIndex + 1].url}`)
                            }

                        }
                    }
                }
                //setAnimate(true)
            }, 500)
        },
        prev: () => {
            //setAnimate(false)
            setTimeout(() => {

                if(form){
                    const formIndex = section.forms.findIndex(f => f.url === form.url)
                    if(section.forms[formIndex - 1]){
                        navigate(`/sluttrapport/${application.hash}/${section.url}/${section.forms[formIndex - 1].url}`)
                    }else{
                        const sectionIndex = reportApplication.sections.findIndex(s => s.url === section.url)
                        
                        if(reportApplication.sections[sectionIndex - 1]){
                            const s = reportApplication.sections[sectionIndex - 1]
                            navigate(`/sluttrapport/${application.hash}/${s.url}/${s.forms[s.forms.length - 1].url}`)
                        }
                    }
                }else{
                    if(section){
                        const sectionIndex = reportApplication.sections.findIndex(s => s.url === section.url)
                        if(reportApplication.sections[sectionIndex - 1]){
                            if(reportApplication.sections[sectionIndex - 1].forms && reportApplication.sections[sectionIndex - 1].forms.length){
                                navigate(`/sluttrapport/${application.hash}/${reportApplication.sections[sectionIndex - 1].url}/${reportApplication.sections[sectionIndex + 1].forms[0].url}`)
                            }else{
                                navigate(`/sluttrapport/${application.hash}/${reportApplication.sections[sectionIndex - 1].url}`)
                            }
                            
                        }
                    }
                }
                //setAnimate(true)
            }, 0)
        },
        updatePercent: (percent) => {
            setPercent(percent)
        },
        setAsCompleted: () => {
            complete()
        }
    }}>{render()}</FinalReportContext.Provider>
}