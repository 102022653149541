import { useContext, useEffect, useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import { FinalReportContext } from "../providers/FinalReportProvider";
import Number from "./Number";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RepeaterFinalReport = (item) => {
    const {element, form, section, formData, updateForm, removeProperty, bulkUpdateForm, application } = useContext(FinalReportContext) 
    const {
        fields,
        addbutton,
        label,
        name:groupname,
        type
    } = item;
   
    const existing = 
        application.payload.hasOwnProperty[groupname] && typeof application.payload[groupname] !== 'undefined' 
            ? 
                JSON.parse(application.payload[groupname]) 
            : 
                null;
    
    const removeGroup = (group, k, groupname) => {
        //Vi sender inn gruppenavnet (overordnet for repeater og k, som sier hvor dette feltet ligger)
        removeProperty(groupname, k);
    }
    const handleChange = (name, value, valid, group, groupkey, key) => {
        let isValid = valid
        let temp = formData[groupname] ? formData[groupname].value : [];
        let obj = {};
        
        if(!temp[key]){
            temp.push({[name]: {value: value, valid: valid}});
            obj = {[groupname]: {value: temp, valid: valid}}
            bulkUpdateForm(obj);
        }else{
            temp[key][name] = {value: value, valid: valid};
        }
        if(formData[groupname] && formData[groupname].value){
            formData[groupname].value.map((item) => {
                Object.keys(item).map((i, k) => {
                    if(!item[i].valid) isValid = false
                })
            })
        }    
        
        updateForm(groupname, temp, isValid)
    }   

    return ( 
        <>
            {
                formData[groupname] && formData[groupname].value  ? 
                    (
                        formData[groupname].value.map((i, k) => {
                            return fields.map((group, groupkey) => {
                                return(
                                    <div className="d-flex flex-wrap align-items-center dual-rows">
                                        {k ?
                                            <div key={k} className="col-lg-1 col-2 mb-3">
                                                
                                                <Button
                                                    key={k}
                                                    type="deleterow"
                                                    classes=""
                                                    tooltip="Fjern rad"
                                                    title={<FontAwesomeIcon icon={['far', 'trash']} />}
                                                    onClick={() => removeGroup(group, k, groupname)}
                                                />
                                            </div>
                                        :
                                            null
                                        }
                                        { group.map((item, key) => {
                                            return (
                                                <FormRepeaterElement 
                                                    key={key}
                                                    value={i[item.name] ? i[item.name].value : null}
                                                    item={item}
                                                    group={group}
                                                    groupkey={groupkey}
                                                    groupname={groupname}
                                                    onChange={(name, value, valid) => {
                                                        handleChange(name, value, valid, group, groupkey, k)
                                                    }}
                                                />
                                                
                                            )    
                                        })}
                                    </div>
                                ) 
                            })
                        })
                    )
                :
                    (
                        <>
                            {
                                fields.map((group, groupkey) => {
                                    return group.map((item, key) => {
                                        
                                        return (
                                            
                                            <FormRepeaterElement 
                                                key={key}
                                                value={existing ? existing[groupkey] : ''}
                                                item={item}
                                                group={group}
                                                groupkey={groupkey}
                                                groupname={groupname}
                                                onChange={(name, value, valid) => {
                                                    handleChange(name, value, valid, group, groupkey, 0)
                                                }}
                                            />
                                            
                                        )    
                                    })
                                })
                            }
                        </>
                    )
            }
            <div className="col-6 offset-3">
            <button className="btn btn-secondary w-100" onClick={() => {
                let temp = formData[groupname].value;
                let newObject = {};
                fields.map((group) => {
                    group.map((item) => {
                        newObject[item.name] = {value: '', valid: false};
                    });
                });

                temp.push(newObject);
                updateForm(groupname, temp, false);

            }}>
                <span>{addbutton}</span>
            </button>
            </div>
        </>
    );
}
 
export default RepeaterFinalReport;



const FormRepeaterElement = ({ item, group, groupkey, groupname, onChange, value }) => {
    const { label, mask, name, placeholder, required, type, depends, display } = item;
    
    switch(type){
        case 'text':
            return (
                <div className={display ? display + ' col' : 'col'}>
                    <div className="form-group mb-3">
                        <Input 
                            value={value ? value : ''}
                            onChange={(value, valid) => {
                                onChange(name, value, valid, group, groupkey)
                            }}
                            {...item}
                        />
                    </div>
                </div>
            )
        case 'numberformat':
            return (
                <div className={display ? display : ''}>
                    <div className="form-group mb-3">
                        <Number
                            value={value ? value : ''}
                            onChange={(value, valid) => {
                                onChange(name, value, valid, group, groupkey)
                            }}
                            {...item}
                        />
                    </div>
                </div>
            )
    }

}