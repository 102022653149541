import React, { useContext, useEffect, useState } from "react";
import { Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../components/Button";
import Input from "../components/Input";
import { AuthContext } from "../providers/AuthProvider";
import { ConfigContext } from "../providers/ConfigProvider";
import Logo from "../components/Logo";

export default function ForgotPassword(){
    const { lingo } = useContext(ConfigContext);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [forgotPasswordCode, setForgotPasswordCode] = useState('');
    const [error, setError] = useState({})
    const [success, setSuccess] = useState(null);
    const [partialSuccess, setPartialSuccess] = useState(null);

    const [codeSent, setCodeSent] = useState(false);

    const { forgotPassword } = useContext(AuthContext);

    const doForgotPassword = async () => {
        setCodeSent(true);
        forgotPassword(email, forgotPasswordCode, password, repeatPassword).then((status) => {
            if(status.data.status === 2){
                setError({status: status.data.status, msg: status.data.message})
                setSuccess(null);
                setPartialSuccess(null);
            }
            if(status.data.status === 1){
                setError({});
                setPartialSuccess(null);
                setSuccess({status: status.data.status, msg: status.data.message});
            }
            if(status.data.status === 3){
                setError(null);
                setSuccess(null);
                setPartialSuccess({status: status.data.status, msg: status.data.message});
            }
        })
    }

    const doCodeSent = () => {
        setCodeSent(true);
    }

    useEffect(()=>{
        const listener = (e) => {
            if(e.code === "Enter" || e.code === "NumpadEnter"){
                e.preventDefault();
                forgotPassword();
            }else{
                setError({})
            }
        }
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener)
        }
    })

    return (
        <div className="minheight-full position-relative">
            
            <div className="d-lg-flex d-none no-gutters h-100 w-100 position-absolute cover">
                <div className="col-6 bg-light-green opacity-heavy">
                </div>
            </div>
                
            <div className="px-lg-5 px-3 w-100 h-100 position-relative login-container">
                <div className="d-flex flex-wrap h-100 align-items-center">
                    <div className="col-lg-6 text-lg-center">
                        <div className="login-left-wrap pt-4 pt-lg-0">
                            <h3>{lingo.forgot_password_left_title}</h3>
                            <p>{lingo.forgot_password_left_text}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 pb-5 ps-lg-5 col-12">
                        {success && 
                            <div className="success-wrap">
                                <h2 className="py-3">{lingo.forgot_password_new_password_title}</h2>
                                <p>{lingo.forgot_password_new_password_text}</p>
                                <p><Link to="/"><FontAwesomeIcon icon={['far', 'chevron-left']} className="pe-2" />{lingo.register_right_success_link_text}</Link></p>
                            </div>
                        }
                        {!success && 
                        <div className="login-wrap">
                            <p><Link to="/"><FontAwesomeIcon icon={['far', 'chevron-left']} className="pe-2" />{lingo.register_right_success_link_text}</Link></p>
                            <h2 className="py-3">{lingo.forgot_password_forgot_password}</h2>
                            <div className="form-group mb-3">
                                <label>{lingo.login_email_label}</label>
                                <Input 
                                    type="email"
                                    placeholder={lingo.login_email_placeholder}
                                    value={email}
                                    rules={{
                                        email:{}
                                    }}
                                    onChange={(value) => setEmail(value)}
                                />
                            </div>
                            <div className={!codeSent ? "d-none" : null}>
                                <div className="form-group mb-3">
                                    <label>{lingo.forgot_password_code_label}</label>

                                    <Input 
                                        type="text"
                                        placeholder={lingo.forgot_password_code_placeholder}
                                        value={forgotPasswordCode}
                                        rules={[]}
                                        onChange={(value) => setForgotPasswordCode(value)}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>{lingo.forgot_password_new_pw_label}</label>
                                    <Input 
                                        type="password"
                                        placeholder={lingo.forgot_password_new_pw_placeholder}
                                        value={password}
                                        rules={[]}
                                        onChange={(value) => setPassword(value)}
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label>{lingo.forgot_password_new_pw_repeat_label}</label>

                                    <Input 
                                        type="password"
                                        placeholder={lingo.forgot_password_new_pw_repeat_placeholder}
                                        value={repeatPassword}
                                        rules={[]}
                                        onChange={(value) => setRepeatPassword(value)}
                                    />
                                </div>
                            </div>

                            {error ? <p className="text-error">{error.msg}</p> : null}
                            {success ? <p className="text-success">{success.msg}</p> : null}
                            {partialSuccess ? <p className="text-dark">{partialSuccess.msg}</p> : null}

                            <div className="row mb-3">
                                <div className="col-auto">
                                    <Button 
                                        title={codeSent ? lingo.forgot_password_button_change_password : lingo.forgot_password_button_change_send_code}
                                        type="primary"
                                        onClick={doForgotPassword}
                                        classes=""
                                    />
                                </div>
                                <div className={`col align-self-center ${codeSent ? "d-none" : null}`}>
                                    <a href="#" onClick={doCodeSent}>{lingo.forgot_password_got_code}</a>
                                </div>
                            </div>
                            <Logo className={"login-logo"} />
                        </div>
                    }
                    </div>
                
                </div>
             </div>
        </div>
    )
}