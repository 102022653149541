import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import BubbleModal from "../components/BubbleModal";
import Button from "../components/Button";
import { ApplicationContext } from "../providers/ApplicationProvider";
import Summary from "./Summary";
import UserInfo from "./UserInfo";
import Lottie from "lottie-react";
import CheckAnim from "../lottieanim.json"
import { AuthContext } from "../providers/AuthProvider";
import Header from "../components/Header";
import Logo from "../components/Logo";

export default function Section(){
    
    const { lingo, application, setApplicationData, section, animate, form, next, draftId, applicationId, isProgressDone } = useContext(ApplicationContext)
    

    let params = useParams();
    const navigate = useNavigate()
    const nodeRef = useRef(null);

    useEffect(() => {
        if(application === null){
            setApplicationData(params.formType)
        }
    })

    const handleToYourPage = (url) => {
        
        navigate(url);
    }

    if(application === null){
        return (
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
            </div>
        )
    }

    if(!section){
        return (
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
            </div>
        )
    }
  

    if(section.type === 'pre'){
        return (
            <CSSTransition nodeRef={nodeRef} in={animate} timeout={500} classNames="fade">
                <div ref={nodeRef} style={{minHeight: '100vh'}} className="d-flex align-items-center justify-content-center h-100">
                    <div className="text-center container narrow py-5">
                        <h6 className="text-green">{section.title}</h6>
                        <Outlet />
                    </div>
                </div>
            </CSSTransition>
        )
    }

    if(section.type === 'modal'){
        
        return (
            <Modal 
                {...section}
            />
        )
    }

    if(section.type === 'summary'){

        if(form){
            return (
                <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                    <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
                </div>  
            );
        }
        return (
            <React.Fragment>
                
                <CSSTransition nodeRef={nodeRef} timeout={500} classNames="fade">
                    <div ref={nodeRef} className="form-container">
                        <div className="main-content">
                            <div>
                                <div className="section">
                                    <div className="row">
                                        <div className="col-lg-auto col-12">
                                            <Logo className="sidebar-logo" />
                                            <div className="close-sidebar">
                                                <Button 
                                                    title={lingo.go_to_your_page}
                                                    leftIcon="chevron-left"
                                                    classes="py-2 backbutton"
                                                    type="secondary"
                                                    onClick={() => handleToYourPage('/din-side')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg col-12 pb-4">
                                            <ProgressBar />
                                            <div className="form-content">
                                                <div>
                                                    <div className="form-title mb-4">
                                                        <h2>{section.title}</h2>
                                                        <p>{section.subtitle}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Summary consentText={section.consentText}/>
                                    <FooterSummary />
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </React.Fragment>
        );
    }

    if(section.type === 'user_info'){
        if(!form){
            return (
                <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                    <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
                </div>
            )
        }
        return (
            <React.Fragment>
                <CSSTransition nodeRef={nodeRef} timeout={500} classNames="fade">
                    <div ref={nodeRef} className="form-container">
                        <div className="sidebar">
                            <Logo className="sidebar-logo" />
                            <div className="close-sidebar">
                                <Button 
                                    title={lingo.go_to_your_page}
                                    leftIcon="chevron-left"
                                    classes="py-2 backbutton"
                                    type="secondary"
                                    onClick={() => handleToYourPage('/din-side')}
                                />
                            </div>
                            <div className="sidebar-navigation">
                                {
                                    application.sections.map((item, key) => {
                                        if(item.type === 'form' || item.type === 'user_info'){
                                            return (
                                                <SidebarSection
                                                    key={key} 
                                                    item={item}
                                                />
                                            )
                                        }
                                        
                                    })
                                }
                                {
                                    isProgressDone && <SidebarSection item={{
                                        icon: "line-columns",
                                        short_description: lingo.summary_short_description,
                                        title: lingo.summary,
                                        type: "summary",
                                        url: "oppsummering",
                                        valid: true
                                    }} />
                                }
                            </div>
                            
                        </div>
                        <div className="main-content">
                            <div>
                                <ProgressBar />
                                <UserInfo />
                                <Tips />
                                <Footer />
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </React.Fragment>
        )
    }
    if(section.type === 'form'){
        return (
            <React.Fragment>
                <CSSTransition nodeRef={nodeRef} timeout={500} classNames="fade">
                    <div ref={nodeRef} className="form-container">
                        <div className="sidebar">

                            <Logo className="sidebar-logo" />
                            <div className="close-sidebar">
                                
                                <Button 
                                    title={lingo.to_your_page}
                                    leftIcon="chevron-left"
                                    classes="py-2 backbutton"
                                    type="secondary"
                                    onClick={() => handleToYourPage('/din-side')}
                                />
                            </div>
                            <div className="sidebar-navigation">
                                {
                                    application.sections.map((item, key) => {
                                        if(item.type === 'form' || item.type === 'user_info'){
                                            return (
                                                <SidebarSection
                                                    key={key} 
                                                    item={item}
                                                />
                                            )
                                        }
                                    })                                
                                }
                                {
                                    isProgressDone && <SidebarSection item={{
                                        icon: "line-columns",
                                        short_description: lingo.summary_short_description,
                                        title: lingo.summary,
                                        type: "summary",
                                        url: "oppsummering",
                                        valid: true
                                    }} />
                                }
                            </div>
                            
                        </div>
                        <div className="main-content">
                            <div>
                                <ProgressBar />
                                <Outlet />
                                <Tips />
                                <Footer />
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </React.Fragment>
        )
    }

    return(
        <div>
            <h3>Section</h3>
        </div>
    )
}

function Modal({ modalType, title, content, background, button, icon, image, subtitle, video }){
    const { prev, next, lingo } = useContext(ApplicationContext)
    const [active, setActive] = useState(true)
    
    const calculateBoxSize = () => {
        const {innerWidth, innerHeight} = window;
        let styleObj = {
            outer: { maxWidth: '50vw', marginTop: '50%' },
            inner: { maxWidth: '60%', margin: '0 auto' } 
        }
        if(innerWidth < 1800){
            styleObj.outer.marginTop = '40%';
            styleObj.inner.maxWidth = '80%';
        }
        if(innerWidth < 1200){
            styleObj.inner.maxWidth = '100%';
        }
        if(innerWidth < innerHeight){
            //Change for portrait
            styleObj = {
                outer: { maxWidth: '100vw', marginTop: '50%' },
                inner: { maxWidth: '90%', margin: '0 auto' } 
            }
        }
        return styleObj;
    }

    if(modalType === 'image'){
        return (
            <BubbleModal 
                active={active} 
                setActive={setActive}
                image={image}
                background={background}
            >

                <div className="d-flex flex-column h-100 justify-content-end" style={calculateBoxSize().outer}>
                        <div className="p-lg-4 py-3 px-4 px-lg-5 bg-white rounded" style={calculateBoxSize().inner}>
                            <h3 className="font-weight-bold my-3">{title}</h3>
                            <p className="mb-4">{content}</p>
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <Button
                                        title={button.text}
                                        type={button.type}
                                        rightIcon="arrow-right"
                                        onClick={() => {
                                            setActive(false)
                                            setTimeout(() => {
                                                next()
                                            }, 300)
                                        }}
                                    />
                                    <div className="d-block mt-4 text-center">
                                        <Button
                                            title={lingo.previous_screen}
                                            type="linkbtn"
                                            leftIcon="arrow-left"
                                            onClick={() => {
                                                setActive(false)
                                                setTimeout(() => {
                                                    prev()
                                                }, 300)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                
                
            </BubbleModal>
        )
    }

    if(modalType === 'middleSection'){
        return (
            <BubbleModal 
                active={active} 
                setActive={setActive}
                image={image}
                background={background}
            >
                <div className="d-flex flex-column h-100 justify-content-between " style={{maxWidth: calculateBoxSize().outer.maxWidth}}>
                        <div className="text-center" style={calculateBoxSize().inner}>
                            <h5>{title}</h5>
                            <div className="lottiecheck">
                                <Lottie animationData={CheckAnim} loop={false} />
                            </div>
                            <div className="mb-3">
                                <h3 className="text-primary font-weight-bold">{subtitle}</h3>
                                <p dangerouslySetInnerHTML={{__html: content}}></p>
                            </div>
                            {
                                video ? 
                                    <video controls>
                                        <source src={video} /> 
                                    </video>
                                : null
                            }   
                            
                            
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-auto">
                                <Button
                                    title={button.text}
                                    type={button.type}
                                    rightIcon="arrow-right"
                                    onClick={() => {
                                        setActive(false)
                                        setTimeout(() => {
                                            next()
                                        }, 300)
                                    }}
                                />
                                <div className="d-block mt-4 text-center">
                                    <Button
                                        title="Forrige skjerm"
                                        type="linkbtn"
                                        leftIcon="arrow-left"
                                        onClick={() => {
                                            setActive(false)
                                            setTimeout(() => {
                                                prev()
                                            }, 300)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                
                
            </BubbleModal>
            )
    }

    return (
        <div>Ooops, noe gikk feil - section 401</div>
    )
}

function SidebarSection({ item }){

    const { lingo, section, animate, configJsonArray, application, formData, saveDraft } = useContext(ApplicationContext)
    const { title, icon, short_description, url, type } = item
    const navigate = useNavigate();

    const [navError, setNavError] = useState([]);
    const [validSection, setValidSection] = useState(false);

    const params = useParams();

    let thisSection = configJsonArray.filter(el => el.url === url)
    let thisIndex = configJsonArray.findIndex(el => el.url === section.url)

    useEffect(() => {
        try{
            let sectionFields;
            if(thisSection.length && typeof(thisSection) !== undefined){

                if(thisSection[0].items.length){

                    sectionFields = thisSection[0].items.map(n => n.name);

                    if(sectionFields.length){
                        let validCheck = [];
                        sectionFields.forEach((input) => {
                            if(formData.hasOwnProperty([input]) && formData[input] && formData[input].hasOwnProperty('valid')){
                                validCheck.push(formData[input].valid)
                            }
                        })
                        if(validCheck.includes(false)){
                            setValidSection(false)
                        }else{
                            setValidSection(true);
                        }
                    }else{
                        setValidSection(false)
                    }
                }
            }
            return () => sectionFields = [];
        }catch(e){
            
        }

    }, [formData])

    const handleClick = async() => {

        const sectionIndex = application.sections.findIndex(s => s.url === url)
        //Må finne index fra configJsonArray for å validere og sammenligne med thisIndex. Det er kun disse som er relevante for validering og sidemeny
        const clickedIndex = configJsonArray.findIndex(s => s.url === url);
        const diff = clickedIndex - thisIndex;

        let canNavigate = false;

        //Kan alltid navigere seg bakover
        if(diff > 0){
            let errorSections = [];
            for(let i = thisIndex; clickedIndex > i; i++){
                if(!configJsonArray[i].valid){
                    errorSections.push(configJsonArray[i].title);
                    continue;
                }
            }
            if(errorSections.length){
                setNavError(errorSections);
            }
            else{
                canNavigate = true;
            }
        }else{
            canNavigate = true;
        }

        if(canNavigate){

            await saveDraft();
            if(type === 'summary'){
                navigate(`/soknadskjema/${application.url}/${url}`)
            }else{
                navigate(`/soknadskjema/${application.url}/${application.sections[sectionIndex].url}/${application.sections[sectionIndex].forms[0].url}`);
            }

        }else{

            if(navError.length >= 2){
                alert(lingo.multiple_naverrors + ' ' + navError.join(', ') + '. ' + lingo.multiple_naverrors_2);
            }else{
                alert(lingo.naverror_singular + '');
            }
            
        }
    }
    return (
        <div className={"sidebar-navigation-item " + (params.section===url ? 'active ' : '') + (thisSection[0]?.valid && validSection ? 'valid ': '')}>
            <div className="clickarea" onClick={() => handleClick()}>
                <div className="sidebar-navigation-item-content">
                    <h6 className="pointer mt-3 mt-lg-0">{title}</h6>
                    <p className="d-none d-lg-block">{short_description}</p>
                </div>
                <div className="sidebar-navigation-item-icon pointer">
                {(thisSection[0]?.valid && validSection ? <FontAwesomeIcon icon={['fal', 'check']} />: <FontAwesomeIcon icon={['fal', icon]} />)}
                </div>
            </div>
        </div>
        
    )
}

function ProgressBar(){
    const { lingo, application, formData, setIsProgressDone} = useContext(ApplicationContext)
    const [totalProgress, setTotalProgress] = useState(0)
    const [currentProgress, setCurrentProgress] = useState(0)

    useEffect(() => {
        updateProgress()
    }, [formData])

    const updateProgress = () => {
        let total = 0;
        let current = 0;

        let countFields = [];
        let validFields = [];

        application.sections.forEach((section) => {
            
            if(section.forms && section.forms.length){
                section.forms.forEach((form) => {
                    if(form.elements){
                        form.elements.forEach((element) => {
                            const shouldCount = element.required;

                            if(shouldCount){
                                if(element.type !== 'calculate'){
                                    total += 1;
                                    countFields.push(element);
                                }
                                                            
                                if(formData[element.name] && formData[element.name].valid){
                                    current += 1
                                    validFields.push(formData[element.name]);
                                    
                                }
                            }
                        })
                    }
                })
            }
        });

        setCurrentProgress(current)
        setTotalProgress(total)

        if(current === total || current > total){
            setIsProgressDone(true);
        }else{
            setIsProgressDone(false);
        }
    }
    
    return (
        <div className="progress-container mb-4">
            <div className="row justify-content-between align-items-center pb-1">
                <div className="col-auto">
                    <h6 className="mb-0">{application.title}</h6>
                </div>
                
                <div className="col text-end">
                    <small>{Math.round((currentProgress / totalProgress) * 100)}% {lingo.done}</small>
                </div>
            </div>
            <div className="progress">
                <div className="progress-indicator" style={{width: (currentProgress / totalProgress) * 100 +'%'}}></div>
            </div>
        </div>
    )
}

function FooterSummary(){
    const { apiCall } = useContext(AuthContext);
    const {lingo, formData, applicationId, applicationType, isProgressDone, consentApproved} = useContext(ApplicationContext);
    const [isCompleted, setIsCompleted] = useState(false);
    const navigate = useNavigate();

    const completeApplication = async (applicationId) => {

        const object = {
            applicationId: applicationId
        }
        const response = await apiCall({
            action: 'completeApplication',
            ...object
        });
        const data = await response.data;
        
        return data;
        

    };

    const handleClick = async () => {
        completeApplication(applicationId)
            .then(data => {
                navigate('/soknadskjema/soknad-innsendt');
            });
    }
    return (
        <div className="summary-bottom">
            <div className="fixed-bottom">
                <div className="fix-container">
                    <div className="row justify-content-end text-end">
                        <div className="col-auto">
                            {isProgressDone && consentApproved && 
                                <Button 
                                    title={lingo.footer_summary_title}
                                    type="primary"
                                    onClick={() => handleClick()}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Footer(){
    const { lingo, next, section, form, application, prev, saveDraft, formData } = useContext(ApplicationContext)

    const [canGoBack, setCanGoBack] = useState(false);
    const [validScreen, setValidScreen] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
        if(section){
            const sectionIndex = application.sections.findIndex(s => s.url === section.url)
            if(application.sections[sectionIndex - 1]){
                setCanGoBack(true)
                return true;
            }

            if(form){
                const formIndex = section.forms.findIndex(f => f.url === form.url)
                if(section.forms[formIndex - 1]){
                    setCanGoBack(true)
                    return true;
                }
            }
                
            setCanGoBack(false)
        }

    }, [location]);

    useEffect(() => {
        updateValidScreen();
    }, [formData, next, prev]);

    /**
     * Sjekker om nåværende skjerm er valid. Hvis ikke skal det ikke være mulig å navigere seg videre.
     */
    const updateValidScreen = () => {

        let currFormFields = [];

        //Finner feltene på nåværende skjerm og pusher de inn i currFormFields
        if(form){
            if(form.elements.length){
                form.elements.forEach((field) => {
                    if(field.name && field?.rules?.required){
                        currFormFields.push(field.name);
                    }
                })
            }
        }
        if(currFormFields.length){
            let validCheck = [];

            currFormFields.forEach((input) => {
                if(formData[input] && formData[input].hasOwnProperty('valid')){
                    validCheck.push(formData[input].valid)
                }
            })

            if(validCheck.includes(false) && validCheck.length){
                setValidScreen(false)
            }else{
                if(currFormFields.length <= validCheck.length){
                    setValidScreen(true);
                }else{
                    setValidScreen(false);
                }
            }
        }else{
            setValidScreen(true)
        }
    }

    return (
        <div className="row justify-content-between bottombuttonwrapper">
            <div className="col-auto">
                { canGoBack ? 
                <Button 
                    title={lingo.previous}
                    type="secondary"
                    leftIcon="arrow-left"
                    onClick={() => prev()}
                />
                : null}
            </div>
            <div className="col-auto">
                {validScreen 
                    ? 
                    <Button title={lingo.next} type="primary" rightIcon="arrow-right" onClick={async () => { const status = await saveDraft(); next() }}/> 
                    : 
                    <Button title={lingo.next} type="disabled" rightIcon="arrow-right" onClick={async () => { alert(lingo.fill_inn_all_fields) }}/>
                }
            </div>
        </div>
    )
}

export function Tips(){
    const { application, setApplicationData, section, animate, form, next } = useContext(ApplicationContext)
    let params = useParams();
    const navigate = useNavigate();
    const nodeRef = useRef(null);

    if(form && form.tips){

        const {icon, title, value, link} = form.tips;

        return(
            <CSSTransition nodeRef={nodeRef} in={animate} timeout={500} classNames="fade">
                <div className="form-content" ref={nodeRef}>
                    <div className="row">
                        {icon ? 
                            <div className="col-auto">
                                <img src={process.env.PUBLIC_URL + '/svg/' + icon} className='mb-4' />    
                            </div>
                        : null}
                        <div className="col">
                            {title ? <h5 className="text-blue">{title}</h5> : null}
                            {value ? <div dangerouslySetInnerHTML={{__html: value}}></div> : null}
                            {link && link.title && link.url ? <p className="pt-3"><a href={link.url} target="_blank">{link.title}</a></p>: ''}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }

    return(<CSSTransition nodeRef={nodeRef} in={animate} timeout={500} classNames="fade"><div ref={nodeRef}></div></CSSTransition>);
}