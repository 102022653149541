import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Button(props){
    const { title, onClick, leftIcon, rightIcon, type, classes, disabled, loadingIndicator, tooltip } = props;

    return (
        <button title={tooltip ? tooltip : ""} className={"btn btn-" + type +  (classes ? ' ' + classes : '')} onClick={disabled ? null : onClick} disabled={disabled ? 'disabled' : ''}>
            { leftIcon ? <FontAwesomeIcon icon={['far', leftIcon]} size="lg" /> : null}
            {loadingIndicator ? <FontAwesomeIcon icon={['far', 'fa-circle-notch']} className="fa-spin" size="lg" /> : null}
            <span className={loadingIndicator ? 'hideonload' : ''}>{title}</span>
            { rightIcon ? <FontAwesomeIcon icon={['far', rightIcon]} size="lg" /> : null}
        </button>
    )
}