import React from 'react';
import ReactDOM from 'react-dom';
import './bootstrap.min.css'
import axios from 'axios'
import '@sb1/ffe-webfonts/sb1-fonts.css';
import './index.css';
import './style.less'
import { library } from '@fortawesome/fontawesome-svg-core'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { ConfigProvider } from './providers/ConfigProvider';

// axios.defaults = Object.assign(axios.defaults, {
//   withCredentials: true,
//   //baseURL: 'https://sbsdev.catchmedia.no/',
// });

library.add(fas, far, fal, fat, fad)
ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
