import { useContext } from "react"
import { Link, Outlet, useMatch, useParams, useResolvedPath } from "react-router-dom"
import { Context } from "./ApplicationDetails"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { attachmentLabel, fileIcon, formatFormType, formatNumber, formatOrgnr, getStatus } from "../helpers/Functions"
import CollapseSection from "../components/CollapseSection"
import { ConfigContext } from "../providers/ConfigProvider";

export default function ApplicationOverviewProjectgift(){
    const { application, finalReportEnabled, applicationConfig } = useContext(Context)
    // const { data,lingo } = useContext(ConfigContext)
    const { lingo } = useContext(ConfigContext);

    const { id, name, payload, status, amount_awarded, amount_invested, amount_requested, createdon, final_report, applicant, payout, hash } = application

    const statusObject = getStatus(status, lingo)

    const Field = ({ type, name, alternatives, depends, fields, options, key }) => {
        
        let value;
        if(payload[name]){
            value = payload[name];
            if(name === 'project_total'){
                value = amount_requested
            }
        }else{
            if(payload.contact[name]){
                value = payload.contact[name]
            }else if(name === 'project_name'){
                value = application['name']
            }
            else{
                return <div></div>
            }
            
        }

        switch(type){
            case 'numberformat':
                return (
                    <p><span className="bold">NOK</span> {formatNumber(value)}</p>
                )
            case 'radiogroupdepending':
                const val = alternatives.filter(a => a.value === value)[0]
                let dependingValue;
                if(depends && depends.length){
                    dependingValue = depends.map((depend, key) => {
                        if(depend.radiovalue == value){
                            return depend.innerElements.map((inner, key) => {
                                return <Field {...inner} key={key} />
                            })
                        }
                    })
                }
                if(val) return (
                    <>
                        <p key={key}>{val.label}</p>
                        {dependingValue}
                    </>
                )
                break

            case 'repeater':
                const repeaterValue = JSON.parse(value)
                
                
                return (
                    <div className=" border-bottom mb-2">
                        <div className="row">
                    
                        {
                            repeaterValue.map((repeater, k) => {
                                return fields.map((group, groupkey) => {
                                    return group.map((item, key) => {
                                        return (
                                            repeater[item.name] ? 
                                            <div className="col-6" key={key}>
                                                {
                                                    item.type === 'numberformat' ? 
                                                    <p className="m-1"><span className="bold">NOK</span> {formatNumber(repeater[item.name].value)}</p> :
                                                    <p className="m-1">{repeater[item.name].value}</p>
                                                }
                                                
                                            </div>
                                            : null
                                        )
                                    })
                                })
                            })
                        }
                        </div>
                    </div>
                )
            case 'fileupload':
               
                    {
                        return value.map((file, key) => {
                            return (
                                <p key={key}>{file.name}</p>
                            )
                        })
                    }
            case 'ToggleSwitch':
                return <p>{value ? <FontAwesomeIcon icon={['fal', 'check-circle']} className="text-success" /> : <FontAwesomeIcon icon={['fal', 'times-circle']} className="text-danger" /> }</p>
            case 'dropdown':
                return <p>{options.filter(o => o.value === value)[0].label}</p>
            case 'brreg_required':
                return <p>{formatOrgnr(value)}</p>
            case 'calculate':
                break; 
            case 'dropdowncheck':
                const array = JSON.parse(value)
                
                return (
                    <div className="row">
                        {
                            array.map((item, key) => {
                                return (
                                    <div className="col-auto applicationoverview" key={key}>
                                        <p className="ms-choice">{item.label}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
        }

        return <p>{value}</p>
    }

    if(!applicationConfig) return <div></div>

    const getRemaining = () => {
        if(payout.length === 0) return
        let payouts = 0
        payout.map((item) => {
            payouts += parseInt(item.amount)
        })

        return formatNumber(amount_awarded - payouts)
    }
    return (
        <div>
            <h2>{name}</h2>
            <div className="row py-4">
                <div className="col-lg-8 col-12">
                    <div>
                        {
                            applicationConfig.sections.map((section, key) => {
                                if(['pre', 'modal', 'summary'].includes(section.type)) return <div></div>
                                return (
                                    <CollapseSection
                                        key={key}
                                        {...section}
                                    >
                                        
                                        {
                                            section.forms && section.forms.length && section.forms.map((form, key) => {
                                                return (
                                                    <div className="row" key={key}>
                                                        {
                                                            form.elements.map((element, key) => {
                                                                return (
                                                                    <div key={key} className={element.display && element.display !== 'text-center' ? element.display : ''}>
                                                                        <div className="form-group mb-3">

                                                                        {element.type !== 'calculate' && <label>{element.label}</label>}
                                                                            <Field {...element} />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                                
                                            })
                                        }
                                        </CollapseSection>
                                        
                                    
                                )
                            })
                        }
                    </div>
                </div>
                <div className="col">
                    <div className="border border-thick rounded p-3 mb-4">
                        <p className="bold caps">{lingo.application_status}</p>
                        <div className={"row gx-2 align-items-center " + statusObject.className}>
                            <div className="col-auto">
                                <FontAwesomeIcon icon={['fal', statusObject.icon]} size="2x" />
                            </div>
                            <div className="col">
                                <h5 className="m-0">{statusObject.label}</h5>
                            </div>
                        </div>

                        {
                            finalReportEnabled && status === 1 ? 
                                <div className="mt-2">
                                    <Link to={`/sluttrapport/${hash}`} className="btn btn-primary">{lingo.submit_final_report}</Link>
                                </div>
                            : null
                            
                        }
                        {/*
                            status === 4 ? 
                                <div className="pt-3">
                                    <Link to="sluttrapport" className="mt-4">Se sluttrapport</Link>
                                </div> 
                            : null
                        */}
                    </div>
                    <div className="border border-thick rounded p-3 mb-2">
                        <div className="mb-3">
                            <p className="font-weight-bold caps m-0">{lingo.application_amount}</p>
                            <p><span className="bold">{lingo.currency_short}</span> {formatNumber(amount_requested)}</p>
                        </div>
                        {
                            status === 1 ?
                         
                                <div className="mb-3">
                                    <p className="font-weight-bold caps m-0">{lingo.granted_subsidy}</p>
                                    <p><span className="bold">{lingo.currency_short}</span> {formatNumber(amount_awarded)}</p>
                                </div>
                            : null 
                        }
                        
                        {
                            payout && payout.length ? 
                            (
                                <div className="mb-3">
                                    <p className="font-weight-bold caps m-0">{lingo.executed_payments}</p>
                                    <div className="mb-5">
                                    {
                                        payout.map((item, key) => {
                                            return (
                                                <div key={key} className="row justify-content-between">
                                                    <div className="col-auto">{moment.unix(item.timestamp).format('DD.MM.YYYY')}</div>
                                                    <div className="col-auto font-weight-bold">{lingo.currency_short} {formatNumber(item.amount)}</div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                    <div className="row text-danger font-weight-bold justify-content-between">
                                        <div className="col-auto">{lingo.remaining}</div>
                                        <div className="col-auto">{lingo.currency_short} {getRemaining()}</div>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
            
        </div>
    )
}