import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import { ConfigContext } from "../providers/ConfigProvider";

export default function CollapseSection({ children, title, icon }){
    const { lingo } = useContext(ConfigContext);
    const [collapsed, setCollapsed] = useState(true)
    
    return (
        <div className="mb-3 border-bottom">
            <div className="collapse-section">
                <div className="row align-items-center mb-3 pointer" onClick={() => setCollapsed(!collapsed)}>
                    <div className="col-auto">
                        <FontAwesomeIcon icon={['fal', icon]} size="lg" fixedWidth  />
                    </div>
                    <div className="col">
                        <h3>{title}</h3>
                    </div>
                    <div className="col-auto">
                        <span className="collapse-hover">{collapsed ? lingo.hide : lingo.show}</span>
                    </div>
                    <div className="col-auto">
                        <FontAwesomeIcon icon={['fal', 'chevron-down']} style={{transform: `rotate(${collapsed ? 180 : 0}deg)`, transition: 'transform 250ms ease'}} size="lg" fixedWidth />
                    </div>
                </div>
            </div>
            <CSSTransition
                in={collapsed}
                timeout={300}
                classNames="accordion-wrapper__content"
                unmountOnExit
            >
                <div>
                    {children}
                </div>
            </CSSTransition>
        </div>
    )
}