import Input from '../components/Input';
import Button from '../components/Button';
import RadioGroup from '../components/RadioGroup';
import Textarea from '../components/Textarea';
import FileUpload from '../components/FileUpload';
import DropDown from '../components/DropDown';
import DropDownCheck from '../components/DropDownCheck';
import { useState } from 'react';
import ToggleSwitch from '../components/ToggleSwitch';

export default function Components() {

	const [firstInput, setFirstInput] = useState('')
	const [secondInput, setSecondInput] = useState('')
	const [thirdInput, setThirdInput] = useState('47')
	const [fourthInput, setFourthInput] = useState('Catch Media AS')
	const [fifthInput, setFifthInput] = useState('100 000')

	const [firstSingleSelect, setFirstSingleSelect] = useState('');
	const [firstMultipleSelect, setFirstMultipleSelect] = useState([]);

	const [firstToggleSwitch, setFirstToggleSwitch] = useState(false);

	const [firstTextArea, setFirstTextArea] = useState('')
	const [secondTextArea, setSecondTextArea] = useState('Prosjektet går ut på...')
	const [thirdTextArea, setThirdTextArea] = useState('Hei, Vi i designvirksomhet driver for det meste med design. Som en del av dette tilbyr vi gratis designskolen for unge i alderen 8-16 år. Vi er de eneste på Jevnaker som tilbyr en slik fritidsaktivitet, og har som mål å skape mer kreativitet blant de unge. Formålet med denne søknaden er først og fremst støtte til nødvendig tegneutstyr.')

	const [files, setFiles] = useState([])

	const removeFile = (file) => {
		const newFiles = files.filter((t) => t !== file);
  		setFiles(newFiles);
	}

	const handleFileDrop = (fileList) => {
		for (var i = 0; i < fileList.length; i++) {
			if(!files.some(file => file.name === fileList[i].name)){
				setFiles([...files, fileList[i]])
			}
			
		}
		//setFiles([...files, data])
	}
	return (
		<div className="p-4 mr-5">
			<h1>Text input</h1>
			<section>
				<div className="row align-items-center">
					<div className="col-4">
						<strong>Input med label og char limit</strong>
					</div>
					<div className="col-6">
						<label>Adresse</label>
						<Input
							value={firstInput}
							onChange={(e) => setFirstInput(e.target.value)}
							placeholder={'Gatenummer og navn'}
							maxLength={100}
						/>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-4">
						<strong>:disabled</strong>
					</div>
					<div className="col-6">
						<label>Lorem</label>
						<Input
							disabled
							value={secondInput}
							onChange={(e) => setSecondInput(e.target.value)}
							placeholder={'Gatenummer og navn'}
							maxLength={100}
						/>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-4">
						<strong>Med prefix</strong>
					</div>
					<div className="col-6">
						<label>Adresse</label>
						<Input
							value={thirdInput}
							onChange={(e) => setThirdInput(e.target.value)}
							placeholder={'47'}
							prefix="+"
							maxLength={2}
						/>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-4">
						<strong>valid</strong>
					</div>
					<div className="col-6">
						<Input
							value={fourthInput}
							onChange={(e) => setFourthInput(e.target.value)}
							placeholder={''}
							maxLength={100}
							valid={true}
						/>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-4">
						<strong>error</strong>
					</div>
					<div className="col-6">
						<Input
							value={fifthInput}
							onChange={(e) => setFifthInput(e.target.value)}
							placeholder={''}
							maxLength={100}
							error={true}
							errorMessage="Søknadsbeløp er for høyt"
							prefix="KR."
						/>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-4">
						<strong>Suffix</strong>
					</div>
					<div className="col-6">
						<Input 
							value={fifthInput}
							onChange={(e) => setFifthInput(e.target.value)}
							placeholder={''}
							maxLength={100}
							error={true}
							errorMessage={'ting'}
							suffix="NOK"
						/>
					</div>
				</div>
			</section>
			<hr />
			<h1>DropDown / Select</h1>
			<section>
				<div className="row align-item-center mb-4">
					<div className="col-4">
						<strong>Single select</strong>
					</div>
					<div className="col-6">
					<DropDown
                        value={firstSingleSelect}
                        onChange={(e) => setFirstSingleSelect(e)}
                        placeholder={'Velg en av disse'}
						valid={true}
						options={[
							{
								"label": "Lorem ipsum dolor sit amet", 
								"value": "lorem"
							},
							{
								"label": "Ipsum dolor sit amet lorem",
								"value": "ipsum"
							},
							{
								"label": "Dolor sit amet lorem ipsum",
								"value": "dolor"
							},
							{
								"label": "Sit amet lorem ipsum dolor",
								"value": "sit"
							},
							{
								"label": "Amet lorem ipsum dolor sit",
								"value": "amet"
							},
							{
								"label": "Kenneth",
								"value": "kenneth"
							}

						]}
                    />
					</div>
				</div>
				<div className="row align-item-center mb-4">
					<div className="col-4">
						<strong>Multiple select checkbox</strong>
					</div>
					<div className="col-6">
						<DropDownCheck
							value={firstMultipleSelect}
							onChange={(e) => setFirstMultipleSelect(e)}
							placeholder={'Velg flere av disse'}
							valid={true}
							options={[
								{
									"label": "1. Utrydde fattigdom", 
									"value": "fng_01"
								},{
									"label": "2. Utrydde sult",
									"value": "fng_02"
								},{
									"label": "3. God helse og livskvalitet",
									"value": "fng_03"
								},{
									"label": "4. God utdanning",
									"value": "fng_04"
								},{
									"label": "5. Likestilling mellom kjønnene",
									"value": "fng_05"
								},{
									"label": "6. Rent vann og gode sanitærforhold",
									"value": "fng_06"
								},{
									"label": "7. Ren energi til alle",
									"value": "fng_07"
								},{
									"label": "8. Anstendig arbeid og øknonomisk vekst",
									"value": "fng_08"
								},{
									"label": "9. Industri, innovasjon og infrastruktur",
									"value": "fng_09"
								},{
									"label": "10. Mindre ulikhet",
									"value": "fng_10"
								},{
									"label": "11. Bærekraftige byer og lokalsamfunn",
									"value": "fng_11"
								},{
									"label": "12. Ansvarlig forbruk og produksjon",
									"value": "fng_12"
								},{
									"label": "13. Stoppe klimaendringene",
									"value": "fng_13"
								},{
									"label": "14. Livet i havet",
									"value": "fng_14"
								},{
									"label": "15. Livet på land",
									"value": "fng_15"
								},{
									"label": "16. Fred, rettferdighet og velfungerende institusjoner",
									"value": "fng_16"
								},{
									"label": "17. Samarbeid for å nå målene",
									"value": "fng_17"
								}
							]}
						/>
					</div>
			</div>
			</section>
			<hr />
			<h1>Buttons</h1>
			<section>
				<div className="row align-items-center mb-4">
					<div className="col-4">
						<strong>Primary</strong>
					</div>
					<div className="col-6">
					<Button 
						title="Neste"
						onClick={() => alert('lol')}
						rightIcon="arrow-right"
						type="primary"
					/>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-4">
						<strong>Secondary</strong>
					</div>
					<div className="col-6">
					<Button 
						title="Neste"
						onClick={() => alert('lol')}
						leftIcon="arrow-left"
						type="secondary"
					/>
					</div>
				</div>
			</section>
			<hr />
			<h1>Radio butons</h1>
			<section>
				<div className="row align-items-center mb-4">
					<div className="col-4">
						<strong>Default</strong>
					</div>
					<div className="col-6">
						<RadioGroup 
							items={[{label: 'Nei, jeg søker kun her', value: 'soker-kun-her'}]}
							onCheck={() => alert('lol')}
							checked={'soker-kun'}
						/>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-4">
						<strong>:checked</strong>
					</div>
					<div className="col-6">
						<RadioGroup 
							items={[{label: 'Ja, det har jeg', value: 'ja-det-har-jeg'}]}
							onCheck={() => alert('lol')}
							checked={'ja-det-har-jeg'}
						/>
					</div>
				</div>
			</section>
			<hr />
			<h1>Switch button (checkbox)</h1>
			<section>
				<div className="row align-items-center mb-4">
					<div className="col-4">
						<strong>Switch button med label</strong>
					</div>
					<div className="col-6">
						<ToggleSwitch
							label="Urelevant / Virksomheten har ikke medlemmer"
							value="morradi"
							onClick={(e) => {
								
							}}
							onChange={(e) => setFirstToggleSwitch(e.target.checked)}
						/>
					</div>
				</div>
			</section>
			<hr />
			<h1>Text area</h1>
			<section>
				<div className="row align-items-center mb-4">
					<div className="col-4">
						<strong>Textarea med label og char limit</strong>
					</div>
					<div className="col-6">
						<label>Beskriv nåværende status og hva som er gjort i prosjektet</label>
						<Textarea 
							placeholder='Fyll inn beskrivelse her'
							value={firstTextArea}
							onChange={(e) => setFirstTextArea(e.target.value)}
							maxLength={1000}
						/>
					</div>
				</div>
				<div className="row align-items-center mb-4">
					<div className="col-4">
						<strong>error</strong>
					</div>
					<div className="col-6">
						<Textarea 
							placeholder='Fyll inn beskrivelse her'
							value={secondTextArea}
							onChange={(e) => setSecondTextArea(e.target.value)}
							maxLength={1000}
							error={true}
							errorMessage="Beskrivelsen er for kort, vennligst skriv mer utfyllende"
						/>
					</div>
				</div>
				<div className="row align-items-center mb-4">
					<div className="col-4">
						<strong>valid</strong>
					</div>
					<div className="col-6">
						<Textarea 
							placeholder='Fyll inn beskrivelse her'
							value={thirdTextArea}
							maxLength={1000}
							error={false}
							valid={true}
							onChange={(e) => setThirdTextArea(e.target.value)}
						/>
					</div>
				</div>
			</section>
			<hr />
			<h1>File upload</h1>
			<div className="row mb-4">
			<div className="col-4">
					<strong>Normal</strong>
				</div>
				<div className="col-6">
					<FileUpload 
						onHandleDrop={handleFileDrop}
						onRemoveFile={removeFile}
						files={files}
					/>
				</div>
			</div>
		</div>
	);
}
