import { useContext, useEffect, useState, useRef } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import validateRules from "./Validations"
import { ApplicationContext } from "../providers/ApplicationProvider"

export default function Input(props){
    const { 
        value, 
        onChange, 
        placeholder, 
        type, 
        maxLength, 
        classes, 
        disabled, 
        prefix,
        suffix,
        validation,
        rules,
        mask,
        onAltChange
    } = props

    const {formData,lingo} = useContext(ApplicationContext);
    const [focus, setFocus] = useState(false)
    const [isValidating, setIsValidating] = useState(false)
    const [valid, setValid] = useState({error: false, valid: true}) // Sjekk om valid kan stå til true og ikke som false (originalt)
    const [hasFiredOnChange, setHasFiredOnChange] = useState(false)

    let typingTimer
    
    useEffect(async () => {
        clearTimeout(typingTimer)
        if(validation){
            setIsValidating(true)
            const isValid = await validation(value);
            setValid(isValid)
            setIsValidating(false)
        }
        if(rules){
            return setValid(validateRules(value, rules, formData, lingo));
        }
    }, [value])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(onAltChange) onAltChange(valid.valid)
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [valid])

    useEffect(() => {
        onChange && onChange(value, valid.valid);
    },[valid]);

    return (
        <div>
            <div className="form-group position-relative mb-1">
                <div className={"input-group " + (focus ? 'focus ' : '') + (Object.entries(rules).length ? '' : 'skip-validstyle ') + (disabled ? 'disabled ' : '') + (valid.valid ? 'valid ' : '') + (valid.error && hasFiredOnChange ? 'error ' : '')}>  
                    {prefix ? <span className="input-prefix">{prefix}</span> : null}  
                    <input 
                        maxLength={maxLength? maxLength : 300}
                        type={type ? type : 'text'}
                        className={"form-control " + (classes ? classes : null)} 
                        placeholder={placeholder} 
                        onChange={(e) => {
                            setHasFiredOnChange(true)
                            onChange(e.target.value, valid.valid)
                        }} 
                        value={value}
                        onFocus={(e) => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        disabled={disabled ? disabled : null}
                    />
                    {maxLength ? <span className="form-max-length">{value ? value.length : 0} / {maxLength}</span> : null}
                    {suffix ? <span className="input-suffix">{suffix}</span> : null}
                </div>
                
                {valid.valid === true && !isValidating && Object.entries(rules).length ? <div className={"input-validation-icon valid"}>
                    <FontAwesomeIcon icon={['fas', 'check']} size="sm" color="white" />
                </div> : null}
                {
                    isValidating ? <div className={"input-validation-icon"}>
                    <FontAwesomeIcon spin icon={['fas', 'spinner-third']} size="sm" color="grey" />
                </div> : null
                }
                {
                    hasFiredOnChange && valid.error && !isValidating ? <div className={"input-validation-icon error"}>
                        <FontAwesomeIcon icon={['fas', 'times']} size="sm" color="white" />
                    </div> : null
                }
            </div>
            {
                hasFiredOnChange && valid.error && valid.message && valid.type==='below' ? <span className="input-error-message">{valid.message}</span> : null
            }
            {
                hasFiredOnChange && valid.error && valid.message && valid.type==='box' ? <div className="input-error-message-box">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="p-2">
                                <FontAwesomeIcon icon={['far', 'circle-exclamation']} className="text-error opacity" size="4x" />
                            </div>
                        </div>
                        <div className="col">
                            {valid.message}
                        </div>
                    </div>
                </div> : null
            }
        </div>
    )
}