import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from 'axios'
import { ConfigContext } from "./ConfigProvider";
export const AuthContext = React.createContext({
    accessToken: null,
    user: null,
    login: () => {},
    getLoginState: () => {},
    apiCall: () => {},
    registerUser: () => {},
    logout: () => {}
})

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('sbs.user_data')))
    const [accessToken, setAccessToken] = useState(localStorage.getItem('sbs.access_token'))
    //const [baseUrl, setBaseUrl] = useState('https://sbsdev.catchmedia.no/')
    const {baseURL} = useContext(ConfigContext);

    const doApiCall = async (data, files) => {
        
        const formData = new FormData();
        formData.append("accessToken", accessToken);
        for ( var key in data ) {

            if(key.startsWith('filer_')){
                for (const file in data[key]){
                    if(data[key][file] instanceof File){
                        // instanceof File fungerer veldig bra
                        formData.append(key + '[]', data[key][file]);
                    }
                }
            }
            else if(key.startsWith('existingfile_')){
                for (const val in data[key]){
                    formData.append(key, data[key]);
                }

            }
            else if(Array.isArray(data[key])){
                for (const val in data[key]){
                    formData.append(key + '[]', data[key][val].value);
                }                
            }
            else if(typeof data[key] === 'object'){
                if(data[key] && data[key].value){
                    formData.append(key, data[key].value);
                }
                
            }
            else{
                formData.append(key, data[key]);
            }
            
        }
        const status = await axios({
            method: 'post',
            url: `${baseURL}api/`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })
        if(status.data.status === 5){
            //setMustRelog(true)
            localStorage.removeItem('sbs.user_data')
            localStorage.removeItem('sbs.access_token')
            return {
                data: {
                    status: 5
                }
            }
        }else{
            return status
        }
    }

    return <AuthContext.Provider value={{
        accessToken,
        user,
        //baseUrl,
        login: async (email, password) => {
            const status = await axios.post(`${baseURL}api/`, {
                action: 'api-login',
                username: email,
                password: password
            })

            if(status.data.status === 1){
                localStorage.setItem('sbs.access_token', status.data.token);
                localStorage.setItem('sbs.user_data', JSON.stringify(status.data.user));
                setUser(status.data.user)
                setAccessToken(status.data.token)
            }

            return status

        },
        logout: () => {
            localStorage.removeItem('sbs.user_data')
            localStorage.removeItem('sbs.access_token')
            setUser(null)
            setAccessToken(null)
            return <Navigate to="/" />
        },
        registerUser: async (email, password, repeatPassword) => {
            const status = await axios.post(`${baseURL}api/`, {
                action: 'api-register',
                username: email,
                password: password,
                repeatPassword: repeatPassword
            })
            /*if(status.data.status === 1){
                return status;
            }*/
            return status
        },
        forgotPassword: async (email, forgotPasswordCode, password, repeatPassword) => {
            const status = await axios.post(`${baseURL}api/`, {
                action: 'api-forgotPassword',
                username: email,
                forgotPasswordCode: forgotPasswordCode,
                password: password,
                repeatPassword: repeatPassword
            })
            /*if(status.data.status === 1){
                return status;
            }*/
            return status
        },
        getLoginState: async () => {
            const status = await axios.post(`${baseURL}api/`, {
                action: 'api-get-user',
                access_token: '844d01f4cbf413094f74c962c758e51ba930eca91ddb0c75c96e5dec361c299b'
            })

            
        },
        saveUserProfile: async (userProfileInfo) => {
            const status = await axios.post(`${baseURL}api/`, {
                action: 'api-saveUserProfile',
                accessToken: accessToken,
                userProfileInfo: userProfileInfo
            });
            return status;
        },
        apiCall: async (data) => {
            const status = await doApiCall(data)
            return status            
        }
    }}>
        {children}
    </AuthContext.Provider>
}