import { faGameConsoleHandheld } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createRef, useEffect, useState, useContext } from "react"
import { ApplicationContext } from "../providers/ApplicationProvider";
import { ConfigContext } from "../providers/ConfigProvider";
export default function FileUpload(props){
    const { lingo } = useContext(ConfigContext);
    const {maxNumberOfFiles, onHandleDrop, files, required, onRemoveFile, existing_files, extensions, extensionsFormat, validateNewFiles } = props
    const container = createRef()
    const [dragging, setDragging] = useState(false)
    const [mousePosition, setMousePosition] = useState({})
    const [validFileType, setValidFileType] = useState(true);
    const [requiredValidation, setRequiredValidation] = useState(false);

    const fileSelector = document.createElement('input');

    const renderAcceptList = (extensionsFormat) => {
        let output = "";

        if(extensionsFormat && extensionsFormat.length){
            output = extensionsFormat.join(",");
        }
        
        return output;
    }
    
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', renderAcceptList(extensionsFormat));
    fileSelector.addEventListener('change', (e) => {
        e.preventDefault();
        let path = e.path || (e.composedPath && e.composedPath());

        //Trenger muligens ikke være med?
        if(validateNewFiles(path[0].files)){
            setValidFileType(true);
            onHandleDrop(path[0].files, validFileType, requiredValidation)
        }else{
            setValidFileType(false);
        }
        onHandleDrop(path[0].files, validFileType, requiredValidation)
        // onHandleDrop(e.path[0].files)
    })

    useEffect(() => {
        if(required && !existing_files.length){
            setRequiredValidation(false);
        }else{
            setRequiredValidation(true);
        }
    })
    
    useEffect(() => {
        if(container.current){
            ['dragenter', 'dragover'].forEach(eventName => {
                container.current.addEventListener(eventName, (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    
                    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {

                        setDragging(true)
                        /*
                        setMousePosition({
                            position: 'fixed',
                            top: e.pageY,
                            left: e.pageX
                        })
                        */
                    }
                }, false)
                
            });

            ['dragleave'].forEach(eventName => {
                
                container.current.addEventListener(eventName, (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                        setDragging(false)
                    }
                }, false)
                
            })

        
            container.current.addEventListener('drop', (e) => {
                e.preventDefault()
                
                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                    setDragging(false)
                    if(validateNewFiles(e.dataTransfer.files)){
                        onHandleDrop(e.dataTransfer.files, validFileType, requiredValidation)
                    }
                    setValidFileType(validateNewFiles(e.dataTransfer.files));
                    e.dataTransfer.clearData()  
                }
                
            })
        }

        return () => {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                if(container.current){
                    container.current.removeEventlistener(eventName)
                }
            })
        }
    }, [container])

    const formatExtensions = (extensions) => {
        if(extensions){
            let output = '';
            let typeArr = extensions.split('/');
            let lastItem = typeArr.pop();

            if(typeArr.length){
                output = typeArr.join(", ");
            }
            output += ` ${lingo.ext_and} ${lastItem} ${lingo.ext_is_legal}`;
            return output;
        }else{
            return lingo.ext_all_is_legal
        }
    }

    const FileItem = ({ file }) => {
        let icon = 'file-text';
        if(!extensions){
            switch(file.type){
                case 'application/pdf':
                    icon = 'file-pdf';
                    break;
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    icon = 'file-word'
                    break;
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    icon = 'file-excel';
                    break;
                default:
                    icon = 'file-text'
                
            }
        }else{

            icon = 'file-image';
        }
        return (
            <div className="file-item">
                <div className="px-2 text-center" title={file.name}>
                    <FontAwesomeIcon icon={['far', icon]} size="3x" />
                    <h6>{file.name}</h6>
                    <h6 className="remove-file text-danger" onClick={() => {
                        let isRequired = required ? true : false;
                        onRemoveFile(file, isRequired)
                    }}><FontAwesomeIcon className="px-1" icon={['far', 'times']} size="lg"></FontAwesomeIcon> {lingo.ext_remove_file}</h6>
                </div>
            </div>
        )
    }
    
    const ExistingFilesItem = ({file}) => {
        let icon = 'file-text';
        if(!file) return <div></div>
        return(
            <div className="file-item">
                <div className="px-2 text-center" title={file.name}>
                    <FontAwesomeIcon icon={['fal', icon]} size="3x" className="mb-1 opacity-light" />
                    <h6>{file.name}</h6>
                    <h6 className="remove-file text-danger" onClick={() => {
                        let isRequired = required ? true : false;
                        onRemoveFile(file, isRequired)
                    }}><FontAwesomeIcon className="px-1" icon={['far', 'times']} size="lg"></FontAwesomeIcon> {lingo.ext_remove_file}</h6>
                </div>
            </div>
        );
    }

    return (
        <div>
        <div ref={container} className={"file-upload-container " + (dragging ? 'highlight' : '')}>
            {
            (files && (files.length === 0)) && (existing_files && (existing_files.length === 0))?
                <div className="file-wrapper">
                    <div className="file-upload-icon">
                        <div className="file-upload-bubble" style={dragging ? mousePosition : null}></div>
                        <FontAwesomeIcon icon={['fal', 'file-text']} size="4x" />
                    </div>
                </div>
            : 
                (<>
                <div className="file-wrapper">
                    {
                        existing_files && existing_files.map((el, index) => {
                            return (
                                <ExistingFilesItem
                                    key={index}
                                    file={el}
                                />
                            );
                        })
                    }
                    {
                        files && files.map((el, index) => {
                            return (
                                <FileItem 
                                    key={index}
                                    file={el}
                                />)
                        })  
                    }
                </div>
                </>)
            }
            <div className="file-upload-text text-center">
                <strong>{lingo.ext_drop_files_here} <a href="javascript();" onClick={(e) => {
                    e.preventDefault();
                    fileSelector.click();
                }}>{lingo.ext_upload}</a></strong>
                <p>{formatExtensions(extensions)}</p>
            </div>
        </div>
        {!validFileType && <p className="text-error text-center mt-2">{lingo.ext_upload_error}</p>}
        </div>
    )
}
