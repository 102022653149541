//import { valid } from "semver";

const validateRules = (value, rules, formData, lingo) => {
    let feedback = {
        error: false,
        valid: true,
        message: '',
        type:''
    }

    Object.keys(rules).map((rule) => {
        let helper = {}
        //let {errormessage, errortype, errorvalue} = rules[rule];
        switch(rule){
            case 'minLength':
                helper = minLength(value, rules[rule], lingo);
                break;
            case 'numbersOnly':
                helper = numbersOnly(value, rules[rule], lingo);
                break;
            case 'dependFieldPercent':
                const dependFieldPercentData = formData[rules.dependFieldPercent.name];
                if(dependFieldPercentData){
                    helper = dependFieldPercent(value, rules[rule], dependFieldPercentData, lingo);
                }
                break;
            case 'email':
                helper = checkEmail(value, rules[rule], lingo);
                break;
            case 'required':
                helper = required(value, rules[rule], lingo);
                break;
            case 'externalValidate':
                helper = {
                    error: null
                }
                break;
            default:
                helper = {
                    error: null,
                    valid: true
                }
        }
        if(helper && !helper.valid){
            feedback.valid = false;
        }
        if(helper && helper.error){
            feedback.error = true;
        }
        if(helper && helper.message){
            feedback.message = `${helper.message}`
            feedback.type = helper.type;
        }

    })
    return feedback;
}



const minLength = (value, { errormessage = lingo.validations_minlength, errortype='below', errorvalue=5}, lingo) => {
    if(!value) return {
        error: true,
            valid: false,
            message: lingo.validations_this_field_required,
            type: errortype
    }
    if(value.length < errorvalue){
        return {
            error: true,
            valid: false,
            message: errormessage,
            type: errortype
        }
    }
    // return {
    //     valid: true,
    //     error: false
    // }
}

const required = (value, { errormessage = lingo.validations_field_required, errortype='below', errorvalue=true}, lingo) => {
    if(value.length <= 0 && errorvalue){
        return {
            error: true,
            valid: false,
            message: errormessage,
            type: errortype
        }
    }
    return {
        error: false,
        valid: true
    }
}

const numbersOnly = (value, { errormessage = lingo.validations_numbers_only, errortype='below', errorvalue=true}, lingo) => {
    let isnum = null;
    if(value){
        isnum = /^\d+$/.test(value);
    }
    if(value === ""){
        isnum = true;
    }
       
    if(!isnum){
        return {
            error: true,
            valid: false,
            message: errormessage,
            type: errortype
        }
    }    
}

const checkEmail = (value, { errormessage = lingo.validations_checkemail, errortype='below', errorvalue=true}, lingo) => {

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(value) && errorvalue){
        // Valid
        return{
            error: false,
            valid: true
        }
    }else{
        // Invalid
        return{
            error: true,
            valid: false,
            message: errormessage,
            type: errortype
        }
        
    }
}

const dependFieldPercent = (value, {name, type, percent, errormessage, errortype}, dependData) => {
    if(dependData){
        percent = Number(percent);
        const total = Number(dependData.value);
        const amount = Number(value);
        const maxAmount = percent * total / 100;

        if(amount > maxAmount){
            return {
                error: true,
                valid: false,
                message: errormessage,
                type: errortype
            }
        }
    }
    return {
        error:false,
        valid: true
    }
}

export default validateRules;