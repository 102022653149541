import { useContext, useEffect, useState } from "react";
import { FinalReportContext } from "../providers/FinalReportProvider";
import validateRules from "./Validations";

const ToggleSwitch = (props) => {
    const { 
        value, 
        onChange, 
        placeholder, 
        classes, 
        disabled, 
        prefix,
        error,
        errorMessage,
        options,
        title,
        rules,
        description,
        onClick,
        checked,
        label
    } = props;

    

    const [isChecked, setIsChecked] = useState(checked ? checked : false);
    const [hasFiredOnChange, setHasFiredOnChange] = useState(false)
    const [valid, setValid] = useState({error: false, valid: false})
    const {lingo} = useContext(FinalReportContext);
    useEffect(() => {
        
        onClick(isChecked, valid.valid);
    },[isChecked]);

    const handleClick = (e) => {
        setIsChecked(!isChecked);
    }

    
    return (
        <div>
            {description ? (
                <p>{description}</p>
            ) : (lingo && lingo.finalreport_image_text ? (
                <p>{lingo.finalreport_image_text}</p>
            ) : null
            )}
            
            <div className="toggleswitch-container" onClick={handleClick}>
                
                <div className="toggleswitch">
                    <input 
                        type="checkbox" 
                        value={value}
                        checked={isChecked}
                        onChange={(e) => {
                            setHasFiredOnChange(isChecked)
                            onChange(e.target.value, valid.valid)
                        }}
                    />
                    <span className="toggleswitch-slider" value={value}></span>
                </div>
                <label>{label}</label>
            </div>
        </div>
    );
}
 
export default ToggleSwitch;