import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"

export default function DropDownCheck(props){
    const { 
        value, 
        onChange, 
        placeholder, 
        classes, 
        disabled, 
        prefix,
        error,
        errorMessage,
        valid,
        rules,
        options,
        readonly
    } = props

    const [open, setOpen] = useState(false)
    const [focus, setFocus] = useState(false)
    const nodeRef = useRef(null);

    

    useEffect(() => {
        
    }, [open])
    const handleChange = (object) => {
        if(!readonly && !disabled){
            if(value.filter(v => v.value === object.value).length){
                const newValue = value.filter((t) => t.value !== object.value);
                let reqPopulated = true;
                if(rules.hasOwnProperty("required")){
                    reqPopulated = newValue.length ? true : false;
                }
                onChange(newValue, reqPopulated);
            }else{
                let reqPopulated = true;
                if(rules.hasOwnProperty("required")){
                    reqPopulated = object.value.length ? true : false;
                }
                onChange([...value, object], reqPopulated);
            }
        }

    }

    const Option = ({ label, val }) => {
        return (
            <li 
                className={value.filter(v => v.value === val).length ? 'active' : ''}
                onClick={() => {
                    handleChange({label: label, value: val});
                }}
                
            >
                <div className="checkbox">
                    {value.filter(v => v.value === val).length ? <FontAwesomeIcon size="xs" icon={['fas', 'check']} /> : null }
                </div>
                {label}
                {/* <input type="checkbox" readOnly checked={value.filter(v => v.value === val).length ? 'checked' : ''} />{label} */}
            </li>
        )
    }
    
    const renderValue = (value, placeholder) => {

        if(value && value.length !== 0){
            let values = value.map((item) => {
                return (<span className="ms-choice" key={item.value}>{item.label} <span className="svg-inside remove-choice" onClick={(event) => { 
                    handleChange(item);
                }}><FontAwesomeIcon  size="xs" icon={['fal', 'times']} /></span></span>);
            });

            return <span>{values}</span>;
        }
        return <p>{placeholder}</p>;   
    }

    return (
        <div className="position-relative">
            <div className="form-group position-relative mb-1" onClick={(event) => {
                if(!disabled && !readonly){
                    if(event.target.classList.contains('remove-choice')){
                        
                    }else{
                        setOpen(!open);    
                    }
                }
                
            }}>
                <div className={"dropdown input-group " + (open ? 'focus ' : '') + (readonly ? 'readonly ' : '') + (disabled ? 'disabled ' : '') + (valid ? 'valid ' : '') + (error ? 'error ' : '')}>  
                    {/* <p>{value && value.length !== 0 ? value.label : placeholder}</p> */}
                    {renderValue(value, placeholder)}
                    <div className="chevron"><FontAwesomeIcon icon={['fal', 'chevron-down']} /></div>
                </div>
            </div>
             
            <CSSTransition nodeRef={nodeRef} in={open} unmountOnExit timeout={200} classNames="dropdown">
                <div ref={nodeRef} className="dropdown-options multiple" tabIndex="0" onBlur={(event) => { 
                    setOpen(false); }}>
                    <ul>
                        {
                            options.map((item, key) => {
                                return (
                                    <Option 
                                        key={key}
                                        label={item.label}
                                        val={item.value}
                                    />
                                )
                            })
                        }
                    </ul>
                </div>
            </CSSTransition>
             
        </div>
    )
}