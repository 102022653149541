import React, { useContext } from "react";
import { AuthContext } from "./providers/AuthProvider";
import AppContainer from "./AppContainer";
import AuthDashboard from "./screens/AuthDashboard";

export default function Auth(){
    const { accessToken } = useContext(AuthContext)

    if(accessToken === null){
        return (
            <AuthDashboard />
        )
    }

    return (
        
            <AppContainer />
       
    )
}