
export const formatFormType = (a, lingo) => {
    switch(a){
        case 1:
            return lingo.formatformtype_1 //'Prosjektgave'
        case 2:
            return lingo.formatformtype_2 //'Generell gave til drift'
        case 3:
            return lingo.formatformtype_3 //'Tilskudd og investering'
        default:
            return lingo.formatformtype_4 //'(ukjent)'
    }
}

export const formatNumber = (a) => {
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}
export const formatOrgnr = (a) => {
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

export const formatPhoneNumber = (a) => {
    return a.toString().replace(/\B(?=(\d{2})+(?!\d))/g, " ");
}

export const attachmentLabel = (a, lingo) => {
    switch(a){
        case 'filer_regnskap': return lingo.files_accounting;
        case 'filer_prosjektbudsjett': return lingo.files_project_budget;
        case 'filer_finansieringsplan': return lingo.files_financial_plan;
        case 'filer_fremdriftsplan': return lingo.files_progress_plan;
        case 'filer_andre1': return lingo.files_other_1;
        case 'filer_andre2': return lingo.files_other_2;
    }
}

export const getStatus = (a, lingo) => {
    switch(a){
        case 0: return { label: lingo.functions_getstatus_unhandled, icon: "circle-minus", className: "text-pending"}
        case 1: return { label: lingo.functions_getstatus_allocated, icon: "circle-check", className: "text-success"}
        case 2: return { label: lingo.functions_getstatus_rejected, icon: "times-circle", className: "text-danger"}
        case 3: return { label: lingo.functions_getstatus_not_realized, icon: 'times-circle', className: "text-warning"}
        case 4: return { label: lingo.functions_getstatus_final_report_submitted, icon: "circle-check", className: "text-success"}
        case 9: return { label: lingo.functions_getstatus_draft, icon: 'pencil', className: 'text-warning'}

    }
}

export const fileIcon = (a) => {
    switch(a){
        case 'pdf': return 'file-pdf'
        case 'xls': case 'xlsx': return 'file-excel'
        case 'csv': return 'file-csv'
        case 'ppt': case 'pptx': return 'file-powerpoint'
        case 'zip': case 'rar': return 'file-zipper'
        case 'doc': case 'docx': return 'file-word'
        case 'jpg': case 'png': return 'file-image'
        default: return 'file'
    }
}

