import { faGameConsoleHandheld } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createRef, useEffect, useState } from "react"

export default function FileUploadButton(props){

    const { onHandleDrop, files, onRemoveFile } = props
    const container = createRef()
    const [dragging, setDragging] = useState(false)
    const [mousePosition, setMousePosition] = useState({})

    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.addEventListener('change', (e) => {
        e.preventDefault();

        
        let path = e.path || (e.composedPath && e.composedPath());
        onHandleDrop(path[0].files)
        // onHandleDrop(e.path[0].files)
    })

    
    useEffect(() => {
        if(container.current){
            ['dragenter', 'dragover'].forEach(eventName => {
                
                container.current.addEventListener(eventName, (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    
                    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                        setDragging(true)
                        /*
                        setMousePosition({
                            position: 'fixed',
                            top: e.pageY,
                            left: e.pageX
                        })
                        */
                    }
                }, false)
                
            });

            ['dragleave'].forEach(eventName => {
                
                container.current.addEventListener(eventName, (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                        setDragging(false)
                    }
                }, false)
                
            })

        
            container.current.addEventListener('drop', (e) => {
                e.preventDefault()
                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                    setDragging(false)
                    onHandleDrop(e.dataTransfer.files)
                    e.dataTransfer.clearData()  
                }
                
            })
        }

        return () => {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                if(container.current){
                    container.current.removeEventlistener(eventName)
                }
            })
        }
    }, [container]) 
    

    return (
        <button className="btn btn-white" onClick={() => fileSelector.click()}>
            <div className="row gx-2">
                <div className="col-auto">
                    <FontAwesomeIcon icon={['fal', 'paperclip']} />
                </div>
                <div className="col-auto">
                    {files.length ? `${files.length} ${files.length === 1 ? 'fil' : 'filer'}` : 'Filer'}
                </div>
            </div>
        </button>
    )
}
