import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useState } from "react"
import { FormElement } from "../screens/Form";
import { ApplicationContext } from "../providers/ApplicationProvider";

const RadioGroupDepending = (props) => {
    const { items, checked, onCheck, classes, separator, value, depends, FormElementAlt, name} = props
    const [dependencies, setDependencies] = useState([]);
    const {formData, updateForm} = useContext(ApplicationContext);

    useEffect(() => {
        if(value !== null || value !== undefined){
            const dependArr = DependentMarkup(value, depends).map((dep) => {
                return dep;
            });

            
            setDependencies(dependArr);
        }
    },[value]);

    useEffect(() => {
        if(dependencies) {
            let bool = true
            dependencies.forEach((item, key) => {
                if(formData[item.name] && !formData[item.name].valid) bool = false
            })

            //updateForm(name, value, bool)

        }
    }, [formData])

    const validate = (isValid) => {
        updateForm(name, value, isValid)
    }

    if(items.length === 0){
        return (<div />)
    }
    
    if(depends.length === 0){
        return (<div />);
    }
    
    return (
        <React.Fragment>
            <div className={"radio-group " + classes}>
                {
                    items.map((el, index) => {
                        return (
                            <React.Fragment key={el.label + index}>
                                {separator && index > 0 ? <span key={`n${index}`} className="separator">{separator}</span> : null}
                                <RadioButton
                                    label={el.label}
                                    active={value === el.value || checked === el.value || value.toString() === el.value || checked.toString() === el.value}
                                    onCheck={onCheck}
                                    value={el.value}
                                />
                            </React.Fragment>
                        )
                    })
                }
            </div>
            <div>
                {
                    dependencies.map((item, key) => {
                        if(FormElementAlt){
                            return (
                                <FormElementAlt
                                    key={key}
                                    item={item}
                                />
                            )
                        }
                        return(
                            <FormElement
                                key={key}
                                item={item}
                                altOnChange={(e) => validate(e)}
                            />
                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}

const DependentMarkup = (radiovalue, depends) => {
    const filteredDepends = depends.filter((dep) => {
        return dep.radiovalue === radiovalue || dep.radiovalue === radiovalue.toString();

    });
    return filteredDepends.length > 0 ? filteredDepends[0].innerElements : [];
}

function RadioButton({ label, active, onCheck, value }){
    return (
        <div className={"radio-button " + (active ? 'radio-button-active' : '')} onClick={() => onCheck(value)}>
            <div className="radio-button-circle">
                {active ? <FontAwesomeIcon icon={['fas', 'check']} /> : null }
            </div>
            <label>{label}</label>
        </div>
    )
}

 
export default RadioGroupDepending;