import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export default function RadioGroup(props){
    const { items, checked, onCheck, classes, separator, value } = props

    if(items.length === 0){
        return (<div />)
    }
    
    return (
        <div className={"radio-group " + classes}>
            {
                items.map((el, index) => {
                    return (
                        <React.Fragment key={el.label + index}>
                            {separator && index > 0 ? <span key={`n${index}`} className="separator">{separator}</span> : null}
                            <RadioButton
                                label={el.label}
                                active={value === el.value || checked === el.value}
                                onCheck={onCheck}
                                value={el.value}
                            />
                        </React.Fragment>
                    )
                })
            }
        </div>
    )
}

function RadioButton({ label, active, onCheck, value }){
    return (
        <div className={"radio-button " + (active ? 'radio-button-active' : '')} onClick={() => onCheck(value)}>
            <div className="radio-button-circle">
                {active ? <FontAwesomeIcon icon={['fas', 'check']} /> : null }
            </div>
            <label>{label}</label>
        </div>
    )
}