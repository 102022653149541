import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../providers/ConfigProvider";
import { ApplicationContext } from "../providers/ApplicationProvider";

const useTypeBook = (input) => {
    const { applicationType } = useContext(ApplicationContext);
    const { typeBook } = useContext(ConfigContext);
    const [name, setName] = useState(null);
    const [type, setType] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const currentTypeBook = typeBook.filter((page) => page.type === applicationType);

    useEffect(() => {

        if(currentTypeBook[0] && currentTypeBook[0].fields){
            let result = currentTypeBook[0].fields.find(f => f.name === input);

            if(result){
                setError(false);
                setErrorMessage(null);
                setName(result.name);
                setType(result.type);
            }else{
                setError(true);
                setErrorMessage(`Cannot find ${input} in applicationType: ${applicationType}`);
            }
        }else{
            setError(true);
            setErrorMessage(`Not finding any fields in currentTypeBook`);
        }

    }, [input]);


    return { name, type, applicationType, input, error, errorMessage };

}

export default useTypeBook;