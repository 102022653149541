import './App.css';
import { AuthProvider } from './providers/AuthProvider';
import Auth from './Auth';
import { useEffect } from 'react';

function App() {
	return (
		<AuthProvider>
			<Auth />
		</AuthProvider>
	)
}


export default App;
