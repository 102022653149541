import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../providers/ApplicationProvider"
import { FinalReportContext } from "../providers/FinalReportProvider";

const CalculateFields = (props) => {
    const {formData} = useContext(FinalReportContext);
    const {
        fields, 
        display, 
        sublabel
    } = props;
    
    const getArraySum = (a) => {
        let total = 0;
        for(let i in a){
            let val = 0;
            if(formData[a[i].name] === undefined){
                continue;
            }
            if(!('repeaterfield' in a[i])){
                val = formData[a[i].name].value;
            }
            if('repeaterfield' in a[i]){
                let repeaterField = a[i].repeaterfield;
                let reducer = formData[a[i].name].value.map((item, key) => {
                    if (item[repeaterField] === undefined){
                        return 0;
                    }
                    else{
                        return item[repeaterField].value
                    }       
                })
                val = reducer.reduce((sum, x) => Number(sum) + Number(x));
            }
            if(a[i].method === 'add'){
                total += Number(val);
            }
            if(a[i].method === 'subtract'){
                total -= Number(val);
            }
        }
        return total;
    }
    
    
    let amount = 0;

    if ( Object.keys(formData).length && fields.length) {
        amount = getArraySum(fields);
    }

    const formatConfig = {
        style: "currency",
        currency: "NOK", // CNY for Chinese Yen, EUR for Euro
        minimumFractionDigits: 0,
        currencyDisplay: "symbol",
      };


    return (
        <>
            <div className={display ? display : ''}>
                <div className="row d-flex justify-content-center">
                    <div className="col-6">
                        <div className="bg-light-grey rounded-more py-1">
                            <p className="p-0 m-0 calc-label">
                                <strong>
                                    {Intl.NumberFormat('nb-NO', formatConfig).format(amount)}
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {sublabel ? <small><i>{sublabel}</i></small> : ''}
        </>
    );
}
 
export default CalculateFields;