import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createContext, useContext, useEffect, useState } from "react";
import { Link, Outlet, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { AuthContext, AuthProvider } from "../providers/AuthProvider";
import BubbleModal from "../components/BubbleModal";
import { formatFormType, getStatus } from "../helpers/Functions";
import Button from "../components/Button";
import { ConfigContext } from "../providers/ConfigProvider";
import Skeleton from "../components/Skeleton";
import InputMask from "../components/InputMask";
import moment from 'moment'
import 'moment/locale/nb';
import Header from "../components/Header";


const Context = createContext({
    active: null,
    archived: null,
    messages: null
})

const Provider = ({children}) => {
    // Denne provideren sørger for at innlasting av soknader skjer
    const { apiCall } = useContext(AuthContext)

    const activeStatus = [0,1];
    const archivedStatus = [2,3,4];

    const [drafts, setDrafts] = useState(null)
    const [active, setActive] = useState(null)
    const [archived, setArchived] = useState(null)
    const [messages, setMessages] = useState(null)
    const [applications, setApplications] = useState(null)


    const loadApplications = async () => {
		const { data } = await apiCall({
			action: 'getApplicationsByUser'
		})

		if(data.status === 1){
			setApplications(data.data)
		}
	}

    useEffect(() => {
        loadApplications()
    }, [])

    useEffect(() => {
        if(applications){
            
            setDrafts(applications.filter(a => a.status === 9))
            setActive(applications.filter(a => activeStatus.includes(a.status)))
            setArchived(applications.filter(a => archivedStatus.includes(a.status)))

            let messages = []
            applications.map((item) => {
                if(item.messages.filter(m => m.unread).length){
                    messages.push({
                        name: item.name,
                        hash: item.hash,
                        object: item.messages[item.messages.length - 1]
                    })
                }
            })

            setMessages(messages)
        }
    }, [applications])

    return <Context.Provider value={{
        active,
        archived,
        messages,
        drafts
    }}>{
        applications ? children : <SkeletonContainer />
    }</Context.Provider>
}


export default function Dashboard(){
    const { logout } = useContext(AuthContext)
    const { lingo } = useContext(ConfigContext);
    return (
        <Provider>
            <div className="container">
                <Header/>
                <div className="py-md-4 py-0">
                    <h1>{lingo.dashboard_title}</h1>
                </div>
                <div className="tabs">
                    <div>
                        <CustomLink to="">{lingo.dashboard_your_overview}</CustomLink>
                        <CustomLink to="arkiv">{lingo.dashboard_archive}</CustomLink>
                        <CustomLink to="rediger-profil">{lingo.dashboard_edit_profile}</CustomLink>
                    </div>
                    <div>
                        <a className="logout-link" href="#" onClick={logout}>{lingo.dashboard_logout} <FontAwesomeIcon icon={['far', 'arrow-right-from-bracket']} /></a>
                    </div>
                </div>
                <div className="py-3">
                    <Outlet />
                </div>
            </div>
        </Provider>
    )
}

export function Overview(){
	const [modalActive, setModalActive] = useState(false)
	const { data, lingo } = useContext(ConfigContext)
    const { active, drafts, messages } = useContext(Context)
    const {apiCall} = useContext(AuthContext);
    const { getDraftFromDataBase, getDraftDetails } = useContext(ApplicationContext)
    const [draftId, setDraftId] = useState(null)
    const [draftStatus, setDraftStatus] = useState(false)

    const handleContinueApplication = async () => {
        
        //Sjekk om brukeren har noen drafts og sett hasDraft til true
        const object = {
            applicationType: 1,
        };
        let response = await apiCall({
            action: 'checkForDraft',
            ...object
        })
        .then((res) => {
            if ( res.data.status === 1){
                setDraftId(res.data.data.draft.applicationId);
                setDraftStatus(true);
            }
        })
        .catch((err) => {
            console.error(err);
        });

        //let draftDetails = await getDraftDetails(draftId);
        //getDraftDetails(draftId).then((data) => data);

        /*
        getDraftFromDataBase().then((data) => {

            if(data){
                let section = data.at(-1);
                if(section.forms){
                    let form = section.forms.at(-1);
                    navigate(section.url + '/' + form.url);
                }
            }else{
                //next()
            }
        });*/
    }

	return (
		<div>
			<div className="row">
				<div className="col-lg-7">
                    <h3>{lingo.dashboard_your_applications}</h3>
                    <p>{lingo.dashboard_your_applications_text}</p>
                    <Button
                        title={lingo.dashboard_new_application}
						rightIcon="arrow-right"
						type="secondary mb-4 newapplication"
						onClick={() => {
							setModalActive(!modalActive)
						}}
					/>
					<div className="mb-4">
                        {
                            drafts && drafts.length ? 
                                drafts.map((item, key) => <ApplicationDraft key={key} {...item} />) : null
                        }
						{
							!active ? (
								<div className='row justify-content-center py-5'>
									<FontAwesomeIcon icon={['fad', 'spinner-third']} spin size='3x' />
								</div>	
							) : active.map((item, key) => <ApplicationItem key={key} {...item} />)
						}
					</div>
					

						
					
				</div>
				<div className="col-lg-5">
                    <h3>{lingo.dashboard_unread_messages}</h3>
					{
                        !messages ? (
                            <div className='row justify-content-center py-5'>
                                <FontAwesomeIcon icon={['fad', 'spinner-third']} spin size='3x' />
                            </div>	
                        ) : messages.length ? messages.map((item, key) => <MessageItem key={key} {...item} />) : <div><p><i>{lingo.dashboard_no_unread_messages}</i></p></div>
                    }
				</div>
			</div>
			<BubbleModal active={modalActive} setActive={setModalActive} background="">
				<div className="choose-applicationtype">
					<img src={process.env.PUBLIC_URL + "/svg/soknad-gfx.svg"} alt={lingo.choose_application_type} className="bubble-topimg" />
                    <h3 className='text-center mb-4'>{lingo.dashboard_what_apply_title}</h3>
					{
						data && data.applications && data.applications.map((item, key) => {
							return (
								<ApplicationChoice 
									key={key}
									item={item}
									setModalActive={setModalActive}
								/>
							)
						})
					}
					<div className="text-center mt-4">
						<a href="#" className="bubble-backlink font-weight-medium" onClick={(e) => {
							e.preventDefault()
							setModalActive(false)
                        }}><FontAwesomeIcon icon={['far', 'arrow-left']} className="pr-4" /> {lingo.dashboard_take_me_back}</a>
					</div>
				</div>
			</BubbleModal>
		</div>
	)
}
export function Archive(){
    const { lingo } = useContext(ConfigContext)
	const { archived } = useContext(Context)
	return (
		<div>
            <h3>{lingo.dashboard_archive}</h3>
            <p>{lingo.dashboard_archive_text}</p>
			<div className="">
                {
                    !archived ? (
                        <div className='row justify-content-center py-5'>
                            <FontAwesomeIcon icon={['fad', 'spinner-third']} spin size='3x' />
                        </div>	
                    ) : archived.map((item, key) => <ApplicationItem key={key} {...item} />)
                }
			</div>
		</div>
	)
}

export function Profile(){
    const { lingo } = useContext(ConfigContext)
    const { user } = useContext(AuthContext)
    const { saveUserProfile } = useContext(AuthContext);

    const [fullname, setFullname] = useState(user.fullname)
    const [email, setEmail] = useState(user.email)
    const [phone, setPhone] = useState(user.phone)

    const [address, setAddress] = useState(user.address)
    const [city, setCity] = useState(user.city)
    const [zip, setZip] = useState(user.zip)
    const [error, setError] = useState({})
    const [success, setSuccess] = useState(null);

    const getCityFromPostCode = async (zipcode) => {
        const headers = {
            'X-MyBring-API-Uid': 'post@catchmedia.no',
            'X-MyBring-API-Key': 'aec45816-0c00-4ac8-88dc-a6d3e29cd771'
            //'X-Bring-Client-URL': window.location.href
        }

        const url = `https://api.bring.com/pickuppoint/api/postalCode/NO/getCityAndType/${zipcode}.json`;
        //const url = `https://webapi.no/api/v1/zipcode/${zipcode}`;
        
        const response = await fetch(url, { headers });
        //const response = await fetch(url);
        const json = await response.json();
        if(response.ok){
            return json.postalCode.city;
            //return json.data.city;
        }else{
            return '';
        }
    }

    const checkPostCode = (value) => {
        let isnum = /^\d+$/.test(value);
        if(isnum){
            if(value.length === 4)
            getCityFromPostCode(value)
            .then( (response) => {
                if(response !== ''){
                    setCity(response);
                }
            });
        }
        if(value.length !== 4){
            setCity('');
        }
    }

    const doSaveChanges = async () => {
        const userProfileInfo = {
            user,
            fullname,
            email,
            phone,
            address,
            city,
            zip
        };

        /*saveUserProfile(userProfileInfo).then(({data}) => {
            setError({status: data.status, msg: data.message});
            setSuccess({status: data.status, msg: data.message});
        });*/

        const res = await saveUserProfile(userProfileInfo);
        // Her gjenstår det å få gitt noe positiv feedback til bruker.
        const data = await res.data;
        if(data.status === 1){
            setError(null);
            setSuccess({msg: data.message});
        }else{
            setSuccess(null);
            setError({msg: data.message});
        }
    }

    useEffect(() => {
        checkPostCode(zip);
    }, [zip])

	return (
        <>
		    <h2>{lingo.dashboard_edit_profile_title}</h2>
            <div className="row">
                <div className="col-md-5">
                    <div className="form-group mb-3">
                        <label>{lingo.dashboard_edit_profile_full_name}</label>
                        <Input 
                            type="text"
                            onChange={setFullname} 
                            value={fullname}
                            prefix={<FontAwesomeIcon icon={['fal', 'user']} 
                            placeholder={lingo.dashboard_edit_profile_full_name_placeholder} />} 
                            rules={{
                                letFunctionValidate:{}
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="form-group mb-3">
                        <label>{lingo.dashboard_edit_profile_email_title}</label>
                        <Input 
                            type="email"
                            rules={{
                                letFunctionValidate:{}
                            }}
                            value={email} 
                            onChange={setEmail} 
                            prefix={<FontAwesomeIcon icon={['fal', 'at']} />} 
                            placeholder={lingo.dashboard_edit_profile_email_placeholder}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group mb-3">
                        <label>{lingo.dashboard_edit_profile_phone_label}</label>
                        <InputMask 
                        value={phone} 
                        onChange={setPhone} 
                        prefix={<FontAwesomeIcon icon={['fal', 'phone']} placeholder={lingo.dashboard_edit_profile_phone_placeholder} />}
                        type="inputmask"
                        required={true}
                        replace=" "
                        replaceWith=""
                        mask="00 00 00 00"
                        rules={{
                            letFunctionValidate:{}
                        }}
                        />
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <div className="form-group mb-3">
                        <label>{lingo.dashboard_edit_profile_address_label}</label>
                        <Input 
                            value={address} 
                            onChange={setAddress} 
                            prefix={<FontAwesomeIcon icon={['fal', 'home']} placeholder={lingo.dashboard_edit_profile_address_placeholder} />} 
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group mb-3">
                        <label>{lingo.dashboard_edit_profile_post_number_label}</label>
                        <Input 
                            value={zip} 
                            onChange={setZip} 
                            onChankge={(value) => {
                                setZip(value);
                            }}
                            prefix={<FontAwesomeIcon icon={['fal', 'signs-post']} />} 
                            placeholder="" 
                        />
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="form-group mb-3">
                        <label>{lingo.dashboard_edit_profile_post_place_label}</label>
                        <Input 
                            disabled={true}
                            value={city} 
                            onChange={setCity} 
                            prefix={<FontAwesomeIcon icon={['fal', 'building']} />} 
                            placeholder="" 
                           />
                    </div>
                </div>
            </div>
            {error ? <p className="text-error">{error.msg}</p> : null}
            {success ? <p className="text-success">{success.msg}</p> : null}
            <div className="row">
                <div className="col">
                    <Button onClick={() => { doSaveChanges() }} title={lingo.dashboard_edit_profile_save_title} rightIcon="floppy-disk" type="primary" />
                </div>
            </div>
        </>
	)
}

function Input(props){
    const { 
        value, 
        onChange, 
        placeholder, 
        type, 
        maxLength, 
        classes, 
        disabled, 
        prefix,
        suffix,
        validation,
        rules,
        mask
    } = props


    const [focus, setFocus] = useState(false)
    const [isValidating, setIsValidating] = useState(false)
    const [valid, setValid] = useState({error: false, valid: false})





    return (
        <div>
            <div className="form-group position-relative mb-1">
                <div className={"input-group " + (focus ? 'focus ' : '') + (disabled ? 'disabled ' : '') + (valid.valid ? 'valid ' : '') + (valid.error ? 'error ' : '')}>  
                    {prefix ? <span className="input-prefix">{prefix}</span> : null}  
                    <input 
                        maxLength={maxLength}
                        type={type ? type : 'text'}
                        className={"form-control " + (classes ? classes : null)} 
                        placeholder={placeholder} 
                        onChange={(e) => {
                            onChange(e.target.value, valid.valid)
                        }} 
                        value={value}
                        onFocus={(e) => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        disabled={disabled ? disabled : null}
                    />
                    {maxLength ? <span className="form-max-length">{value ? value.length : 0} / {maxLength}</span> : null}
                    {suffix ? <span className="input-suffix">{suffix}</span> : null}
                </div>
                
                {valid.valid === true && !isValidating ? <div className={"input-validation-icon valid"}>
                    <FontAwesomeIcon icon={['fas', 'check']} size="sm" color="white" />
                </div> : null}
                {
                    isValidating ? <div className={"input-validation-icon"}>
                    <FontAwesomeIcon spin icon={['fas', 'spinner-third']} size="sm" color="grey" />
                </div> : null
                }
                {
                    valid.error && !isValidating ? <div className={"input-validation-icon error"}>
                        <FontAwesomeIcon icon={['fas', 'times']} size="sm" color="white" />
                    </div> : null
                }
            </div>
            {
                valid.error && valid.message && valid.type==='below' ? <span className="input-error-message">{valid.message}</span> : null
            }
            {
                valid.error && valid.message && valid.type==='box' ? <div className="input-error-message-box">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="p-2">
                                <FontAwesomeIcon icon={['far', 'circle-exclamation']} className="text-error opacity" size="4x" />
                            </div>
                        </div>
                        <div className="col">
                            {valid.message}
                        </div>
                    </div>
                </div> : null
            }
        </div>
    )
}

const getCorrectPeriod = (periods, now, lingo) => {
    const monthNames = ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'];
    if(!periods) return null;
    if(!now) return null;
    let output = {
        inPeriod: true
    };

    // Først sjekk om now er innenfor en periode
    periods.forEach(period => {
        const [month, day] = period.deadline.split('-').map(Number);
        const monthName = monthNames[month - 1];

        if (period.startdate > period.deadline) {
            if ((period.startdate <= now && '12-31' >= now) || ('01-01' <= now && period.deadline >= now)) {
                output.text = `${lingo.correct_period_1} ${day}. ${monthName}!`;
            }
        }

        if (period.startdate <= now && period.deadline >= now) {
            output.text = `${lingo.correct_period_1} ${day}. ${monthName}!`;
        }
    });

    if (output.text) {
        return output;
    }

    // Sjekk om now er innenfor lastcall
    for (const period of periods) {
        const [month, day] = period.deadline.split('-').map(Number);
        const monthName = monthNames[month - 1];

        if (period.deadline > period.lastcall) {
            if ((period.deadline <= now && '12-31' >= now) || ('01-01' <= now && period.lastcall >= now)) {
                output.text = `${lingo.correct_period_2} ${day}. ${monthName}. ${lingo.correct_period_3}`;
                output.inPeriod = false;
            }
        }

        if (period.deadline <= now && period.lastcall >= now) {
            output.text = `${lingo.correct_period_1} ${day}. ${monthName}. ${lingo.correct_period_3}`;
            output.inPeriod = false;
        }
    };

    if (output.text) {
        return output;
    }

    // Sjekk hva neste soknadsperiode er
    periods.sort((a, b) => a.startdate.localeCompare(b.startdate));


    for (const period of periods) {
        const [startmonth, startday] = period.startdate.split('-').map(Number);
        const startmonthName = monthNames[startmonth - 1];
        const [endmonth, endday] = period.deadline.split('-').map(Number);
        const endmonthName = monthNames[endmonth - 1];
        if (period.startdate > now) {
            output.text = `${lingo.correct_period_4} ${startday}. ${startmonthName} ${lingo.correct_period_5} ${endday}. ${endmonthName}.`;
            output.inPeriod = false;
            break; // Exit the loop as soon as the condition is met
        }
    }



    if (output.text) {
        return output;
    }

    if (!output) {
        return '';
    }
}


function ApplicationChoice({ item, setModalActive }){
	const { title, short_description, url, startdate, deadline, periods} = item
	const navigate = useNavigate();
    const [hasDeadline, setHasDeadline] = useState(startdate || deadline);
    const [hasPeriods, setHasPeriods] = useState(periods);
    const { lingo } = useContext(ConfigContext)
    moment.locale('nb');
    moment().format('L');

    let inPeriod = true;
    let statusText = "";

    let periodStatus = {};

    if(hasPeriods){
        periodStatus = getCorrectPeriod(periods, moment().format('MM-DD'), lingo);
        if(periodStatus){
            inPeriod = periodStatus.inPeriod;
            statusText = periodStatus.text;
        }
    }

    // if(hasDeadline){
    //     let mstart = moment(startdate);
    //     let mend = moment(deadline);
    //     let mendplusone = moment(deadline);
    //     mendplusone.add(1, 'day');
    //     //Setter midlertidig årstall på frister for å få nåværende års periode
        
    //     mstart.set('year', moment().format("YYYY"));
    //     mend.set('year', moment().format("YYYY"));
        
    //     const daysAfterDeadline = moment().diff(mendplusone, 'days');

        
    //     if (moment().isBetween(mstart, mendplusone)){
    //         statusText = "Husk soknadsfristen " + moment(mend).format("DD.MM.YYYY") + "!";
    //         // statusText = "Soknadsskjemaet er åpen i perioden " + moment(mstart).format("DD.MM.YYYY") + " til " + moment(mend).format("DD.MM.YYYY") + ".";
    //     }
    //     else if (daysAfterDeadline <= 2) {
    //         statusText = `Soknadsfristen var ${moment(mend).format("DD.MM.YYYY")}. Du kan fortsatt forsøke å søke, men merk at vi ikke kan garantere at vi rekker å ta med din soknad i denne runden.`;
    //     }
    //     else if(moment().isBefore(mstart)){
    //         inPeriod = false;
    //         statusText = "Soknadsskjemaet åpner igjen " + moment(mstart).format("DD.MM.YYYY") + ".";
    //     }else{
    //         inPeriod = false;
    //         mstart.add(1, 'years');
    //         statusText = "Soknadsskjemaet åpner igjen " + moment(mstart).format("DD.MM.YYYY") + ".";
    //     }

    // }
    

	const goToApplication = () => {
		setModalActive(false)
		setTimeout(() => {
			navigate("/soknadskjema/" + url);
		}, 400)
		
	}
	return (
		<a className={"application-choice border border-thick rounded px-4 py-3 mb-2 d-block" + (inPeriod ? "" : " deactivated")} href="#" onClick={(e) => {
			e.preventDefault()
			goToApplication()
		}}>
			<div className="row align-items-center">
				<div className="col">
					<p className="h5 mb-0">{title}</p>
					<p className="font-weight-medium opacity mb-0 small">{statusText === "" ? short_description : statusText }</p>
				</div>
				<div className="col-auto">
					<FontAwesomeIcon icon={['far', 'arrow-right']} className="linkbox-icon" />
				</div>
			</div>
		</a>
	)
}

function ApplicationItem({ id, name, type, createdon,  status, hash, messages }){
    const { hasFinalReport } = useContext(ConfigContext)
    const { lingo } = useContext(ConfigContext)
	const date = moment(createdon)
	const navigate = useNavigate()
	const statusObject = getStatus(status, lingo)
    const finalReport = hasFinalReport(type)
	return (
		<div className="border border-thick rounded px-4 py-3 mb-2 pointer application-item" onClick={() => navigate(`/soknad/${hash}`)}>
			<div className="row">
				<div className="col">
					<p className="h5 mb-1">{type === 2 ? formatFormType(type, lingo) : name}</p>
					<p className="font-weight-medium opacity mb-0 small">{formatFormType(type, lingo)} - {date.format('DD.MM.YYYY')}</p>
				</div>
				<div className="col-auto">
					<div className="row gx-1">
						<div className="col-auto">
							<span className={"bold " + statusObject.className}>{statusObject.label}</span>
						</div>
						<div className="col-auto">
							<FontAwesomeIcon className={statusObject.className} icon={['fas', statusObject.icon]} />
						</div>
					</div>
				</div>
					
					
				{
					finalReport && status === 1 ? 
					(
						<div className="col-12 mt-2">
							<div className="py-1 px-2 bg-beige d-inline-block rounded">
                                <small className="font-weight-bold p-0 m-0">{lingo.end_report_not_delivered}</small>
							</div>
						</div>
					)
					: null
				}
			</div>
			
		</div>
	)
}

function ApplicationDraft({ id, type, createdon,  status, hash }){
    const { data,lingo } = useContext(ConfigContext)
	const date = moment(createdon)
	const navigate = useNavigate()
	const statusObject = getStatus(status, lingo)
    const [url, setUrl] = useState(null)
    
    useEffect(() => {
        if(data){
            const applicationType = data.applications.filter(a => a.type === type)
            if(applicationType.length){
                setUrl(applicationType[0].url)
            }
        }
    }, [data])
    if(url === null) return <div></div>
	return (
		<div className="border border-thick rounded px-4 py-3 mb-2 pointer application-item" onClick={() => navigate(`/soknadskjema/${url}`)}>
			<div className="row">
				<div className="col">
					<p className="h5 mb-1">{lingo.dashboard_draft}</p>
					<p className="font-weight-medium opacity mb-0 small">{formatFormType(type, lingo)} - {date.format('DD.MM.YYYY')}</p>
				</div>
				<div className="col-auto">
					<div className="row gx-1">
						<div className="col-auto">
							<span className={"bold " + statusObject.className}>{statusObject.label}</span>
						</div>
						<div className="col-auto">
							<FontAwesomeIcon className={statusObject.className} icon={['fas', statusObject.icon]} />
						</div>
					</div>
				</div>
			</div>
			
		</div>
	)
}
function MessageItem({  name,  hash, object }){
    const { lingo } = useContext(ConfigContext)
	const { baseUrl } = useContext(AuthContext)
	
	const navigate = useNavigate()


	const { message, sender, sent } = object
	const date = moment(sent).format('DD.MM.YY hh:mm')
	return (
		<div>
			<small className='font-weight-bold caps opacity-75'>{name}</small>
			<div className="border border-thick rounded px-4 py-3 mb-2 pointer application-item" onClick={() => navigate(`/soknad/${hash}/meldinger`)}>
				<div className="row">
					<div className="col-auto">
					{ sender.photo ? <img src={baseUrl + 'assets/files/' + sender.photo} style={{width: 50, height: 50, borderRadius: '50%'}} title={`${sender.fullname} ${date}`} /> : <div className="profile-photo-replacement">{sender.fullname.substr(0, 1)}</div> }
					</div>
					<div className="col">
						<p className='m-0 pb-1'>{message}</p>
						<div className="row gx-2">
							<div className="col-auto">
                                <small className='m-0 text-blue'>{lingo.go_to_conversation}</small>
							</div>
							<div className="col-auto">
								<FontAwesomeIcon className='text-blue' icon={['fal', 'arrow-right']} size="sm" />
							</div>
						</div>
						
					</div>
				</div>
				
				
			</div>
		</div>
	)
}



function CustomLink({ children, to, ...props }) {
	let resolved = useResolvedPath(to);
	let match = useMatch({ path: resolved.pathname, end: true });
  
	return (
	  
		<Link
			className={match && 'active'}
			to={to}
			{...props}
		>
			{children}
		</Link>
	  
	);
}

function SkeletonContainer(){
    const { logout } = useContext(AuthContext)
    const { lingo } = useContext(ConfigContext)
    return (
        <div className="container">
            <div className="py-4">
                <h1>{lingo.dashboard_title}</h1>
            </div>
            <div className="tabs">
                <div>
                    <CustomLink to="">{lingo.dashboard_your_overview}</CustomLink>
                    <CustomLink to="arkiv">{lingo.dasboard_archive}</CustomLink>
                    <CustomLink to="rediger-profil">{lingo.dashboard_edit_profile}</CustomLink>
                </div>
                <div>
                    <a className="logout-link" href="#" onClick={logout}>{lingo.dashboard_logout} <FontAwesomeIcon icon={['far', 'arrow-right-from-bracket']} /></a>
                </div>
            </div>
            <div className="py-3">
                <div>
                    <div className="row">
                        <div className="col-12">
                            <Button
                                title={lingo.dashboard_new_application}
                                rightIcon="arrow-right"
                                type="secondary"
                                classes="skeleton mb-4"
                            />
                        </div>
                        <div className="col-7">
                            <div><h3 className="skeleton">{lingo.dashboard_your_applications}</h3></div>
                            <p className="skeleton">{lingo.dashboard_here_you_can_see_status}</p>
                           
                            <div className="mb-4">
                                <div className="mb-2"><Skeleton width={'100%'} height={100} /></div>
                                <div className="mb-2"><Skeleton width={'100%'} height={100} /></div>
                            </div>
                            

                                
                            
                        </div>
                        <div className="col-5">
                            <div><h3 className="skeleton">{lingo.dashboard_unread_messages}</h3></div>
                            <div className="mb-4">
                                <div className="mb-2"><Skeleton width={'100%'} height={100} /></div>
                                <div className="mb-2"><Skeleton width={'100%'} height={100} /></div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
    )
}