import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Button from "../components/Button";
import CalculateFields from "../components/CalculateFields";
import CalculateFieldsFinalReport from "../components/CalculateFieldsFinalReport";
import CalculateFieldsFinalReportF from "../components/CalculateFieldsFinalReport";
import DropDown from "../components/DropDown";
import DropDownCheck from "../components/DropDownCheck";
import FileUpload from "../components/FileUpload";
import FileUploadSingle from "../components/FileUploadSingle";
import Input from "../components/Input";
import InputMask from "../components/InputMask";
import Number from "../components/Number";
import RadioGroup from "../components/RadioGroup";
import RadioGroupDepending from "../components/RadioGroupDepending";
import RepeaterFinalReport from "../components/RepeaterFinalReport";
import Textarea from "../components/Textarea";
import ToggleSwitch from "../components/ToggleSwitch";
import { FinalReportContext } from "../providers/FinalReportProvider";
import { Tips } from "../screens/Section";


export default function FinalReportForm(){
    const { application, section, form, setApplicationData, applicationType, formData, getDraftFromDataBase, applicationId, reportApplication } = useContext(FinalReportContext)
    let params = useParams();

    if(reportApplication === null){
        return (
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
            </div>
        )
    }

    if(section === null){
        return (
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
            </div>
        )
    }

    if(form === null){
        return (
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
            </div>
        )
    }

    if(section.type === 'form'){
        return (
            <>
            <FormGroup />
            </>
        )
    }

    
    return(
        <div>
            <h3>Form</h3>
        </div>
    )
}


function FormGroup(){
    const { form, section, animate } = useContext(FinalReportContext) 

    const formIndex = section.forms.findIndex(s => s.url === form.url)
    const { display } = form;
    const nodeRef = useRef(null)

    return (
        <CSSTransition nodeRef={nodeRef} in={animate} timeout={500} classNames="fade">
            <div ref={nodeRef} className="form-content">
                <div>
                    <div className="form-title my-4">
                        <p className="m-0 hint-text smaller">{section.title} - {formIndex + 1} / {section.forms.length}</p>
                        <h2>{form.title}</h2>
                        {form.introtext ? <div dangerouslySetInnerHTML={{__html: form.introtext}}></div> : ''}
                        <div className="row mt-5">
                        {
                            form.elements.map((item, key) => {
                                
                                return (
                                    <FormElement 
                                        key={key}
                                        item={item}
                                    />
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
            
        </CSSTransition>
    )
}

export function FormElement({ item }){
    const { label, mask, name, placeholder, required, type, depends, display, readonly,extensions, extensionsFormat, existing_files } = item
    const {element, form, section, formData, updateForm, bulkUpdateForm, application } = useContext(FinalReportContext) 
    const [value, setValue] = useState(formData[name] ? formData[name] : '')
    const [showButton, setShowButton] = useState(false);
    const [helperValue, setHelperValue] = useState({});

    //const [files, setFiles] = useState([]);

    const removeFile = (file, isRequired) => {
        
        let existingFilesArr = formData[name].existing_files;
        let newFiles = formData[name].value;


        if(file instanceof File){
            newFiles = formData[name].value.filter((f) => {
                return f !== file;
            });
        }else{
            existingFilesArr = formData[name].existing_files.filter((f) => {
                return f !== file;
            })
        }

        if(existingFilesArr && existingFilesArr.length !== 0){
            let innertemp = {};
            let obj = {};

            if(!newFiles.length && !existingFilesArr.length && isRequired){
                innertemp = {
                    existing_files: existingFilesArr,
                    value: newFiles,
                    file: true,
                    valid: false
                };
            }else{
                innertemp = {
                    existing_files: existingFilesArr,
                    value: newFiles,
                    file: true,
                    valid: true
                };
            }
            
            obj[name] = innertemp;

            bulkUpdateForm(obj);
            updateForm('last-update', new Date(), true);

        }else{
            let validFiles = true;
            if(isRequired){
                validFiles = !newFiles?.length && !existingFilesArr?.length ? false : true;
            }
            updateForm(name, newFiles, validFiles);
        }

        
    }
    /**
     * @param {FileList} fileList 
     * @param {boolean} validType 
     * @param {boolean} validRequired 
     * 
     * Tar imot og registrerer validering av type hvis satt, og validering av innhold hvis required er satt. 
     * Valideringsregler settes i config.json.
     */
    const handleFileDrop = (fileList, validType, validRequired) => {
        let newFilesArr = [];
        let existingFilesArr = [];
        if( formData[name] && formData[name].existing_files !== undefined){
            existingFilesArr = formData[name].existing_files;
        }

        if ( formData[name] && formData[name].value !== undefined ){
            newFilesArr = formData[name].value;
        }

        for ( let i = 0; i < fileList.length; i++ ) {
            if(!newFilesArr.some(file => file.name === fileList[i].name)){
                newFilesArr.push(fileList[i]);
            }
        }

        if(existingFilesArr.length !== 0){
            let innertemp = {};
            let obj = {};
            /*
                file_project_budget: file: true, valid: true, value: [File, File], existing_files: []
            */
           // Her må jeg bruke bulkUpdateForm for å få til det jeg ønsker. (tror jeg)

           innertemp = {
               existing_files: existingFilesArr,
               value: newFilesArr,
               file: true,
               valid: validType
            };
            obj[name] = innertemp;

            bulkUpdateForm(obj);
            updateForm('last-update', new Date(), validType);
           
        }else{
            updateForm(name, newFilesArr, validType, true);
        }

        
        
    }

    /**
     * Skal håndtere validering av filopplastning, både fra drop og klikk. Eventuelle regler og spesialhåndtering gjøres her.
     */
     const validateNewFiles = (fileToValidate) => {
        if(fileToValidate.length > 0 && extensionsFormat){

            let valArr = []

            Array.from(fileToValidate).forEach(file => {
                let fileFormat = file.name.split(".");
                fileFormat = fileFormat.pop();
                valArr.push(extensionsFormat.includes("." + fileFormat));
            });

            return valArr.includes(false) ? false : true;

        }else{
            return true;
        }
    }

    const handleFileDropSingle = (fileList) => {

        let newFilesArr = [];
        let existingFilesArr = [];
        if( formData[name] && formData[name].existing_files !== undefined){
            existingFilesArr = formData[name].existing_files;
        }

        if ( formData[name] && formData[name].value !== undefined ){
            newFilesArr = formData[name].value;
        }

        // for ( let i = 0; i < fileList.length; i++ ) {
        //     if(!newFilesArr.some(file => file.name === fileList[i].name)){
        //         newFilesArr.push(fileList[i]);
        //     }
        // }

        // for ( let i = 0; i < 1; i++ ) {
        //     if(!newFilesArr.some(file => file.name === fileList[i].name)){
        //         newFilesArr[0] = fileList[0];
        //     }
        // }

        newFilesArr[0] = fileList[0];
        //existingFilesArr = [];

        if(existingFilesArr.length !== 0){
            let innertemp = {};
            let obj = {};
            /*
                file_project_budget: file: true, valid: true, value: [File, File], existing_files: []
            */
           // Her må jeg bruke bulkUpdateForm for å få til det jeg ønsker. (tror jeg)

           innertemp = {
               existing_files: existingFilesArr,
               value: newFilesArr,
               file: true,
               valid: true
            };
            obj[name] = innertemp;

            bulkUpdateForm(obj);
            updateForm('last-update', new Date(), true);
           
        }else{
            updateForm(name, newFilesArr, true, true);
        }

        
        
    }

    const handleOtherDrop = (fileList, key) => {
        let object = formData[name] ? formData[name].value : {}

        let newFilesArr = [];
        let existingFilesArr = [];
        if( object[key] && object[key].existing_files !== undefined){
            existingFilesArr = object[key].existing_files;
        }

        if ( object[key] !== undefined ){
            newFilesArr =  object[key] .value;
        }

        for ( let i = 0; i < fileList.length; i++ ) {
            if(!newFilesArr.some(file => file.name === fileList[i].name)){
                newFilesArr.push(fileList[i]);
            }
        }

        if(existingFilesArr.length !== 0){
            let innertemp = {};
            let obj = {};
            /*
                file_project_budget: file: true, valid: true, value: [File, File], existing_files: []
            */
           // Her må jeg bruke bulkUpdateForm for å få til det jeg ønsker. (tror jeg)

           innertemp = {
               existing_files: existingFilesArr,
               value: newFilesArr,
               file: true,
               valid: true
            };
            obj[name] = innertemp;

            bulkUpdateForm(obj);
            updateForm('last-update', new Date(), true);
           
        }else{
            object[key] = {
                value: newFilesArr,
                valid: true
            }

            updateForm(name, object, true, true);
        }
    }

    const DropdownCheckValue = ({ item }) => {
        return (
            <span className="bg-green p-1 px-2 me-2 rounded text-white">{item.label}</span>
        );
    }

    const updateTest = (value, key, valid) => {
        let object = formData[name] ? formData[name].value : {}
        
        let isValid = true
        
        object[key] = {
            value: value,
            valid: valid
        }

        Object.keys(object).map((e) => {
            if(!object[key].valid) isValid = false

        })
            
        updateForm(name, object, isValid)
    }
    
    switch(type){
        case 'text':
            return (
                <div className={display ? display : ''}>
                    <div className="form-group mb-3">
                        <label>{label}</label>
                        <Input 
                            value={formData[name] ? formData[name].value : ''}
                            onChange={(value, valid) => {
                                updateForm(name, value, valid)
                            }}
                            {...item}
                        />
                    </div>
                </div>
            )
        case 'inputmask':
            return (
                <div className={display ? display : ''}>
                    <div className="form-group mb-3">
                        <label>{label}</label>
                        <InputMask 
                            value={formData[name] ? formData[name].value : ''}
                            onChange={(value, valid) => {
                                updateForm(name, value, valid)
                            }}
                            {...item}
                        />
                    </div>
                </div>
            )
        case 'numberformat':
            return (
                <div className={display ? display : ''}>
                    <div className="form-group mb-3">
                        <label>{label}</label>
                        <Number
                            value={formData[name] ? formData[name].value : ''}
                            onChange={(value, valid) => {
                                updateForm(name, value, valid)
                            }}
                            {...item}
                        />
                    </div>
                </div>
            )
        case 'calculate':
            return (
                <div className={display ? display: ''}>
                    <div className="form-group mb-3">
                        <label>{label}</label>
                        <CalculateFieldsFinalReport
                            {...item}
                        />
                    </div>
                </div>
            )
        case 'repeater':
            return (
                <div className={display ? display + ' col-12': 'col-12'}>
                    <div className="form-group mb-3">
                        <div className="row">
                            
                            <label>{label}</label>
                            <RepeaterFinalReport
                                {...item}
                            />
                        </div>
                    </div>
                </div>
            )
        case 'dropdown':
            return (
                <div className={display ? display : ''}>
                    <div className="form-group mb-3">
                        <label>{label}</label>
                        <DropDown
                            value={formData[name] ? formData[name].value : ''}
                            onChange={(value) => updateForm(name, value, true)}
                            {...item}
                        />
                    </div>
                </div>
            )
        case 'dropdowncheck':
            return (
                <div className={display ? display : ''}>
                    <div className="form-group mb-3">
                        {readonly ?
                            "" :
                            (<label> {label} </label>)}
                        <DropDownCheck
                            value={formData[name] ? formData[name].value : []}
                            onChange={(value) => updateForm(name, value, true)}
                            {...item}
                        />
                    </div>
                </div>
            )
        case 'ToggleSwitch':
            return (
                <div className={display ? display : ''}>
                <ToggleSwitch
							label={label}
							value={formData[name] ? formData[name].value : []}
							onClick={(checked, valid) => updateForm(name, checked, checked)}
                            {...item}
						/>
                </div>
            );
        case 'radiogroup':
            return (
                <div className="form-group mb-3">
                    <label>{label}</label>
                    <RadioGroup 
							items={item.alternatives}
                            value={formData[name] ? formData[name].value: []}
							onCheck={(value) => updateForm(name, value, true)}
							checked={formData[name] ? formData[name].value : null}
                            {...item}
						/>
                </div>
            );
        case 'radiogroupdepending':
            return (
                <React.Fragment>
                    <div className="form-group mb-3">
                        <label className="mb-2">{label}</label>
                        <RadioGroupDepending 
                            items={item.alternatives}
                            value={formData[name] ? formData[name].value: []}
                            onCheck={(value) => updateForm(name, value, true)}
                            checked={formData[name] ? formData[name].value : []}
                            depends={depends}
                            FormElementAlt={FormElement}
                            {...item}
                        />
                    </div>
                </React.Fragment>
            );
        case 'textarea':
            return (
                <div className={display ? display : ''}>
                    <div className="form-group mb-3">
                        <label> {label} </label>
                        <Textarea
                            value={formData[name] ? formData[name].value : ''}
                            onChange={(value, valid) => updateForm(name, value, valid)}
                            
                            {...item}
                        />
                    </div>
                </div>
            );
        case 'fileupload':
            return (
                <div className={display ? display: ''}>
                    <div className="form-group mb-3">
                        <FileUpload 
                            onHandleDrop={handleFileDrop}
                            onRemoveFile={removeFile}
                            validateNewFiles={validateNewFiles}
                            readonly={readonly}
                            files={formData[name] ? formData[name].value : []}
                            extensionsFormat={extensionsFormat && extensionsFormat}
                            existing_files={(formData[name] && formData[name].existing_files) ? formData[name].existing_files : []}
                            final_report={(formData[name] && formData[name].value) ? formData[name].value : []}
                            {...item}
                        />
                    </div>
                </div>
            );
        case 'fileuploadsingle':
            return (
                <div className={display ? display: ''}>
                    <div className="form-group mb-3">
                        <FileUploadSingle 
                            onHandleDrop={handleFileDropSingle}
                            onRemoveFile={removeFile}
                            validateNewFiles={validateNewFiles}
                            readonly={readonly}
                            files={formData && formData[name] ? formData[name].value : (existing_files && existing_files[name] ? existing_files[name] : [])}
                            extensions={extensions && extensions}
                            extensionsFormat={extensionsFormat && extensionsFormat}
                            existing_files={(formData[name] && formData[name].existing_files) ? formData[name].existing_files : []}
                            final_report={(formData[name] && formData[name].value) ? formData[name].value : []}
                            name={name}
                        />
                    </div>
                </div>
            );
        default:
            return (<div></div>)
    }


}